
<div class="row">
    <div class="col-12">
        <div class="container">
            <mat-card>
                <div class="container form-register">
                    <div class="row justify-content-md-center align-items-center">
                        <div class="col-12">
                            
                            <div class="">
                                
                                <div class="card-body form-card">
                                    <form class="form-horizotal form-material" [formGroup]="formMiCuenta" (ngSubmit)="cambioCorreo()" autocomplete="off">
                                        <div class="row ">
                                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                                <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140" alt="logo_unah">
                                            </div>
                                            <div class="col-xs-4 col-md-4 col-lg-4">
                                                <h1 class="text-center">Cambio de correo electrónico</h1>
                                            </div>
                                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                                <div class="">
                                                    <img class="" src="../../../assets/image/logo/logo_degt.png" width="210" alt="logo_degt">
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="alert alert-info" role="alert">
                                            <h2 class="text-center">Para cambiar tu correo electrónico debes ingresar los siguientes datos</h2>
                                        </div>
                                       
                                        <div class="row">
                                            <div class="col-xs-12 col-md-12 col-lg-12">
                                                <div class="form-group" [ngClass]="{'text-danger': correo.dirty && correo.invalid }">
                                                    <label for="correo">Correo electrónico</label>
                                                    <input  maxlength="80" formControlName="correo" type="text" class="form-control form-control-sm" name="correoElectronico" id="correoElectronico" aria-describedby="helpId" placeholder="Ingrese el nuevo correo electrónico">
                                                    
                                                  </div>
                                            </div>
                                            <div class="col-xs-12 col-md-12 col-lg-12">
                                                <div class="form-group" [ngClass]="{'text-danger': motivo.dirty && motivo.invalid }">
                                                    <label for="motivo">Motivo</label>
                                                <textarea maxlength="200"  formControlName="motivo"
                                                        placeholder="Ingrese el motivo del cambio de correo electrónico"
                                                        class="form-control form-control-sm" name="motivo" id="motivo" cols="30"
                                                        rows="2"></textarea>
                                                </div>
                                            </div>
                                            <div  class="col-xs-12 col-md-12 col-lg-12">
                                                <div class="form-group" [ngClass]="{'text-danger': clave.dirty && clave.invalid }">
                                                    <label for="clave">clave</label>
                                                    <div class="input-group input-group-sm mb-3">
                                                        <input maxlength="16" formControlName="clave" [type]="showpass ? 'text' : 'password'" class="form-control form-control-sm" name="clave" id="clave" aria-describedby="helpId" placeholder="Ingrese su contraseña actual">
                                                        <div (click)="showpassword()" class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i *ngIf="!showpass" class="fas fa-eye-slash"></i>
                                                                <i *ngIf="showpass" class="fas fa-eye"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                  </div>
                                            </div>
                                           
                                        </div>
                                        
                                        <hr>
                                        
                                            <div class="text-center">
                                                <button [disabled]="cargando" type="submit" style="background-color: rgb(0, 54, 113);" class="btn btn-primary"><i *ngIf="cargando" class="fas fa-sync fa-spin"></i>Guardar</button>
                                            </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                
            </mat-card>
            
        </div>
    </div>
</div>
