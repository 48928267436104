import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Module 
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import{MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input'; 
import {MatIconModule} from '@angular/material/icon'; 
import {MatCardModule} from '@angular/material/card'; 
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import { MatNativeDateModule } from '@angular/material/core';
import {NgxSpinnerModule}from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap' ;  
import {NgxPaginationModule} from 'ngx-pagination'; 
import { OrderModule } from 'ngx-order-pipe';
import {MatListModule} from '@angular/material/list'
import {MatSlideToggleModule} from '@angular/material/slide-toggle'

//pipe
import { IdentidadPipe } from '../pipes/identidad.pipe';
import { ApellidosPipe } from '../pipes/apellidos.pipe';
import { DepartamentosPipe } from '../pipes/departamentos.pipe';
import { MunicipiosPipe } from '../pipes/municipios.pipe';
import { TelefonoMovilPipe } from '../pipes/telefono-movil.pipe';
import { FormatLinksPipe } from '../pipes/filter.pipe';
import { correoPipe } from '../pipes/correo.pipe';
 import { rolPipe } from '../pipes/rol.pipe';
import { profesionPipe } from '../pipes/profesion.pipe'; 

//Componentes
import { PagesComponent } from './pages.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

//Routes
import { PAGES_ROUTES } from './pages.routes';
import { PerfilComponent } from './perfil/perfil.component';
import { InformateComponent } from './informate/informate.component';
import { CitasComponent } from './citas/citas.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { DoctorComponent } from './doctor/doctor.component';
import { PatientmodalComponent } from '../components/patient/patientmodal/patientmodal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PatologiabasemodalComponent } from '../components/patient/patologiabasemodal/patologiabasemodal.component';
import { MapComponent } from '../components/map/map.component';
import { CambiarcontrasenaComponent } from './cambiarcontrasena/cambiarcontrasena.component';
import { DefaultComponent } from './default/default.component';
import { SintomasComponent } from './sintomas/sintomas.component';
import { AddDoctorComponent } from './add-doctor/add-doctor.component';
import { MicuentaComponent } from './micuenta/micuenta.component';
import { RegistroVacunasComponent } from './registro-vacunas/registro-vacunas.component';



@NgModule({
  declarations: [
    PagesComponent,
    PerfilComponent,
    InformateComponent,
    CitasComponent,
    NotificacionesComponent,
    DoctorComponent,
    IdentidadPipe,
    ApellidosPipe,
    DepartamentosPipe,
    MunicipiosPipe,
    TelefonoMovilPipe,
    FormatLinksPipe,
    CambiarcontrasenaComponent,
    DefaultComponent,
    SintomasComponent,
    AddDoctorComponent,
    MicuentaComponent,
    correoPipe,
    rolPipe,
    profesionPipe,
    RegistroVacunasComponent,
  ],
  exports:[
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule

  ],
  imports: [
    CommonModule,
    PAGES_ROUTES,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule ,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    NgbModule,
    NgxPaginationModule,
    OrderModule,
    CommonModule,
    MatCheckboxModule,
    MatListModule,
    MatSlideToggleModule
  ],
  entryComponents: [
    PatologiabasemodalComponent,
    MapComponent,
    DoctorComponent
  ]
})
export class PagesModule { }
