<div class="title row">
    <div class="col-md-6">
        <h3>Vacuna</h3>
    </div>
</div>
<form [formGroup]="formVacuna" (ngSubmit)="enviarVacuna()" autocomplete="off">
    <div class="modal-body">
        <input type="hidden" name="id" id="id" formControlName="id" class="form-control form-control-sm">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="doctor">Vacuna<span style="color: red;">*</span></label>
                    <select (change)="cambioVacuna()" formControlName="idVacuna" class="form-control form-control-sm" name="idVacuna" id="idVacuna">
                <option   *ngFor="let vacuna of vacunas" [value]=vacuna.id >{{vacuna.nombreMarcaVacuna}}</option>
              </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="lote">Lote<span style="color: red;">*</span></label>
                    <input type="text" name="lote" id="lote" formControlName="lote" class="form-control form-control-sm">

                </div>


            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div class="form-group">
                    <label for="doctor">Dosis<span style="color: red;">*</span></label>
                    <select (change)="cambioDosis($event.target.value)" formControlName="idDosis" class="form-control form-control-sm" name="dosis" id="dosis">
                    <option *ngFor="let dosi of dosis" [value]=dosi.id >{{dosi.nombreDosis}}</option>
                  </select>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div class="form-group">
                    <label for="fechaAplicacion">Fecha aplicación<span style="color: red;">*</span></label>
                    <input type="date" min="fecha_minimo" (change)="cambio($event.target.value)" name="fechaAplicacion" id="fechaAplicacion" formControlName="fechaAplicacion" class="form-control form-control-sm">
                    <p [hidden]="!hideFecha" style="text-align: center;color:#4F88EC;padding-top: 5%;">Fecha segunda dosis: <strong>{{fechaTo}}&nbsp;</strong></p>


                </div>


            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="lugar">Lugar de aplicación <span style="color: red;">*</span></label>
                    <textarea type="text" name="lugar" id="lugar" formControlName="lugar" class="form-control form-control-sm">
                    </textarea>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="form-group">
                    <label for="nacionalidad">Observaciones</label>
                    <textarea type="text" name="observaciones" id="observaciones" formControlName="observaciones" class="form-control form-control-sm">
                    </textarea>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cerrar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
            <!-- <button type="submit" *ngIf="this.userService.selectCita.id!=0||this.userService.selectCita.id!=null;" class="btn btn-success">Actualizar</button> -->
        </div>
    </div>
</form>
<div [hidden]="historialVacunas.length==0" class="table-responsive table12">
    <table class="table table-striped table-hover  table-condensed table-bordered">
        <thead>
            <tr>
                <th>
                    <span>Marca vacuna</span>
                </th>
                <th>
                    <span>Lote</span>
                </th>
                <th>
                    <span>Fecha aplicación</span>
                </th>
                <th>
                    <span>Número dosis</span>
                </th>
                <th>
                    <span>Lugar</span>
                </th>
                <th>
                    <span>Observaciones</span>
                </th>
                <th><span>Opciones</span></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <tr *ngFor="let vac of historialVacunas">
                    <td> {{vac.nombreMarcaVacuna}}</td>
                    <td> {{vac.lote}}</td>
                    <td> {{vac.fechaAplicacion|date:'d/M/yyyy'}}</td>
                    <td> {{vac.numeroDeDosis}}</td>
                    <td> {{vac.lugar}}</td>
                    <td> {{vac.observacion}}</td>
                    <td>

                        <div class=" btn-group">
                            <button type="button" title="Editar" (click)="preUpdateVacuna(vac)" class="btn btn-success btn-xs">
                                <i class="fa fa-wrench"></i>
                        </button>
                        </div>
                        <div class=" btn-group">
                            <button type="button" title="Eliminar vacuna" (click)="eliminarVacuna(vac)" class="btn btn-danger btn-xs">
                            <i class="fa fa-trash"></i>
                        </button>
                        </div>
                    </td>
                </tr>

        </tbody>
    </table>
    <div class="text-right">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cerrar</button>
    </div>
</div>