<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 30px; color: white">Cargando...</p>
</ngx-spinner>
<div class="col-lg-12 text-center">
    <label class="display-4">Listado de Pacientes</label>
</div>
<div class="nav-justified">
    <ngb-tabset #t="ngbTabset">

        <ngb-tab *ngIf="menu1 == true" id="tab-selectbyid1">
            <ng-template ngbTabTitle>
                <div (click)="getlistUser1()">Validación de pacientes</div>
            </ng-template>
            <ng-template ngbTabContent>
                <hr>
                <div class="col-lg-12 text-center">
                    <label class="h4">Validación de pacientes</label>
                </div>
                <ngb-tabset #t="ngbTabset">
                    <ngb-tab id="tab-selectbyid1">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser1()">Altamente sospechosos <span style="color: red;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Dirección</span>
                                            </th>
                                            <th>
                                                <span>Colonia</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesNuevos1 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                            <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.direccion}}</td>
                                                <td> {{user.colonia}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="validarPaciente(user) " title="Validar paciente" class="btn btn-primary btn-xs ">
                                                        <i class="fa fa-check "></i>
                                                    </button>
                                                        <button type="button" (click)="deshabilitarPaciente(user) " title="Deshabilitar paciente" class="btn btn-danger btn-xs ">
                                                <i class="fa fa-remove"></i>
                                            </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                                <i class="fa fa-eye "></i>
                                            </button>
                                                        <button type="button " (click)="getPerfil(user) " title="Ver perfil del paciente" class="btn btn-info btn-xs ">
                                                <i class="fa fa-user "></i>
                                            </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-selectbyid2">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser1()">Sospechosos <span style="color: yellow; "><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Dirección</span>
                                            </th>
                                            <th>
                                                <span>Colonia</span>
                                            </th>
                                            <!-- <th>
                                                <span>Tipo Vivienda</span>
                                            </th>
                                            <th>
                                                <span>Aldea</span>
                                            </th> -->
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefono Movil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesNuevos2 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                            <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.direccion}}</td>
                                                <td> {{user.colonia}}</td>
                                                <!-- <td> {{user.nombretipoVivienda}}</td>
                                                <td> {{user.aldea}}</td> -->
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="validarPaciente(user) " title="Validar paciente" class="btn btn-primary btn-xs ">
                                                        <i class="fa fa-check "></i>
                                                    </button>
                                                        <button type="button " (click)="deshabilitarPaciente(user) " title="Deshabilitar paciente" class="btn btn-danger btn-xs ">
                                                <i class="fa fa-remove"></i>
                                            </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                                <i class="fa fa-eye "></i>
                                            </button>
                                                        <button type="button " (click)="getPerfil(user) " title="Ver perfil del paciente" class="btn btn-info btn-xs ">
                                                <i class="fa fa-user "></i>
                                            </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>

                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-selectbyid3">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser1()">No sospechosos <span style="color: green;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Dirección</span>
                                            </th>
                                            <th>
                                                <span>Colonia</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesNuevos3 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                            <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.direccion}}</td>
                                                <td> {{user.colonia}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="validarPaciente(user) " title="Validar paciente" class="btn btn-primary btn-xs ">
                                                        <i class="fa fa-check "></i>
                                                    </button>
                                                        <button type="button " (click)="deshabilitarPaciente(user) " title="Deshabilitar paciente" class="btn btn-danger btn-xs ">
                                                <i class="fa fa-remove"></i>
                                            </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                                <i class="fa fa-eye "></i>
                                            </button>
                                                        <button type="button " (click)="getPerfil(user) " title="Ver perfil del paciente" class="btn btn-info btn-xs ">
                                                <i class="fa fa-user "></i>
                                            </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>

                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-selectbyid4">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistRechazados()">Rechazados <span class="cuadrado" style="color:black;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Dirección</span>
                                            </th>
                                            <th>
                                                <span>Colonia</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <!-- <th><span>Opciones</span></th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesRechazados |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                            <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.direccion}}</td>
                                                <td> {{user.colonia}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>


                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>

                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>




            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="menu2 == true" id="tab-selectbyid2">
            <ng-template ngbTabTitle>
                <div (click)="getlistUser2()">Primera consulta</div>
            </ng-template>
            <ng-template ngbTabContent>
                <hr>
                <div class="col-lg-12 text-center">
                    <label class="h4">Primera consulta</label>
                </div>
                <ngb-tabset #t="ngbTabset">
                    <ngb-tab id="tab-selectbyid1">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser2()">Altamente sospechosos <span style="color: red;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesAtendidos1 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-selectbyid2">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser2()">Sospechosos <span style="color:yellow;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesAtendidos2 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                        <i class="fas fa-notes-medical"></i>
                                                    </button>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="tab-selectbyid3">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser2()">No Sospechosos <span style="color:green;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesAtendidos3 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="menu3 == true" id="tab-selectbyid3">
            <ng-template ngbTabTitle>
                <div (click)="getlistUser3()">Pacientes seguimiento </div>
            </ng-template>
            <ng-template ngbTabContent>
                <hr>
                <div class="col-lg-12 text-center">
                    <label class="h4">Pacientes seguimiento</label>
                </div>
                <ngb-tabset #t="ngbTabset">
                    <ngb-tab id="tab-selectbyid1">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser3()">Altamente sospechosos <span style="color: red;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesSeguimiento1 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-selectbyid2">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser3()">Sospechosos <span style="color :yellow;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesSeguimiento2 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="tab-selectbyid3">
                        <ng-template ngbTabTitle>
                            <div (click)="getlistUser3()">No sospechosos <span style="color:green;"><i class="fa fa-square"></i></span> </div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of pacientesSeguimiento3 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>
                                                        <!-- <button type="button " (click)="openModalVacuna(user) " title="vacuna " style="background-color: blueviolet;color:white;" class="btn btn-xs ">
                                            <i class="fas fa-syringe"></i>
                                        </button> -->

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>

            </ng-template>
        </ngb-tab>

        <ngb-tab *ngIf="menu4 == true" id="tab-selectbyid4">
            <ng-template ngbTabTitle>
                <div (click)="getMisPaciente()">Mis atenciones</div>
            </ng-template>
            <ng-template ngbTabContent>
                <hr>
                <div class="col-lg-12 text-center">
                    <label class="h4">Mis atenciones</label>
                </div>
                <ngb-tabset #t="ngbTabset">
                    <ngb-tab id="tab-selectbyid7">
                        <ng-template ngbTabTitle>
                            <div (click)="getMisPaciente()">Altamente sospechosos<span style="color: red;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of misPacientes1 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="tab-selectbyid8">
                        <ng-template ngbTabTitle>
                            <div (click)="getMisPaciente()">Sopechosos <span style="color:yellow;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of misPacientes2 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="tab-selectbyid9">
                        <ng-template ngbTabTitle>
                            <div (click)="getMisPaciente()">No sospechosos <span style="color:green;"><i class="fa fa-square"></i></span></div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <br>
                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span># Identidad</span>
                                            </th>
                                            <th>
                                                <span>Nombre</span>
                                            </th>
                                            <th>
                                                <span>Apellido</span>
                                            </th>
                                            <th>
                                                <span>Departamento</span>
                                            </th>
                                            <th>
                                                <span>Municipio</span>
                                            </th>
                                            <th>
                                                <span>Teléfono móvil</span>
                                            </th>
                                            <th>
                                                <span>Edad</span>
                                            </th>
                                            <th>
                                                <span>Estado</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar Paciente por Identidad" title="Buscar Paciente por Identidad" /></td>
                                            <td>
                                                <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar Paciente por Nombre" title="Buscar Paciente por Nombre" /></td>
                                            <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar Paciente por Apellido" title="Buscar Paciente por Apellido" /></td>
                                            <td><input type="text" class="form-control" name="departamentos" [(ngModel)]="departamentos" placeholder="Buscar Paciente por Departamento" title="Buscar Paciente por Departamento" /></td>
                                            <td><input type="text" class="form-control" name="municipios" [(ngModel)]="municipios" placeholder="Buscar Paciente por Municipio" title="Buscar Paciente por Municipio" /></td>
                                            <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar Paciente por telefonoMovil" title="Buscar Paciente por telefonoMovil" /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <tr *ngFor="let user of misPacientes3 |orderBy:order:true| apellidos:apellidos | identidad:identidad|departamentos:departamentos|municipios:municipios|telefonoMovil:telefonoMovil | filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual } "
                                                [ngClass]="{ 'text-warning':(user.totalpuntos)>5&&(user.totalpuntos)
                                        <=11, 'text-success':(user.totalpuntos) <=5, 'text-danger':(user.totalpuntos>=12 )}">

                                                <td>{{user.identidad}}</td>
                                                <td> {{user.nombres}}</td>
                                                <td> {{user.apellidos}}</td>
                                                <td> {{user.nombreDepartamento}}</td>
                                                <td> {{user.nombreMunicipio}}</td>
                                                <td> {{user.telefonoMovil}}</td>
                                                <td> {{user.edad}} años</td>
                                                <td class="estat">
                                                    <i title="Estado del Paciente" class="fa fa-square"></i>
                                                </td>
                                                <td>
                                                    <div class=" btn-group ">
                                                        <button type="button " (click)="citaAhora(user) " title="Atender ahora " class="btn btn-danger btn-xs ">
                                                            <i class="fas fa-notes-medical"></i>
                                                        </button>
                                                        <button type="button " (click)="getEncuestaPato(user) " title="Ver patologías " class="btn btn-secondary btn-xs ">
                                                    <i class="fa fa-stethoscope "></i>
                                                </button>
                                                        <button type="button " (click)="getEncuesta(user) " title="Ver síntomas" class="btn btn-success btn-xs ">
                                            <i class="fa fa-eye "></i>
                                        </button>

                                                        <button type="button " class="btn btn-primary btn-xs " (click)="openMapa(user) " title="Ver ubicación ">
                                            <i class="fa fa-map-marker "></i>
                                        </button>
                                                        <button type="button " title="Agendar Cita" (click)="openCitas(user)" class="btn btn-info btn-xs ">
                                            <i class="fa fa-user-plus"></i>
                                        </button>

                                                    </div>
                                                </td>

                                            </tr>
                                    </tbody>
                                </table>
                                <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>