import { Component, OnInit, Inject } from '@angular/core';
import {environment} from '../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from "@angular/material/dialog"; 
import{NgxSpinnerService}from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  //  mapa: mapboxgl.Map;

  constructor(public dialogRef: MatDialogRef <MapComponent> ,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinnerServi:NgxSpinnerService,
    private toastr:ToastrService) {}

    ngOnInit(): void {
      if (this.data.ultimaPosicionX==null||this.data.ultimaPosicionY==null||this.data.ultimaPosicionY==0||this.data.ultimaPosicionX==0) {
        this.spinnerServi.hide();
        this.closeModal();
         this.toastr.warning('Paciente sin ubicación')
       } else {
      mapboxgl.accessToken = environment.mapboxKey;
       var mapa = new mapboxgl.Map({
        container: 'map-mapbox', // container id
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.data.ultimaPosicionX,this.data.ultimaPosicionY], // starting position long y lati
        zoom: 10// starting zoom
        
      });
      this.spinnerServi.hide();
      //  this.mapa.scrollZoom.disable();

      const marker = new mapboxgl.Marker({
        draggable: false,
      })
      marker.setLngLat([this.data.ultimaPosicionX,this.data.ultimaPosicionY])
       marker.addTo(mapa);
      
      
        // this.crearMarcador(this.data.ultimaPosicionX,this.data.ultimaPosicionY);
    }
      // let element=document.createElement('div')
      // element.className='marker'
      //  addEventListener('click',()=>{
      //    alert(123);
      // })
    }

    // crearMarcador(lng: number, lat: number) {
    //   const marker = new mapboxgl.Marker({
    //       draggable: true,
    //     })
    //     marker.setLngLat([lng, lat])
    //      marker.addTo(this.mapa);
        
    //     if (this.data!=null) {
    //       this.spinnerServi.hide();
    //     }

       
  
     //}
    
      
    closeModal() {
      this.dialogRef.close();
    }

 

}
