import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICE } from 'src/app/config/config';
import { DataApi } from 'src/app/interfaces/dataRespuesta.interface';
import { UsuarioLogin } from 'src/app/models/usuario.model';
import { UsuarioService } from '../usuario/usuario.service';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {

  public estadoPsicologico: boolean = true;

  constructor(
    private http : HttpClient,
    private _UsurioService: UsuarioService
  ) { }

  //Funtions
  
 
  getCitas(){
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    let url = URL_SERVICE + '/paciente/cita/mostrar';
    
    return this.http.post<DataApi>(url,{
      idPersona: usuario.idPersona,
      idusuario: usuario.idUsuario,
      id:0
    });
  }

  getPacienteEncuestas(){
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    let url = URL_SERVICE + '/paciente/encuesta/mostrar';
    return this.http.post<DataApi>(url,usuario);
  }

  getEncuesta(idCategoria: number){
    let usuario : UsuarioLogin = this._UsurioService.usuario;
    let url = URL_SERVICE + '/encuesta/mostrar';
    return this.http.post<DataApi>(url,{
      "idEncuesta":0,
      "idUsuario":usuario.idUsuario,
      "idPersona":usuario.idPersona,
      "idCategoria":idCategoria
  });}

  verificarEncuesta(idCategoria:number){
    let usuario: UsuarioLogin =  this._UsurioService.usuario;
    let url = URL_SERVICE + '/patologias/verificar';
    return this.http.post<DataApi>(url,{
      idPersona: usuario.idPersona,
      idUsuario: usuario.idUsuario,
      idCategoria: idCategoria
    });
  }

  postEncuesta(respuesta){
   let usuario: UsuarioLogin = this._UsurioService.usuario;
   let url = URL_SERVICE + '/respuesta/insertar';
   let objet = {
    idRespuesta:respuesta.idRespuesta,
    idRegistroEncuesta: respuesta.idRegistroEncuesta,
    idPregunta:respuesta.idPregunta,
    idOpcionRespuesta:respuesta.idOpcionRespuesta,
    idUsuario:usuario.idUsuario
   };
   return this.http.post<DataApi>(url,objet);
  }

  eliminarRespuesta(idRespuesta:number){
    let url = URL_SERVICE + '/registro/respuesta/eliminar';
    let usuario: UsuarioLogin =  this._UsurioService.usuario;
    return this.http.post<DataApi>(url,{
      id: idRespuesta,
      idUsuario: usuario.idUsuario
    });
  }

  finalizarEncuesta(idRegistroEncuesta: number){
    let url = URL_SERVICE + '/encuesta/finalizar';
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    return this.http.post<DataApi>(url,{
      idRegistroEncuesta:idRegistroEncuesta,
      finalizada:1,
      idUsuario: usuario.idUsuario
    });
  
  }
  // Registros vacuna


  postEncuestaVac(respuesta){
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    let url = URL_SERVICE + '/respuesta/insertar';
    let objet = {
     idRespuesta:respuesta.idRespuesta,
     idRegistroEncuesta: respuesta.idRegistroEncuesta,
     idPregunta:respuesta.idPregunta,
     idOpcionRespuesta:respuesta.idOpcionRespuesta,
     idUsuario:respuesta.idUsuario
    };
    return this.http.post<DataApi>(url,objet);
   }
 
   eliminarRespuestaVac(idRespuesta:number,idUsuario){
     let url = URL_SERVICE + '/registro/respuesta/eliminar';
     let usuario: UsuarioLogin =  this._UsurioService.usuario;
     return this.http.post<DataApi>(url,{
       id: idRespuesta,
       idUsuario: idUsuario
     });
   }
 
   finalizarEncuestaVac(idRegistroEncuesta: number,idUsuario){
     let url = URL_SERVICE + '/encuesta/finalizar';
     let usuario: UsuarioLogin = this._UsurioService.usuario;
     return this.http.post<DataApi>(url,{
       idRegistroEncuesta:idRegistroEncuesta,
       finalizada:1,
       idUsuario:idUsuario
     });

    }

    getEncuestaVac(idPersona){
      let usuario : UsuarioLogin = this._UsurioService.usuario;
      let url = URL_SERVICE + '/encuesta/mostrar';
      return this.http.post<DataApi>(url,{
        "idEncuesta":0,
        "idUsuario":usuario.idPersona,
        "idPersona":idPersona,
        "idCategoria":3
    });}
  guardarCita(){
    let url =  URL_SERVICE + '/paciente/cita/insertar';
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    return this.http.post<DataApi>(url,{
      "id":0,
      "idPersona":usuario.idPersona,
      "idDoctor":0,
      "fechaCita":"",
      "horaCita":"",
      "observaciones":"",
      "dictamen":"",
      "idUsuario": usuario.idUsuario

    });
  }

  obtenerCitasPaciente(){
    let url = URL_SERVICE + '/paciente/cita/mostrar';
    let usuario: UsuarioLogin = this._UsurioService.usuario;
    return this.http.post<DataApi>(url,{
    "id":0,
    "idUsuario":usuario.idUsuario,
    "idPersona":usuario.idPersona
    });
  }

  guardarEvaluacionPsicologica(estado: boolean){
    this.estadoPsicologico = estado;
  }

  obtenerNoticias(){
    let url = URL_SERVICE + '/noticias/mostrar';
    return this.http.post<DataApi>(url,{
      "id": 0
      });
  }

}
