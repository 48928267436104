import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class DoctorguardGuard implements CanActivate {
  constructor(
    public _usuarioService: UsuarioService,
    public router: Router
  ){}
  canActivate(){

    if(this._usuarioService.usuario.idRol != 3){
      return true;
    }else{
      this.router.navigate(['/default']);
      return false;
     
    }

  }
  
}
