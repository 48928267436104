import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PatologiabasemodalComponent } from 'src/app/components/patient/patologiabasemodal/patologiabasemodal.component';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { encuaesta } from 'src/app/models/paciente.model';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {
  //Variables
  public estado: boolean = true;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public _pacienteService: PacienteService
  ) { }

  ngOnInit(): void {
  }
  //Funciones
 

  obtenerEncuetaPsicologica(){
    this.router.navigate(['/sintomas']);
  }
  //Navegacion del Sistema.
  navegacion(page){
    this.router.navigate([page]);
  }
 
}
