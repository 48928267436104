import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirmarcontrsena',
  templateUrl: './confirmarcontrsena.component.html',
  styleUrls: ['./confirmarcontrsena.component.css']
})
export class ConfirmarcontrsenaComponent implements OnInit {

  formCambioContrasena: FormGroup
  public cargandoFormulario: boolean = true;
  private urlTree: any
  public mensaje = "";
  get contrasenaActual(){return this.formCambioContrasena.get('contrasenaActual');}
  get nuevaContrasena(){return this.formCambioContrasena.get('nuevaContrasena');}
  get confirmarnuevaContrasena(){return this.formCambioContrasena.get('confirmarnuevaContrasena');}

  constructor(
    private _RegisterService: RegisterService,
    private toastr: ToastrService,
    private _router: ActivatedRoute,
    private navegacion : Router
  ) { }

  ngOnInit(): void {
    this.urlTree = this.navegacion.parseUrl(this.navegacion.url);
    if((this.urlTree.queryParams['id'] && this.urlTree.queryParams['token']) && (this.urlTree.queryParams['id'] !=="" && this.urlTree.queryParams['token'] !=="")){
      this._RegisterService.confirmarReinicioContrasena({
        idSolicitud: this.urlTree.queryParams['id'],
        token: this.urlTree.queryParams['token']
      }).subscribe(result=>{
        if(result.hasError){
          if(result.errors){
            this.mensaje = result.errors[0].descripcion;
            this.cargandoFormulario = false;
            setTimeout(()=>{
              this.navegacion.navigate(['/login']);
            },10000);
          }
          
        }else{
          this.cargandoFormulario = false;
          this.mensaje = result.data.Table0[0].descripcion;
          setTimeout(()=>{
            this.navegacion.navigate(['/login']);
          },10000);
        }   
      });
    }else{
        this.navegacion.navigate(['/login']);
    }
  }
  

 

}
