import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef,MatDialog ,MatDialogConfig } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import{NgxSpinnerService}from 'ngx-spinner';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from "../../../services/doctor/doctor.service";
import { HistorialComponent } from '../../../components/doctor/historial/historial.component';
import { OrderPipe } from 'ngx-order-pipe';
import { OrderModule } from 'ngx-order-pipe';


@Component({
  selector: 'app-modalsintomas',
  templateUrl: './modalsintomas.component.html',
  styleUrls: ['./modalsintomas.component.css']
})
export class ModalsintomasComponent implements OnInit {

  historial:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private matDialog: MatDialog, 
    public dialogRef: MatDialogRef<ModalsintomasComponent>,
    private spinnerServi:NgxSpinnerService,
    private _pacienteService: PacienteService,
    private toastr: ToastrService,
    private userService:DoctorService,
    private orderBy:OrderModule) { 
  }
   ngOnInit(): void {
    this.spinnerServi.hide();
    this.puntaje=false;
  
   }

   enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){  
      this._pacienteService.postEncuesta({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.data.idRegistroEncuesta,
        idPregunta: this.data.preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta
      }).subscribe(
        (data:any)=>{
      });
    }else{
      this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe((data:any)=>{    
      });
    }
  }

  finalizarEncuesta(){
    this._pacienteService.finalizarEncuesta(this.data.idRegistroEncuesta).subscribe((data:any)=>{
        this.toastr.success("Diagnóstico guardado");
      this.dialogRef.close();
      
    });
  }




  closeModal() {
    this.dialogRef.close();
  }


  getEncuestaHistorial(){
    this.spinnerServi.show();
    this.userService
    .getEncuestaHistorial(this.data)
    .subscribe(
      (data:any) => { // Success
        this.historial = data.data.Table0;
        this.historial.idPersona=this.data.idPersona;
        this.closeModal();
         this.openHis(this.historial);
      }
    );
  }
  openHis(historial) {
    const dialogConfig = new MatDialogConfig;
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "historial-component";
    dialogConfig.height = "500px";
    dialogConfig.width = "700px";
    dialogConfig.data = historial;
    const modalDialog = this.matDialog.open(HistorialComponent, dialogConfig);
  };

pipes='';
 order: any = 'fechaFinalizada';
puntaje:boolean;
puntos:any;
sinEnviar:string='1900-05-06';
  obtenerPuntaje(){
    this.userService.getEncuestaHistorial(this.data).subscribe(
      (data:any)=>{
      this.puntaje=true;
      for (let index = 0; index < data.data.Table0.length; index++) {
        const punt = data.data.Table0[index];
        if (data.data.Table0[index].fechaFinalizada==null) {
          data.data.Table0[index].fechaFinalizada='0'
          data.data.Table0[index].horaFinalizada='0'
        }else{
        data.data.Table0[index].fechaFinalizada=data.data.Table0[index].fechaFinalizada;
        }
        
      }
      this.puntos=data.data.Table0;
      
      }
    )
  }

}
