import { Component, OnInit } from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import { UsuarioService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  //Declaracion de variables.
  public posicionX: string = "";
  public posicionY: string = "";
  public mensaje: string = "";
  public cargandoInicio = false;
  public showpass: boolean = false;

  constructor( 
    public _ServiceUsuario: UsuarioService,
    public router:Router) { }

  ngOnInit(): void {
    //Obtener la geolocalización. 
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(function(obj){
        localStorage.setItem('Longitude',String(obj.coords.longitude));
        localStorage.setItem('Latitude',String(obj.coords.latitude));
      },function(error){
        console.log(error);
      });
    }else{
      console.log("No se puede geolocalizar");
    }
  }

  //Funciones
  limpiarMensaje(){
    this.mensaje = "";
  }
  showpassword() {
    this.showpass = !this.showpass;
  }
  iniciarSesion(forma: NgForm){
      let usuario = new Usuario(
        forma.value.usuario,
        this._ServiceUsuario.encryptPassword(forma.value.contrasena) ,
        localStorage.getItem('Longitude'),
        localStorage.getItem('Latitude')
      )

      this.cargandoInicio = true;

      this._ServiceUsuario.iniciarSesion(usuario).subscribe(data =>{
        if (!data.hasError && !data.error){
          this._ServiceUsuario.guardarStorage(data.token,data.data.Table0[0]);
          this.cargandoInicio = false;
          this.router.navigate(['/default']);
        }else{
          if(data.error){
            this.mensaje =  "Intente ingresar de nuevo sus credenciales";
            this.cargandoInicio = false;
          }else{
            this.mensaje = data.errors[0].descripcion
            this.cargandoInicio = false;
          }
        }
      });
  }



}
