import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Data } from '@angular/router';
import { URL_SERVICE } from 'src/app/config/config';
import {DataApi} from './../../interfaces/dataRespuesta.interface'
import { UsuarioRegister, UsuarioLogin } from 'src/app/models/usuario.model';
import sha1 from 'js-sha1';

import { UsuarioService } from '../usuario/usuario.service';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private _UsuarioService: UsuarioService
  ) { }

  verificarNumeroTelefono(valor: string,Campo:string){
    let url = URL_SERVICE + '/registro/campo/verificar';
    return this.http.post<DataApi>(url,{
      nombreCampo: Campo,
      valorCampo:valor
    });
  }

  getDatosPerfil(){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let url =  URL_SERVICE + '/registro/mostrar';
    return this.http.post<DataApi>(url,usuario);
  }
  
  getDatosPerfilValidar(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let url =  URL_SERVICE + '/registro/mostrar';
    return this.http.post<DataApi>(url,{"idEstadoSeguimientoPaciente":user.idEstadoSeguimientoPaciente,"idUsuario":usuario.idUsuario,"idPersona":user.idPersona});
  }

  actualizarPerfilValidar(perfil,user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let objet = {
        id: user.idPersona,
        campo: perfil.campo,
        nuevoValor: perfil.nuevoValor,
        idUsuario: usuario.idUsuario
    }
    let url = URL_SERVICE + '/registro/persona/actualizar';
    return this.http.post<DataApi>(url,objet);
    
  }

  reiniciarContrasena(correo){
    let url = URL_SERVICE + '/usuario/solicitudreinicio/insertar';
    return this.http.post<DataApi>(url,{
      usuario:correo
    })
  }

  confirmarReinicioContrasena(usuario){
    let url = URL_SERVICE + '/usuario/solicitudreinicio/confirmar';
    return this.http.post<DataApi>(url,{
      idSolicitud: usuario.idSolicitud,
      token: usuario.token
    });
  }

  cambioContrasena(cambio){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let url = URL_SERVICE + '/usuario/clave/cambiar';
    return this.http.post<DataApi>(url,{
      idUsuario: usuario.idUsuario,
      clave:cambio.clave,
      claveNueva:cambio.claveNueva
    })
  }

  actualizarPerfil(perfil){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let objet = {
        id: usuario.idPersona,
        campo: perfil.campo,
        nuevoValor: perfil.nuevoValor,
        idUsuario: usuario.idUsuario
    }
    let url = URL_SERVICE + '/registro/persona/actualizar';
    return this.http.post<DataApi>(url,objet);
    
  }

  actualizarCorreo(data){
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    let url = URL_SERVICE + '/persona/correo/editar';
    return this.http.post<DataApi>(url,{
      "idPersona":usuario.idPersona,
      "nuevoCorreo": data.nuevoCorreo,
      "motivo": data.motivo,
      "clave": this.encryptPassword(data.clave)
    });
  }

  obtenerNacionalidades(){
    let url = URL_SERVICE + '/general/nacionalidades/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }
  obtenerTipoDocumento(){
    let url = URL_SERVICE + '/general/documentos/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  crearUsuario(usuario: UsuarioRegister){
    let url  = URL_SERVICE + '/registro';
    return this.http.post<DataApi>(url,usuario);
  }
  encryptPassword(pass: String){
    return sha1(pass);
  }

  crearRegistro(usuario){
    let url  = URL_SERVICE + '/registro';
    return this.http.post<DataApi>(url,usuario);
  }


  

}
