import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'identidad'
})
export class IdentidadPipe implements PipeTransform {
   
    transform(value: any,arg:any): any {
     const resultPost=[]
       for(const post of value){
         if (post.identidad==null) {
          post.identidad='';
         }
         if(post.identidad.toLowerCase().indexOf(arg.toLowerCase())>-1){
            resultPost.push(post);
         };
      
       };
       return resultPost;
     
  }
   
  }
