<div class="form-container">

    <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Citas</h2>
        <button type="button" class="close">
            <span (click)="closeModal()">&times;</span>
          </button>
    </div>
    <form [formGroup]="citaForm" (ngSubmit)="saveCita()" autocomplete="off">
        <div class="modal-body">
            <input type="hidden" name="id" id="id" [(ngModel)]="this.userService.selectCita.id" formControlName="id" class="form-control form-control-sm">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-6">
                    <div class="form-group">
                        <label for="nacionalidad">Fecha de la Cita <span style="color: red;">*</span></label>
                        <input type="date" min="fecha_minimo" name="fechaCita" id="fecha" [(ngModel)]="this.userService.selectCita.fechaCita" formControlName="fechaCita" class="form-control form-control-sm">

                    </div>

                </div>
                <div class="col-lg-6 col-md-6 col-xs-6">
                    <div class="form-group">
                        <label for="nacionalidad">Hora de la Cita <span style="color: red;">*</span></label>
                        <input type="time" name="horaCita" [(ngModel)]="this.userService.selectCita.horaCita" formControlName="horaCita" class="form-control form-control-sm">

                    </div>

                </div>

            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="nacionalidad">Observaciones</label>
                        <textarea type="text" [(ngModel)]="this.userService.selectCita.observaciones" name="observaciones" id="observaciones" formControlName="observaciones" class="form-control form-control-sm">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-8 col-xs-8">
                    <div class="form-group">
                        <label for="doctor">Doctor <span style="color: red;">*</span></label>
                        <select [(ngModel)]="this.userService.selectCita.idDoctor" formControlName="idDoctor" class="form-control form-control-sm" name="idDoctor" id="idDoctor">
                    <option *ngFor="let doctor of doctores" [value]=doctor.id >{{doctor.nombres}} {{doctor.apellidos}}</option>
                  </select>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-secondary" (click)='resetForm()' (click)="closeModal()" data-dismiss="modal">Cerrar</button>
                <button type="submit" class="btn btn-primary">{{!this.userService.selectCita.id ? 'Guardar':'Actualizar'}}</button>
                <!-- <button type="submit" *ngIf="this.userService.selectCita.id!=0||this.userService.selectCita.id!=null;" class="btn btn-success">Actualizar</button> -->
            </div>
        </div>
    </form>
    <hr>
    <h2 class="text-center">Historial de citas</h2>
    <div class="table-responsive table12">
        <table class="table table-striped table-hover  table-condensed table-bordered">
            <thead>
                <tr>
                    <th>
                        <span>Nombre Doctor</span>
                    </th>
                    <th>
                        <span>Fecha Cita</span>
                    </th>
                    <th>
                        <span>Hora Cita</span>
                    </th>
                    <th>
                        <span>Dictamen</span>
                    </th>
                    <th>
                        <span>Valoración telemedica</span>
                    </th>
                    <th>
                        <span>Examenes previos</span>
                    </th>
                    <th>
                        <span>Conclusión</span>
                    </th>
                    <th>
                        <span>Estado Cita</span>
                    </th>
                    <th><span>Opciones</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <tr *ngFor="let cita of citas |paginate:{itemsPerPage:5,currentPage:pageActual }">
                        <td> {{cita.nombreDoctor}}</td>
                        <td> {{cita.fechaCita|date:'d/M/yyyy'}}</td>
                        <td> {{cita.horaCita}}</td>
                        <td> {{cita.nombreDictamen}}</td>
                        <!-- <td> {{cita.observaciones}}</td> -->
                        <td> {{cita.valoracionTelemedicina}}</td>
                        <td> {{cita.resultadosExamenesPrevios}}</td>
                        <td> {{cita.concluciones}}</td>
                        <td> {{cita.estadoCita}} </td>
                        <td>
                            <div class="btn-group">
                                <button type="button" title="Editar Cita" (click)="preUpdateCita(cita)" class="btn btn-success btn-xs" [disabled]="cita.idEstadoCita!==1">
                            <i class="fa fa-wrench"></i>
                        </button>

                            </div>
                            <div class=" btn-group">
                                <button type="button" title="Marcar como atendido" (click)="preUpdateCita(cita)" (click)="openAtendido(cita)" class="btn btn-primary btn-xs" [disabled]="cita.idEstadoCita==2||cita.idEstadoCita==3">
                                <i class="fa fa-check"></i>
                            </button>
                            </div>
                            <div class=" btn-group">
                                <button type="button" title="Marcar como no se presentó" (click)="preUpdateCita(cita)" (click)="openNoAtendido(cita)" class="btn btn-danger btn-xs" [disabled]="cita.idEstadoCita==2||cita.idEstadoCita==3">
                                <i class="fa fa-times-circle"></i>
                            </button>
                            </div>
                        </td>
                    </tr>

            </tbody>
        </table>
        <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
        <div class="text-right">
            <button type="button" class="btn btn-secondary" (click)='resetForm()' (click)="closeModal()" data-dismiss="modal">Cerrar</button>
        </div>
    </div>