import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profesion'
})
export class profesionPipe implements PipeTransform {

  transform(value: any,arg:any): any {
    const resultPost=[]
      for(const post of value){
        if (post.profesion==null) {
         post.profesion='';
        }
        if(post.profesion.toLowerCase().indexOf(arg.toLowerCase())>-1){
           resultPost.push(post);
        };
     
      };
      return resultPost;
    
 }

}
