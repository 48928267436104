<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 30px; color: white">Cargando...</p>
</ngx-spinner>

<div class="row">
    <div class="col-lg-12">
        <mat-card style="background-color:#e6f0f0;">
            <h1 class="text-center">Formulario de registro </h1>

            <div class="row">
                <div class="container">
                    <form [formGroup]="registroForm" (ngSubmit)="guardarRegistro()">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Identidad</mat-label>
                                        <input max="13" #box (keyup.enter)="buscarCenso(box.value)" formControlName="numeroIdentificacion" placeholder="identidad" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div style="margin-top: 1%;" class="col-lg-3">
                                <button (click)="buscarCenso(1)" type="button" class="btn btn-primary">Buscar</button>
                            </div>
                            <div [hidden]=verificado style="margin-top: 1%;" class="col-lg-3">
                                <i style="font-size: 80px; color:green;" class="fas fa-user-check"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Nombres</mat-label>
                                        <input formControlName="nombres" placeholder="Nombres" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Apellidos</mat-label>
                                        <input formControlName="apellidos" placeholder="Apellidos" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <mat-form-field appearance="standard">
                                    <mat-label>Fecha Nacimiento</mat-label>
                                    <input (dateChange)="ver($event.value)" formControlName="fechaNacimiento" matInput [matDatepicker]="pickerIngreso">
                                    <!-- <input type="date" name="fechaNacimiento" id="fechaNacimiento" formControlName="fechaNacimiento" class="form-control form-control-sm"> -->
                                    <mat-datepicker-toggle matSuffix [for]="pickerIngreso"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerIngreso></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Genero</mat-label>
                                        <mat-select formControlName="idGenero" placeholder="Genero">
                                            <mat-option *ngFor="let act of generos" [value]="act.id">
                                                {{act.genero}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Edad</mat-label>
                                        <input (keydown)="validarNumeros($event)" formControlName="edad" placeholder="edad" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Celular</mat-label>
                                        <input (keydown)="validarNumeros($event)" formControlName="telefonoMovil" placeholder="Celular" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Correo electronico</mat-label>
                                        <input formControlName="correo" placeholder="correo electronico" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Departamento</mat-label>
                                        <mat-select formControlName="idDepartamento" (selectionChange)="getMunicipios($event.value)" placeholder="Departamento">
                                            <mat-option *ngFor="let act of departamentos" [value]="act.id">
                                                {{act.departamento}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Municipio</mat-label>
                                        <mat-select formControlName="idMunicipio" placeholder="municipio">
                                            <mat-option *ngFor="let act of municipios" [value]="act.id">
                                                {{act.municipio}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <mat-list-item>
                                    <mat-form-field appearance="standard">
                                        <mat-label>Colonia/residencial/aldea</mat-label>
                                        <input formControlName="direccion" placeholder="Colonia o residencial" matInput>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>
                                </mat-list-item>
                            </div>
                        </div>
                        <hr>
                        <div style="margin-left: 0%;" class="row">
                            <h1 style="margin-right: 1%;">Registrar Comorbilidades</h1>
                            <button type="button" matTooltip="Condiciones patológicas" aria-label="Button that displays a tooltip when focused or hovered over" class="btn-material-primary float-left" (click)="openModalPatologica()" data-toggle="tooltip" data-placement="right" mat-mini-fab
                                aria-label="Example icon-button with a heart icon">
                                <i class="fas fa-stethoscope icon-menu"></i>
                            </button>

                        </div>
                        <hr>
                        <div style="margin-left: 0%;" class="row">

                            <h1 style="margin-right: 1%;">Registro de vacunas </h1>
                            <!-- <button style="border-radius: 50%;" type="button" matToolTip="Agregar vacuna" (click)="openModalVacuna(modalVacuna)" class="btn btn-primary"> <i class="fas fa-plus"></i></button> -->
                            <button style="background-color: #4F88EC;" type="button" matTooltip="agregar vacuna" aria-label="Button that displays a tooltip when focused or hovered over" class="btn-material-primary float-left" (click)="openModalVacuna(modalVacuna)" data-toggle="tooltip"
                                data-placement="right" mat-mini-fab aria-label="Example icon-button with a heart icon">
                            <i class="fas fa-plus"></i>
                        </button>



                            <div class="table-responsive table12">
                                <table class="table table-striped table-hover  table-condensed table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span>Marca vacuna</span>
                                            </th>
                                            <th>
                                                <span>Lote</span>
                                            </th>
                                            <th>
                                                <span>Fecha aplicación</span>
                                            </th>
                                            <th>
                                                <span>Número dosis</span>
                                            </th>
                                            <th>
                                                <span>Lugar</span>
                                            </th>
                                            <th>
                                                <span>Observaciones</span>
                                            </th>
                                            <th><span>Opciones</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <tr *ngFor="let vac of historialVacunas">
                                                <td> {{vac.nombreMarcaVacuna}}</td>
                                                <td> {{vac.lote}}</td>
                                                <td> {{vac.fechaAplicacion|date:'d/M/yyyy'}}</td>
                                                <td> {{vac.numeroDeDosis}}</td>
                                                <td> {{vac.lugar}}</td>
                                                <td> {{vac.observacion}}</td>
                                                <td>

                                                    <div class=" btn-group">
                                                        <button type="button" title="Editar" (click)="preUpdateVacuna(vac,modalVacuna)" class="btn btn-success btn-xs">
                                                            <i class="fa fa-wrench"></i>
                                                    </button>
                                                    </div>
                                                    <div class=" btn-group">
                                                        <button type="button" title="Eliminar vacuna" (click)="eliminarVacuna(vac)" class="btn btn-danger btn-xs">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                    </div>
                                                </td>
                                            </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="text-right">
                            <!-- <button mat-button mat-dialog-close (click)="registroForm.reset()">Salir</button>
                            <button mat-raised-button type="submit" class="btn btn-primary">
                                Guardar
                            </button> -->
                        </div>

                    </form>
                </div>
            </div>
        </mat-card>
    </div>
</div>


<ng-template #modalVacuna>
    <form [formGroup]="formVacuna" (ngSubmit)="enviarVacuna()" autocomplete="off">
        <div class="modal-body">
            <input type="hidden" name="id" id="id" formControlName="id" class="form-control form-control-sm">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="doctor">Vacuna<span style="color: red;">*</span></label>
                        <select (change)="cambioVacuna()" formControlName="idVacuna" class="form-control form-control-sm" name="idVacuna" id="idVacuna">
                    <option   *ngFor="let vacuna of vacunas" [value]=vacuna.id >{{vacuna.nombreMarcaVacuna}}</option>
                  </select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="lote">Lote<span style="color: red;">*</span></label>
                        <input type="text" name="lote" id="lote" formControlName="lote" class="form-control form-control-sm">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-6">
                    <div class="form-group">
                        <label for="doctor">Dosis<span style="color: red;">*</span></label>
                        <select (change)="cambioDosis($event.target.value)" formControlName="idDosis" class="form-control form-control-sm" name="dosis" id="dosis">
                        <option *ngFor="let dosi of dosis" [value]=dosi.id >{{dosi.nombreDosis}}</option>
                      </select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-6">
                    <div class="form-group">
                        <label for="fechaAplicacion">Fecha aplicación<span style="color: red;">*</span></label>
                        <input type="date" min="fecha_minimo" (change)="cambio($event.target.value)" name="fechaAplicacion" id="fechaAplicacion" formControlName="fechaAplicacion" class="form-control form-control-sm">
                        <p [hidden]="!hideFecha" style="text-align: center;color:#4F88EC;padding-top: 5%;">Fecha segunda dosis: <strong>{{fechaTo}}&nbsp;</strong></p>


                    </div>


                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="lugar">Lugar de aplicación <span style="color: red;">*</span></label>
                        <textarea type="text" name="lugar" id="lugar" formControlName="lugar" class="form-control form-control-sm">
                        </textarea>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="nacionalidad">Observaciones</label>
                        <textarea type="text" name="observaciones" id="observaciones" formControlName="observaciones" class="form-control form-control-sm">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-secondary" (click)="closeModal()" mat-button mat-dialog-close>Cerrar</button>
                <button type="submit" mat-button mat-dialog-close class="btn btn-primary">Guardar</button>
                <!-- <button type="submit" *ngIf="this.userService.selectCita.id!=0||this.userService.selectCita.id!=null;" class="btn btn-success">Actualizar</button> -->
            </div>
        </div>
    </form>
</ng-template>