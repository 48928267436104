<!-- <br><br>
<h4 class="text-center display-3 text-lg-center">Historial de Evaluación
</h4> -->
<mat-dialog-content>
    <div class="contenedor1 text-center">
        <table *ngFor="let respuesta of encuesta; let i=index" class="table  table-sm">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Síntomas evaluación {{i+1}}</th>
                    <!-- <th scope="col">{{respuesta.fecha|date:'d/M/yyyy'}}</th> -->
                    <th scope="col"> </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of respuesta">
                    <th scope="row">{{item.respuesta}}</th>
                    <td>
                        <mat-checkbox disabled [(ngModel)]="item.seleccionada"></mat-checkbox>
                    </td>
                </tr>

            </tbody>
        </table>

    </div>
</mat-dialog-content>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Salir</button>
</div>