import { Component, OnInit, Inject } from '@angular/core';
import { PatologiabasemodalComponent } from '../patologiabasemodal/patologiabasemodal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encuaesta } from 'src/app/models/paciente.model';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-consultarapidamodal',
  templateUrl: './consultarapidamodal.component.html',
  styleUrls: ['./consultarapidamodal.component.css']
})
export class ConsultarapidamodalComponent implements OnInit {

  public contador: number = 0;
 
  
  constructor(
    public dialogRef: MatDialogRef<PatologiabasemodalComponent>,
    @Inject(MAT_DIALOG_DATA) public encuesta: encuaesta,
    private _pacienteService: PacienteService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.contador = this.conteoPreguntas();
  }

  conteoPreguntas():any{
    let cont = 0
    this.encuesta.preguntas[0].respuestas.forEach(function (value) {
      if((value.idTipoRespuesta == 28 && value.seleccionada) || (value.idTipoRespuesta == 27 && value.seleccionada) || (value.idTipoRespuesta == 26 && value.seleccionada)){
        cont += 3;
      }else if((value.idTipoRespuesta == 7 && value.seleccionada) || (value.idTipoRespuesta == 5 && value.seleccionada) ){
        cont += 2;
      }else if(value.seleccionada && value.idTipoRespuesta != 7 && value.idTipoRespuesta != 5 && value.idTipoRespuesta != 28 && value.idTipoRespuesta != 27 && value.idTipoRespuesta != 26){
        cont +=1
      }
      
    }); 
    return cont
    
  }

  enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){
      if(respuesta.idTipoRespuesta == 28 || respuesta.idTipoRespuesta == 27 || respuesta.idTipoRespuesta == 26){
        this.contador += 3;
      }else if(respuesta.idTipoRespuesta == 7 || respuesta.idTipoRespuesta == 5 ){
        this.contador += 2;
      }else{
        this.contador += 1;
      }
      this._pacienteService.postEncuesta({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
        idPregunta: this.encuesta.preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta
      }).subscribe(result=>{
        
      });
    }else{
      if(respuesta.idTipoRespuesta == 28 || respuesta.idTipoRespuesta == 27 || respuesta.idTipoRespuesta == 26){
        this.contador -= 3;
      }else if(respuesta.idTipoRespuesta == 7 || respuesta.idTipoRespuesta == 5 ){
        this.contador -= 2;
      }else{
        this.contador -= 1;
      }
      this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe(result=>{
        
      });
    }
  }

  finalizarEncuesta(){
    this._pacienteService.finalizarEncuesta(this.encuesta.idRegistroEncuesta).subscribe(result=>{
      
      if (this.contador < 6){
        this.toastr.success("Según los datos marcados no eres sospechoso de COVID 19.");
      }else if(this.contador > 5 && this.contador < 12){
        this.toastr.warning("Según los datos marcados eres sospechoso de COVID 19.");
        this._pacienteService.guardarCita().subscribe(result=>{
          if(!result.hasError){
            this.toastr.info("Se agregado un cita por el resultado de su evaluación","Cita")
          }
        });
      }else{
        this.toastr.error("Según los datos marcados eres altamente sospechoso de COVID 19.");
        this._pacienteService.guardarCita().subscribe(result=>{
          if(!result.hasError){
            this.toastr.info("Se agregado un cita por el resultado de su evaluación","Cita")
          }
        });
      }
      this.dialogRef.close();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
