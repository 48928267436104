import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICE } from 'src/app/config/config';
import { DataApi } from 'src/app/interfaces/dataRespuesta.interface';

@Injectable({
  providedIn: 'root'
})
export class DataListService {

  constructor(
    private http: HttpClient
  ) { }

  getNacionalidades(){
    let url = URL_SERVICE + '/general/nacionalidades/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }


  getTiposDocumentos(){
    let url = URL_SERVICE + '/general/documentos/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  getEstadosCiviles(){
    let url = URL_SERVICE + '/general/estadocivil/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  getEscolaridad(){
    let url = URL_SERVICE;
    return this.http.post<DataApi>(url,{id:0});
  }

  getDepartamentos(){
    let url = URL_SERVICE + '/general/departamentos/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  getMunicipios(idDepartamento:number){
    let url = URL_SERVICE + '/general/municipios/mostrar';
    return this.http.post<DataApi>(url,{id:0,idDepartamento:idDepartamento});
  }

  getTipoVivienda(){
    let url = URL_SERVICE + '/general/tipovivienda/mostrar';
    return this.http.post<DataApi>(url,{id:0})
  }

  getCategoriaDireccion(){
    let url = URL_SERVICE + '/general/categoriadireccion/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  getCategoriaProfesion(){
    let url = URL_SERVICE + '/general/categoriaprofesion/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

  getGeneros(){
    let url = URL_SERVICE + '/general/genero/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }
  getNivelEducativo(){
    let url = URL_SERVICE + '/general/niveleducativo/mostrar';
    return this.http.post<DataApi>(url,{id:0});
  }

}
