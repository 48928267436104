<nav class="navbar fixed-top top-navbar navbar-expand-md navbar-light nav-back">
    <br>
</nav>
<br>
<div class="container form-register">
    <div class="row justify-content-md-center align-items-center">
        <div class="col-12">

            <div class="card">

                <div class="card-body form-card">
                    <div *ngIf="cargandoFormulario" class="row contenedor-menu-register">

                        <i style="font-size: 90px;" class="fas fa-spinner fa-pulse"></i>

                    </div>
                    <form *ngIf="!cargandoFormulario" class="form-horizotal form-material" [formGroup]="formRegister"
                        (ngSubmit)="registerUser()" autocomplete="off">
                        <div class="row ">
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140"
                                    alt="logo_unah">
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4">
                                <h1 class="text-center">Formulario de registro</h1>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <div class="">
                                    <img class="" src="../../../assets/image/logo/logo_degt.png" width="210"
                                        alt="logo_degt">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-6">
                                <div class="form-group">
                                    <label for="nacionalidad">Nacionalidad</label>
                                    <select formControlName="nacionalidad" class="form-control form-control-sm"
                                        name="nacionalidad" id="idNacionalidad">
                                        <option value="0">-seleccioné uno-</option>
                                        <option *ngFor="let nacionalidad of nacionalidades" [value]="nacionalidad.id">
                                            {{nacionalidad.pais}}</option>
                                    </select>

                                </div>

                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6">

                                <div class="form-group "
                                    [ngClass]="{'text-danger': documento.dirty && documento.invalid }">
                                    <label *ngIf="formRegister.value.tipoDocumento != 1" for="tipoDocumento">Tipo de identificación </label>
                                    <label *ngIf="formRegister.value.tipoDocumento == 1" for="tipoDocumento">Tipo de identificación (sin guiones)</label>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <select (click)="mensajeIdentificacion()" formControlName="tipoDocumento" title="Ingrese"
                                                class="form-control form-control-sm " name="tipoDocumento"
                                                id="idTipoDocumento">
                                                <option value="0">-seleccioné uno-</option>
                                                <option *ngFor="let documento of documentos" [value]="documento.id">
                                                    {{documento.nombreDocumento}}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-6">
                                            <input (keydown)="validarNumeroYLetras($event)" maxlength="30"
                                                formControlName="documento" type="text" name="documento" id="documento"
                                                class="form-control form-control-sm "
                                                placeholder="{{mensIdentificacion}}">

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group" [ngClass]="{'text-danger': nombres.dirty && nombres.invalid }">
                                    <label for="nombre">Nombres</label>
                                    <input (keydown)="validarLetras($event)" maxlength="50" formControlName="nombres"
                                        type="text" class="form-control form-control-sm" name="nombres" id="nombres"
                                        aria-describedby="helpId" placeholder="Ingrese su nombre">

                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group"
                                    [ngClass]="{'text-danger': apellidos.dirty && apellidos.invalid }">
                                    <label for="apellidos">Apellidos</label>
                                    <input (keydown)="validarLetras($event)" maxlength="50" formControlName="apellidos"
                                        type="text" class="form-control form-control-sm" name="Apellidos" id="apellidos"
                                        aria-describedby="helpId" placeholder="Ingrese su apellido">

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group"
                                    [ngClass]="{'text-danger': fechaNacimiento.dirty && fechaNacimiento.invalid }">
                                    <label for="fechaNacimiento">Fecha de nacimiento</label>
                                    <input value="2002-01-01" min="1900-01-01" formControlName="fechaNacimiento"
                                        type="date" class="form-control form-control-sm" name="fechaNacimiento"
                                        id="fechaNacimiento" aria-describedby="helpId"
                                        placeholder="Ingrese su fecha de nacimiento">

                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group"
                                    [ngClass]="{'text-danger': numeroTelefono.dirty && numeroTelefono.invalid }">
                                    <label for="numeroTelefono">Número de teléfono celular</label>
                                    <input (keydown)="validarNumeros($event)" maxlength="8"
                                        formControlName="numeroTelefono" type="text"
                                        class="form-control form-control-sm" name="numeroTelefono" id="numeroTelefono"
                                        aria-describedby="helpId" placeholder="Ingrese su número de teléfono celular">

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-12">
                                <div class="form-group"
                                    [ngClass]="{'text-danger': correoElectronico.dirty && correoElectronico.invalid }">
                                    <label for="correoElectronico">Correo electrónico</label>
                                    <input maxlength="80" formControlName="correoElectronico" type="text"
                                        class="form-control form-control-sm" name="correoElectronico"
                                        id="correoElectronico" aria-describedby="helpId"
                                        placeholder="Ingrese su correo electrónico">

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group" [ngClass]="{'text-danger':(formRegister.errors?.sonIguales && !showpass)}">
                                    <label for="contrasena">Contraseña</label>
                                    <div class="input-group input-group-sm mb-3">


                                        <input (ngModelChange)="copypassword()"  maxlength="16" formControlName="contrasena"
                                            [type]="showpass ? 'text' : 'password'" class="form-control form-control-sm"
                                            name="contrasena" id="contrasena" aria-describedby="helpId"
                                            placeholder="Ingrese una contraseña">
                                        <div (click)="showpassword()" class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i *ngIf="!showpass" class="fas fa-eye-slash"></i>
                                                <i *ngIf="showpass" class="fas fa-eye"></i>
                                            </span>
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div *ngIf="!showpass" class="col-xs-12 col-md-6 col-lg-6">
                                <div class="form-group" [ngClass]="{'text-danger':formRegister.errors?.sonIguales}">
                                    <label for="confirmarContrasena">Confirmar contraseña</label>
                                    <div class="input-group input-group-sm mb-3">
                                        <input [(ngModel)]="datoConfirmarContrasena" maxlength="16" formControlName="confirmarContrasena"
                                            [type]="showpassConfirmar ? 'text' : 'password'" class="form-control form-control-sm"
                                            name="confirmarContrasena" id="confirmarContrasena"
                                            aria-describedby="helpId" placeholder="Confirme la contraseña">
                                        <div (click)="showpasswordConfirmar()" class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i *ngIf="!showpassConfirmar" class="fas fa-eye-slash"></i>
                                                <i *ngIf="showpassConfirmar" class="fas fa-eye"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div  class="col-xs-12 col-md-12 col-md-12" *ngIf="formRegister.errors?.sonIguales && !showpass ">
                                <div class="form-group">
                                    <p class="text-danger text-center">
                                        Las contraseñas debe de ser iguales.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <a class="btn btn-primary float-left" style="background-color: rgb(0, 54, 113);"
                            href="javascript:history.back()"><i class="fas fa-chevron-left"></i> Volver</a>
                        <div class="text-center">
                            <button [disabled]="cargandoRegistro" type="submit"
                                style="background-color: rgb(0, 54, 113); margin-right: 100px;"
                                class="btn btn-primary"><i *ngIf="cargandoRegistro" class="fas fa-sync fa-spin"></i>
                                Registrarse</button>
                        </div>
                    </form>
                </div>
            </div>



        </div>
    </div>
</div>