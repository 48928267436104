import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { URL_SERVICE } from 'src/app/config/config';
import { Router } from '@angular/router';
import { DataApi } from 'src/app/interfaces/dataRespuesta.interface';

//
import { UsuarioLogin } from 'src/app/models/usuario.model';
import sha1 from 'js-sha1';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  

  usuario: UsuarioLogin;
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    private dialogRef: MatDialog
  ) { 
    this.cargarStorage();
  
  }
  
  encryptPassword(pass: String){
    return sha1(pass);
  }

  estaLogueado(){
    /*|| this.token.length<=0 */
    if (!this.usuario ){
      return false;
    }else{
      return true;
    }
    //return (this.token.length<=0) ? false: true;
  }

  logout(){
    this.token = '';
    this.usuario = null;
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.dialogRef.closeAll();
    this.router.navigate(['/login']);
  }

  cargarStorage(){
    if(localStorage.getItem('token')){
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
    }else{
      this.token = '';
      this.usuario = null;
    }
  }

  guardarStorage(token:string ,usuario: any){
    this.usuario = usuario;
    this.token = token;
    localStorage.setItem('token',token);
    localStorage.setItem('usuario',JSON.stringify(usuario));
  }
  
  iniciarSesion(usuario: any){
    let url = URL_SERVICE + '/iniciarSesion';
    return this.http.post<DataApi>(url,usuario)
  }

  obtenerMenu(){
    let url = URL_SERVICE + '/seguridad/menu/mostrar';
    return this.http.post<DataApi>(url,{"idMenuPadre":0 });
  }

}
