<br><br>
<mat-dialog-content>
    <div class="contenedor1">
        <table *ngIf="puntaje==false" class="table  table-sm">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Síntomas</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let respuesta of data.preguntas[0].respuestas">
                    <th scope="row">{{respuesta.respuesta}}</th>
                    <td>
                        <mat-checkbox (click)="enviarRespuestaEncuesta(respuesta)" [(ngModel)]="respuesta.seleccionada"></mat-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="puntaje==true" class="table  text-center table-sm">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Fecha Enviada</th>
                    <th scope="col">Puntos obtenidos</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let punto of puntos|orderBy:order:true">
                    <th *ngIf="punto.totalPuntaje!=0" scope="row">{{punto.fechaEncuesta}}, {{punto.horaEncuesta}}</th>
                    <th *ngIf="punto.totalPuntaje!=0" scope="row">{{punto.fechaFinalizada}}, {{punto.horaFinalizada}}</th>
                    <th *ngIf="punto.totalPuntaje!=0" scope="row">{{punto.totalPuntaje}}</th>
                </tr>
            </tbody>
        </table>

    </div>
</mat-dialog-content>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Salir</button>
    <button type="button" class="btn btn-info" *ngIf="puntaje==false" (click)="obtenerPuntaje()">Puntaje</button>
    <button type="button" class="btn btn-success" *ngIf="puntaje==false" (click)="getEncuestaHistorial()">Historial</button>
    <button type="button" class="btn btn-primary" *ngIf="puntaje==false" (click)="finalizarEncuesta()">Evaluar</button>
</div>