import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { encuaesta } from 'src/app/models/paciente.model';
import { ConsultarapidamodalComponent } from 'src/app/components/patient/consultarapidamodal/consultarapidamodal.component';
import { PatologiabasemodalComponent } from 'src/app/components/patient/patologiabasemodal/patologiabasemodal.component';
import { PatientmodalComponent } from 'src/app/components/patient/patientmodal/patientmodal.component';
import { PsicologicomodalComponent } from 'src/app/components/patient/psicologicomodal/psicologicomodal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  public estadoPsicologico: boolean = true;
  constructor(
    private matDialog: MatDialog,
    public _pacienteService: PacienteService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  openEncuestaRapida(){
    this._pacienteService.getEncuesta(2).subscribe(result=>{
      this.openModalEncuestaRapida(result.data.Table0[0].encuesta[0]);
    });
      /*
    this._pacienteService.verificarEncuesta(3).subscribe(result=>{
      if(!result.data.Table0[0].encuestaGeneralfinalizada || result.hasError){
        this._pacienteService.getEncuesta(3).subscribe(result=>{
          this.openModalPaciente();
        });
      }else{
        this._pacienteService.getEncuesta(2).subscribe(result=>{
          this.openModalEncuestaRapida(result.data.Table0[0].encuesta[0]);
        });
      }
    });
    */
   
  }

openModalPaciente(){
 //modalOpen
 const dialogRef = this.matDialog.open(PatientmodalComponent,{
  disableClose: true,
  autoFocus:true,
  width: "40%"
});

dialogRef.afterClosed().subscribe(result=>{

});
}

openModalPatologica(encuesta: encuaesta){
   
    //modalOpen
    const dialogRef = this.matDialog.open(PatologiabasemodalComponent,{
      disableClose: true,
      autoFocus:true,
      width: "40%",
      data: encuesta
    });

    dialogRef.afterClosed().subscribe(result=>{

    });
  }

  openModalEncuestaRapida(encuesta: encuaesta){
    //Modal config
    const dialogConfig =  new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    //modalOpen
    const dialogRef = this.matDialog.open(ConsultarapidamodalComponent,{
      disableClose: true,
      autoFocus:true,
      height: '590px', 
      width: '500px',
      hasBackdrop: true,
      data: encuesta
    });

    dialogRef.afterClosed().subscribe(result=>{

    });
  }
  openModalPsicologico(encuesta){
    const dialogRef = this.matDialog.open(PsicologicomodalComponent,{
      disableClose: true,
      autoFocus:true,
      height: '300px', 
      width: '400px',
      hasBackdrop: true,
      data:encuesta
    });

    dialogRef.afterClosed().subscribe(result=>{
     
      
    });
  }

  obtenerEncuetaPsicologica(){

    this.router.navigate(['/sintomas']);

    /*
    this._pacienteService.getEncuesta(1).subscribe(result=>{
      
      this.openModalPsicologico(result.data.Table0[0].encuesta[0]);
    });
    */
  }

}
