import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegisterService, UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-micuenta',
  templateUrl: './micuenta.component.html',
  styleUrls: ['./micuenta.component.css']
})
export class MicuentaComponent implements OnInit {

  //Variables
  formMiCuenta: FormGroup
  public cargando: boolean = false;
  public showpass: boolean = false;

  get correo(){return this.formMiCuenta.get('correo');}
  get motivo(){return this.formMiCuenta.get('motivo');}
  get clave(){return this.formMiCuenta.get('clave');}

  constructor(
    private route: Router,
    private toastr: ToastrService,
    private _register: RegisterService,
    private _usuario: UsuarioService
  ) { }

  ngOnInit(): void {
    this.formMiCuenta = new FormGroup({
      correo: new FormControl(null,Validators.required),
      motivo: new FormControl(null,Validators.required),
      clave: new FormControl(null,Validators.required)
    });
  }
  showpassword() {
    this.showpass = !this.showpass;
  }

  cambioCorreo(){
    if(this.formMiCuenta.value.correo == null || this.formMiCuenta.value.correo == ""){
      this.toastr.info("Debe ingresar el nuevo correo eléctrico.","Cambio de correo");
      return;
    }else if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.formMiCuenta.value.correo)){
      this.toastr.info("Debe ingresar un correo eléctrico válido.","Cambio de correo");
      return;
    }else if(this.formMiCuenta.value.motivo == null || this.formMiCuenta.value.motivo == ""){
      this.toastr.info("Debe ingresar un motivo.","Cambio de correo");
      return;
    }else if(this.formMiCuenta.value.clave == null || this.formMiCuenta.value == ""){
      this.toastr.info("Debe ingresar la clave.","Cambio de correo");
      return;
    }else{
      this.cargando = true;
      this._register.actualizarCorreo({
        nuevoCorreo:this.formMiCuenta.value.correo,
        motivo: this.formMiCuenta.value.motivo,
        clave: this.formMiCuenta.value.clave
      }).subscribe(result=>{
        if(result.hasError){
          if(result.errors.length > 0){
            this.toastr.info(result.errors[0].descripcion);
          }
        }else{
          if(result.data.Table0.length > 0){
            this.toastr.success(result.data.Table0[0].descripcion);
            this._usuario.usuario.usuario = this.formMiCuenta.value.correo;
            localStorage.setItem("usuario",JSON.stringify( this._usuario.usuario));
          } 
        }
        this.cargando = false;
      });
    }
  }

}
