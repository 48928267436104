import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { MatDialogRef } from '@angular/material/dialog';
import{NgxSpinnerService}from 'ngx-spinner';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';
import { InterfaceEncuesta } from '../../models/paciente.model';

@Component({
  selector: 'app-patologias',
  templateUrl: './patologias.component.html',
  styleUrls: ['./patologias.component.css']
})
export class PatologiasComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<PatologiasComponent>,
  private spinnerServi:NgxSpinnerService,
  private _pacienteService: PacienteService,
  private toastr: ToastrService) { }
  contador: number = 0;
  cargar:any
  ngOnInit(): void {
    this.spinnerServi.hide();
   
  }

  enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){
      this._pacienteService.postEncuestaVac({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.data[0].idRegistroEncuesta,
        idPregunta: this.data[0].preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta,idUsuario:this.data[1]
      }).subscribe(result=>{
      });
    }else{
      this._pacienteService.eliminarRespuestaVac(respuesta.idRespuesta,this.data[1]).subscribe(result=>{
        
      });
    }
  }

  finalizarEncuesta(){
    this._pacienteService.finalizarEncuestaVac(this.data[0].idRegistroEncuesta,this.data[1]).subscribe(result=>{
      this.toastr.success("Diagnóstico guardado");
      this.dialogRef.close();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }


}
