import { Component, OnInit, ViewChild } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { citaData } from 'src/app/models/paciente.model';

@Component({
  selector: 'app-citas',
  templateUrl: './citas.component.html',
  styleUrls: ['./citas.component.css']
})
export class CitasComponent implements OnInit {
  
  displayedColumns: string[] = ['nombreDoctor', 'fechaCita', 'horaCita','observaciones','nombreDictamen'];
  dataSource: MatTableDataSource<citaData>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  cargando: boolean =  true;
  
  constructor(
    private _pacienteService: PacienteService,
    private pagina: MatPaginatorIntl
  ) { 
     this.pagina.itemsPerPageLabel = 'Registros por página';
     this.pagina.nextPageLabel = 'Siguiente Pagina';
     this.pagina.previousPageLabel = 'Pagina Anterior';
     this.pagina.lastPageLabel = 'Ultima Pagina';
     this.pagina.firstPageLabel = 'Primera Pagina';

     //Form of Data
     this._pacienteService.getCitas().subscribe(result=>{
       
      this.dataSource = new MatTableDataSource(result.data.Table0);
      this.cargando = false;
    });
     
  }

  ngOnInit(): void {
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }




}







