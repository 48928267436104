<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 30px; color: white">Cargando...</p>
</ngx-spinner>
<div class="form-container">
    <div class="col-lg-12 text-center">
        <label class="display-4">Registro de usuarios</label>
    </div>
    <form [formGroup]="apellidoForm" (ngSubmit)="buscarPersona()" autocomplete="off">
        <div class="modal-body">

            <div class="form-group">

                <div class="col-sm-12 col-md-8 col-lg-10">
                    <label for="apellido">1. Buscar por apellido</label>
                    <div class="row">
                        <div class="col-sm-12 col-md-8 col-lg-3">
                            <input type="text" name="apellido" id="apellido" formControlName="apellido" class="form-control form-control-sm">
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-7">
                            <button type="submit" class="btn btn-primary btn-sm">Buscar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <hr>
    <div class="table12" *ngIf="dataSi==true">
        <h2 class="text-center">Listado</h2>
        <div class="table-responsive table12">
            <table class="table table-striped table-hover  table-condensed table-bordered">
                <thead>
                    <tr>
                        <th>
                            <span>Identidad</span>
                        </th>
                        <th>
                            <span>Nombres</span>
                        </th>
                        <th>
                            <span>Apelidos</span>
                        </th>
                        <th>
                            <span>Teléfono</span>
                        </th>
                        <th>
                            <span>Profesión</span>
                        </th>
                        <th>
                            <span>Correo electrónico</span>
                        </th>
                        <th>
                            <span>Rol</span>
                        </th>
                        <th><span>Opciones</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> <input type="text" class="form-control" name="identidad" [(ngModel)]="identidad" placeholder="Buscar por Identidad" title="Buscar por Identidad" /></td>
                        <td>
                            <input type="text" class="form-control" name="filterPost" [(ngModel)]="filterPost" placeholder="Buscar por Nombre" title="Buscar por Nombre" /></td>
                        <td><input type="text" class="form-control" name="apellidos" [(ngModel)]="apellidos" placeholder="Buscar por Apellido" title="Buscar por Apellido" /></td>
                        <td><input type="text" class="form-control" name="telefonoMovil" [(ngModel)]="telefonoMovil" placeholder="Buscar por Telefono" title="Buscar por Telefono" /></td>
                        <td><input type="text" class="form-control" name="profesion" [(ngModel)]="profesion" placeholder="Buscar por profesión" title="Buscar por profesión" /></td>
                        <td><input type="text" class="form-control" name="correoe" [(ngModel)]="correoe" placeholder="Buscar por correo" title="Buscar por correo" /></td>
                        <td><input type="text" class="form-control" name="rol" [(ngModel)]="rol" placeholder="Buscar por rol" title="Buscar por Rol" /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <tr *ngFor="let user of users | apellidos:apellidos | identidad:identidad|telefonoMovil:telefonoMovil|correoe:correoe |rol:rol |profesion:profesion| filter:filterPost | paginate:{itemsPerPage:7,currentPage:pageActual }">
                            <td> {{user.identidad}}</td>
                            <td> {{user.nombres}}</td>
                            <td> {{user.apellidos}}</td>
                            <td> {{user.telefonoMovil}}</td>
                            <td> {{user.profesion}}</td>
                            <td>{{user.correoElectronico}}</td>
                            <td> {{user.rol}}</td>

                            <td>

                                <div class=" btn-group">
                                    <button type="button" *ngIf="user.idRol!=2" (click)="agregarDoctor(user)" title="Agregar como doctor" [disabled]="user.idRol==2" class="btn btn-primary btn-xs">
                                <i class="fa fa-check"></i>
                            </button>
                                    <button type="button" *ngIf="user.idRol!=4" (click)="agregarEnfermera(user)" title="Agregar como enfermera" [disabled]="user.idRol==4" class="btn btn-success btn-xs">
                                <i class="fa fa-bed"></i>
                            </button>
                                    <button type="button" *ngIf="user.idRol!=5" (click)="agregarEnfermeroDoctor(user)" title="Agregar como enfermero-doctor" [disabled]="user.idRol==5" class="btn btn-danger btn-xs">
                                <i class="fa fa-plus"></i>
                            </button>
                                    <button type="button" *ngIf="user.idRol!=1" (click)="agregarAdmin(user)" title="Agregar como administrador" [disabled]="user.idRol==1" class="btn btn-warning btn-xs">
                                <i class="fa fa-hospital-o"></i>
                            </button>
                                    <button type="button" *ngIf="user.idRol!=3" (click)="backPaciente(user)" title="Regresar a paciente" [disabled]="user.idRol==3" class="btn btn-info btn-xs">
                                <i class="fa fa-remove"></i>
                            </button>
                                </div>
                            </td>
                        </tr>

                </tbody>
            </table>
            <pagination-controls (pageChange)="pageActual = $event"></pagination-controls>
        </div>
    </div>