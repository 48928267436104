<br><br>
<div mat-dialog-title class="modal-header text-center">
    <h5 class="modal-title" id="exampleModalScrollableTitle">Modal title</h5>
</div>
<mat-dialog-content>
    Se siente mal de Salud?
</mat-dialog-content>
<mat-dialog-actions class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">NO</button>
    <button type="button" class="btn btn-primary" (click)="actionFunction()">SI</button>
</mat-dialog-actions>