import { Component, OnInit, Inject } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encuaesta } from 'src/app/models/paciente.model';
import { ConsultarapidamodalComponent } from '../consultarapidamodal/consultarapidamodal.component';

@Component({
  selector: 'app-psicologicomodal',
  templateUrl: './psicologicomodal.component.html',
  styleUrls: ['./psicologicomodal.component.css']
})
export class PsicologicomodalComponent implements OnInit {

  respuestas = [
    {
      pregunta: 1,
      respuesta: "",
      estado: false,
      idRespuesta: 0
    },
    {
      pregunta: 2,
      respuesta: "",
      estado: false,
      idRespuesta: 0
    }
  ]
  constructor(
    public dialogRef: MatDialogRef<PsicologicomodalComponent>,
    private _pacienteService: PacienteService,
    @Inject(MAT_DIALOG_DATA) public encuesta: encuaesta,
    private toastr: ToastrService,
    private matDialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.respuestas = [
      {
        pregunta: 0,
        respuesta: "",
        estado: false,
        idRespuesta: 0
      },
      {
        pregunta: 1,
        respuesta: "",
        estado: false,
        idRespuesta: 0
      }
    ]

    if(localStorage.getItem("respuestasPsicologicas")){
      let respuestasPsico =  JSON.parse(localStorage.getItem("respuestasPsicologicas"));
      respuestasPsico.forEach(element => {
        this.EliminarRespuesta(element.idRespuesta);
      });
    }


  }

  EliminarRespuesta(idRespuesta){
    this._pacienteService.eliminarRespuesta(idRespuesta).subscribe(result=>{
     
     });
  }

  opcionRespuesta(nombre: string,pregunta:number){
    if(pregunta == 0){
      if(nombre == 'like'){
        this.respuestas[pregunta].respuesta = nombre;
        this.respuestas[pregunta].estado = true;
        if(this.respuestas[0].estado){
          this.EliminarRespuesta(this.encuesta.preguntas[0].respuestas[1].idRespuesta);
        }

        if(this.respuestas[0].estado && this.respuestas[1].estado){
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[0].respuestas[0].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[0].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[0].respuestas[0].idOpcionRespuesta
          }).subscribe(result=>{
            this.dialogRef.close();
          
          
              this.encuesta.preguntas[0].respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
              this.respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
              localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          
          
          });
        }else{
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[0].respuestas[0].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[0].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[0].respuestas[0].idOpcionRespuesta
          }).subscribe(result=>{
            
              this.encuesta.preguntas[0].respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
              this.respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
              localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          

           
          });
        }
      }else if(nombre == 'dislike'){
        this.respuestas[pregunta].respuesta = nombre;
        this.respuestas[pregunta].estado = true;
        if(this.respuestas[0].estado){
          this.EliminarRespuesta(this.encuesta.preguntas[0].respuestas[0].idRespuesta);
        }
        if(this.respuestas[0].estado && this.respuestas[1].estado){
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[0].respuestas[1].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[0].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[0].respuestas[1].idOpcionRespuesta
          }).subscribe(result=>{
            this.dialogRef.close();
            
            this.encuesta.preguntas[0].respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }else{
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[0].respuestas[1].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[0].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[0].respuestas[1].idOpcionRespuesta
          }).subscribe(result=>{
        
            this.encuesta.preguntas[0].respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }
        
      }
    }else if(pregunta == 1){
      if(nombre == 'bien'){
        this.respuestas[pregunta].respuesta = nombre;
        this.respuestas[pregunta].estado = true;
        if(this.respuestas[1].estado){
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[1].idRespuesta);
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[2].idRespuesta);
        }
        if(this.respuestas[0].estado && this.respuestas[1].estado){
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[0].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[0].idOpcionRespuesta
          }).subscribe(result=>{
            this.dialogRef.close();
            this.encuesta.preguntas[1].respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }else{
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[0].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[0].idOpcionRespuesta
          }).subscribe(result=>{
            this.encuesta.preguntas[1].respuestas[0].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }
      }else if(nombre == 'normal'){
        this.respuestas[pregunta].respuesta = nombre;
        this.respuestas[pregunta].estado = true;
        if(this.respuestas[1].estado){
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[0].idRespuesta);
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[2].idRespuesta);
        }
        if(this.respuestas[0].estado && this.respuestas[1].estado){
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[1].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[1].idOpcionRespuesta
          }).subscribe(result=>{
            this.dialogRef.close();
            this.encuesta.preguntas[1].respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }else{
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[1].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[1].idOpcionRespuesta
          }).subscribe(result=>{
            this.encuesta.preguntas[1].respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }
      }else if(nombre == 'mal'){
        this.respuestas[pregunta].respuesta = nombre;
        this.respuestas[pregunta].estado = true;
        if(this.respuestas[1].estado){
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[0].idRespuesta);
          this.EliminarRespuesta(this.encuesta.preguntas[1].respuestas[1].idRespuesta);
        }
        if(this.respuestas[0].estado && this.respuestas[1].estado){
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[2].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[2].idOpcionRespuesta
          }).subscribe(result=>{
            this.dialogRef.close();
            this.encuesta.preguntas[1].respuestas[2].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }else{
          this._pacienteService.postEncuesta({
            idRespuesta:this.encuesta.preguntas[1].respuestas[2].idRespuesta,
            idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
            idPregunta: this.encuesta.preguntas[1].idPregunta,
            idOpcionRespuesta:this.encuesta.preguntas[1].respuestas[2].idOpcionRespuesta
          }).subscribe(result=>{
            this.encuesta.preguntas[1].respuestas[2].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            this.respuestas[1].idRespuesta = result.data.Table0[0].idRespuesta || 0;
            localStorage.setItem("respuestasPsicologicas",JSON.stringify(this.respuestas));
          });
        }
      }
    }
  }

  closeModal() {
    this.dialogRef.close(true);
  }

  

}
