import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ModalsintomasComponent } from '../modalsintomas/modalsintomas.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

 
  constructor(private matDialog: MatDialog,
    public dialogRef: MatDialogRef<ModalComponent>) { 
  }

  ngOnInit(): void {
  }
  actionFunction() {
    this.closeModal();
    this.openModal();
  }

  closeModal() {
    this.dialogRef.close();
  }


  openModal(){
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-sintomas-component";
    dialogConfig.height = "550px";
    dialogConfig.width = "800px";
    const modalDialog = this.matDialog.open(ModalsintomasComponent, dialogConfig);
}

}
