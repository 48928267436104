export class UsuarioRegister {
constructor(
    public id: number = 0,
    public nombres: string,
    public apellidos: string,
    public numeroIdentificacion: string,
    public idDocumentoIdentificacion: number,
    public fechaNacimiento: string,
    public idNacionalidad: number,
    public telefonoMovil: string,
    public correo: string,
    public clave: string,
    public idGenero: number = 0,
    public idEstadoCivil: number = 0,
    public idNivelEducativo: number = 0,
    public ocupacion: string = "",
    public profesion: string = "",
    public idRol: number = 3,
    public idMunicipio: number = 0,
    public direccion: string = "",
    public telefonoFijo: string = "",
    public cuenta: string = ""

){}
}

export class Usuario{
    constructor(
        public usuario: string,
        public clave: string,
        public posicionX: string,
        public posicionY: string,
        public tipoDispositivo: string = "web"
    ){}
}

export class UsuarioLogin{
    constructor(
        public idPersona: number,
        public idUsuario: number,
        public usuario: string,
        public nombres: string,
        public apellidos: string,
        public telefonoFijo: string,
        public telefonoMovil: string,
        public idRol: number,
        public idDoctor: number,
        public rol: string,
        public idEncuenta: number = 0,
        public idCategoria: number = 0,
        public id: number = 0,
        public activoEvaluar: boolean = false,
        public correo: string = ""

    ){

    }
}

export class citas {
    constructor(
        public id :number,
        public estadoCita: string,
        public fechaCita: Date,
        public horaCita: Date,
       public idPersona:string,
       public idDoctor :number,
       public observaciones:string,
       public idUsuario:number,
       public dictamen:string
    
    ){}
    }