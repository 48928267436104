<nav class="navbar fixed-top top-navbar navbar-expand-md navbar-light nav-back">
    <br>
</nav>
<br><br><br>
<div class="container form-register">
    <div class="row justify-content-md-center align-items-center">
        <div class="col-12">
            
            <div class="card">
                
                <div class="card-body form-card">
                    <form class="form-horizotal form-material" [formGroup]="formReinicio" (ngSubmit)="reinicioContrasena()" autocomplete="off">
                        <div class="row ">
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140" alt="logo_unah">
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4">
                                <h1 class="text-center">Recuperar contraseña</h1>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <div class="">
                                    <img class="" src="../../../assets/image/logo/logo_degt.png" width="210" alt="logo_medicina">
                                </div>
                            </div>
                        </div>
                        <hr>
                        
                        <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-12">
                                <div class="form-group" [ngClass]="{'text-danger': correoElectronico.dirty && correoElectronico.invalid }">
                                    <label for="correoElectronico">Correo electrónico</label>
                                    <input (ngModelChange)="reiniciar()" maxlength="80" formControlName="correoElectronico" type="text" class="form-control form-control-sm" name="correoElectronico" id="correoElectronico" aria-describedby="helpId" placeholder="Ingrese su correo electrónico">
                                    
                                  </div>
                            </div>
                        </div>
                        <div *ngIf="mensaje" class="row">
                            <div class="col-xs-12 col-md-12 col-lg-12 text-center">
                                <div class="alert alert-info" role="alert">
                                    <p>{{respuesta}}</p>
                                  </div>
                            </div>
                        </div>
                        
                        <hr>
                        <div class="text-center">
                            <a class="btn btn-primary float-left" style="background-color: rgb(0, 54, 113);" href="javascript:history.back()"><i class="fas fa-chevron-left"></i> Volver</a>
                            <div class="text-center">
                                    <button [disabled]="bloqueo" type="submit" style="background-color: rgb(0, 54, 113); " class="btn btn-primary btn-menu-reiniciar"><i *ngIf="cargando" class="fas fa-sync fa-spin"></i>Recuperar</button>
                            </div>
                        </div>
                        
                    </form> 
                </div>
            </div>
        </div>
    </div>
    
    <br><br><br><br><br><br>
</div>

