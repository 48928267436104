<br><br>

<mat-dialog-content>
<h1 class="text-center">{{encuesta.preguntas[0].pregunta}}</h1>
<hr>
<table class="table table-striped table-hover table-sm">
    <thead class="thead-light">
        <tr>
            <th scope="col">Pregunta</th>
            <th scope="col">Respuesta</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let respuesta of encuesta.preguntas[0].respuestas">
            <th scope="row">{{respuesta.respuesta}}</th>
            <td>
                <mat-checkbox (click)="enviarRespuestaEncuesta(respuesta)" [(ngModel)]="respuesta.seleccionada"></mat-checkbox>
            </td>
        </tr>
    </tbody>
</table>

</mat-dialog-content>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Salir</button>
    <button type="button" class="btn btn-primary" (click)="finalizarEncuesta()" >Enviar</button>
</div>