import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Pages
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { ReiniciocontrasenaComponent } from './account/reiniciocontrasena/reiniciocontrasena.component';
import { ConfirmarcontrsenaComponent } from './account/confirmarcontrsena/confirmarcontrsena.component';



const routes: Routes = [
  {path:'login', component:LoginComponent},
  {path:'register',component:RegisterComponent},
  {path:'reiniciar',component:ReiniciocontrasenaComponent},
  {path:'confirmarReinicio',component:ConfirmarcontrsenaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
