import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { MatDialog } from '@angular/material/dialog';
import { PsicologicomodalComponent } from 'src/app/components/patient/psicologicomodal/psicologicomodal.component';
import { Router } from '@angular/router';
import { encuaesta } from 'src/app/models/paciente.model';
import { PatologiabasemodalComponent } from 'src/app/components/patient/patologiabasemodal/patologiabasemodal.component';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-sintomas',
  templateUrl: './sintomas.component.html',
  styleUrls: ['./sintomas.component.css']
})
export class SintomasComponent implements OnInit {

  public contador: number = 0;
  public encuesta: any;
  public contadorLlenado: number = 0
  public contadorDengue: number = 0

  public cargando: boolean = true;

  constructor(
    private _pacienteService: PacienteService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private router: Router,
    private _userLogin: UsuarioService
  ) { }

  ngOnInit(): void {
    if(this._userLogin.usuario.activoEvaluar){
      this.obtenerEncuetaPsicologica();
    }else{
      this.obtenerEncuesta();
    }
    

    this.contador = 0;
    this.contadorLlenado = 0;
    this.contadorDengue = 0;
  }

  /** */

  verificarEncuestaPatologica(){
    this._pacienteService.getPacienteEncuestas().subscribe(result=>{
      if(result.data.Table0.length <= 0){
        this.obtenerEncuesta();
      }else{
        if(!result.data.Table0[0].finalizada){
          this.obtenerEncuesta();
        }
      }
    });
  }
  obtenerEncuesta(){
    this._pacienteService.getEncuesta(3).subscribe(result=>{
      if(result.data.Table0[0].encuesta[0]){
        this.openModal(result.data.Table0[0].encuesta[0]);
      }
    });
  }
  openModal(encuesta: encuaesta){
    if(encuesta){
      //modalOpen
      const dialogRef = this.matDialog.open(PatologiabasemodalComponent,{
        disableClose: true,
        autoFocus:true,
        height: '600px', 
        width: '500px',
        hasBackdrop: true,
        data: encuesta
      });
      dialogRef.afterClosed().subscribe(result=>{
        if(result){
          this._userLogin.usuario.activoEvaluar = true;
          localStorage.setItem('usuario',JSON.stringify(this._userLogin.usuario));
          this.obtenerEncuetaPsicologica();
        }else{
          this.router.navigate(['/default']);
        }
      });
    }
    
  }
  /** */
  openModalPsicologico(encuesta){
    const dialogRef = this.matDialog.open(PsicologicomodalComponent,{
      disableClose: true,
      autoFocus:true,
      height: '350px', 
      width: '400px',
      hasBackdrop: true,
      data:encuesta
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.router.navigate(['/default']);
      }else{
        this._pacienteService.getEncuesta(2).subscribe(result=>{
          this.encuesta = result.data.Table0[0].encuesta[0];
          this.contador = this.conteoPreguntas();
          this.cargando = false;
        });
      }
    });
  }

  obtenerEncuetaPsicologica(){
    this._pacienteService.getEncuesta(1).subscribe(result=>{
      this.openModalPsicologico(result.data.Table0[0].encuesta[0]);
    });
  }
  
  conteoPreguntas():any{
    let cont = 0
    this.encuesta.preguntas[0].respuestas.forEach(function (value) {
      if((value.idTipoRespuesta == 28 && value.seleccionada) || (value.idTipoRespuesta == 27 && value.seleccionada) || (value.idTipoRespuesta == 26 && value.seleccionada)){
        cont += 3;
      }else if((value.idTipoRespuesta == 7 && value.seleccionada) || (value.idTipoRespuesta == 5 && value.seleccionada) ){
        cont += 2;
      }else if(value.seleccionada && value.idTipoRespuesta != 7 && value.idTipoRespuesta != 5 && value.idTipoRespuesta != 28 && value.idTipoRespuesta != 27 && value.idTipoRespuesta != 26){
        cont +=1
      }
      
    }); 
    return cont
    
  }

  enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){
      if(respuesta.idTipoRespuesta == 28 || respuesta.idTipoRespuesta == 27 || respuesta.idTipoRespuesta == 26){
        this.contador += 3;
      }else if(respuesta.idTipoRespuesta == 7 || respuesta.idTipoRespuesta == 5 ){
        this.contador += 2;
      }else{
        this.contador += 1;
      }
      this._pacienteService.postEncuesta({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
        idPregunta: this.encuesta.preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta
      }).subscribe(result=>{
        
        respuesta.idRespuesta = result.data.Table0[0].idRespuesta || 0;
      
      });
    }else{
      if(respuesta.idTipoRespuesta == 28 || respuesta.idTipoRespuesta == 27 || respuesta.idTipoRespuesta == 26){
        this.contador -= 3;
      }else if(respuesta.idTipoRespuesta == 7 || respuesta.idTipoRespuesta == 5 ){
        this.contador -= 2;
      }else{
        this.contador -= 1;
      }
     
      this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe(result=>{
        
      });
    }
  }

  verificarLlenadoEncuesta(){
    this.contadorLlenado = 0;
    this.encuesta.preguntas[0].respuestas.forEach((value)=>{
        if(value.seleccionada === true) this.contadorLlenado += 1;
    });
    return this.contadorLlenado;
  }

  verficarDengue(){
    this.contadorDengue = 0;
    this.encuesta.preguntas[0].respuestas.forEach((value)=>{
        if((value.idTipoRespuesta == 24 || value.idTipoRespuesta == 3 || value.idTipoRespuesta == 22 || value.idTipoRespuesta == 23 || value.idTipoRespuesta == 19 ) && value.seleccionada === true) this.contadorDengue += 1;
    });
    return this.contadorDengue;
  }

  salirEncuesta(){

    if(localStorage.getItem("respuestasPsicologicas")){
      let respuestasPsico =  JSON.parse(localStorage.getItem("respuestasPsicologicas"));
      respuestasPsico.forEach(element => {
         this._pacienteService.eliminarRespuesta(element.idRespuesta).subscribe(result=>{
         
         });
      });
      localStorage.removeItem("respuestasPsicologicas");
    }
    this.router.navigate(['/default'])
  }

  finalizarEncuesta(){
    if(this.verificarLlenadoEncuesta() > 0){
      if(this.verificarLlenadoEncuesta() == this.verficarDengue() && this.verficarDengue() == 5){
        
        this.toastr.info("En este momento según tu evaluación no eres sospechoso de COVID 19, pero debes valorarte por otra enfermedad febril.","Evaluación");
        this.router.navigate(['/default']);
      }else {
        this._pacienteService.finalizarEncuesta(this.encuesta.idRegistroEncuesta).subscribe(result=>{
          
          if (this.contador < 6){
            this.toastr.success("Según los datos marcados no eres sospechoso de COVID 19.");
            this.router.navigate(['/default']);
          }else if(this.contador > 5 && this.contador < 12){
            this.toastr.warning("Según los datos marcados eres sospechoso de COVID 19.");
            this._pacienteService.guardarCita().subscribe(result=>{
              if(!result.hasError){
                this.toastr.info("Se ha agendado una cita por el resultado de su evaluación","Cita")
              }
              this.router.navigate(['/default']);
            });
          }else{
            this.toastr.error("Según los datos marcados eres altamente sospechoso de COVID 19.");
            this._pacienteService.guardarCita().subscribe(result=>{
              if(!result.hasError){
                this.toastr.info("Se ha agendado una cita por el resultado de su evaluación","Cita")
              }
              this.router.navigate(['/default']);
            });
          }
          
          localStorage.removeItem("respuestasPsicologicas");

        });
      }
    }else{
      this.toastr.info("Para enviar la evaluación mínimo se debe seleccionar una respuesta.","Evaluación");
    }
   
   
  }

}
