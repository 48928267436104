import {Component,OnInit} from '@angular/core';
import {MatDialogConfig,MatDialog} from '@angular/material/dialog';
import{NgxSpinnerService}from 'ngx-spinner';
import { CitasComponent } from '../../components/doctor/citas/citas.component';
import { ModalsintomasComponent } from 'src/app/components/doctor/modalsintomas/modalsintomas.component';
import { MapComponent } from 'src/app/components/map/map.component';
import { ModalpatologiasComponent } from 'src/app/components/doctor/modalpatologias/modalpatologias.component';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { UsuarioService } from 'src/app/services/service.index';
import { ValidarComponent } from '../../components/doctor/validar/validar.component';
import { ToastrService } from 'ngx-toastr';
import { AtendidoComponent } from '../../components/doctor/atendido/atendido.component';
import { PerfilComponent } from '../../components/doctor/perfil/perfil.component';
import { VacunasComponent } from '../../components/doctor/vacunas/vacunas.component';


@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit {
  pageActual: number = 1;
  filterPost = '';
  departamentos = '';
  identidad='';
  apellidos='';
  municipios='';
  telefonoMovil='';
  encuesta: any = [];
  usuario :any=[];
  idEncuenta:number;
  idUsuario:number;
  idPersona:number;
  consulta:any;
  encuestaPato:any;
  pipes='';
  users: any;
  order: any = 'totalpuntos';
  historial:any;
  EstadoPacientePipe='';

  constructor(private matDialog: MatDialog, private modal:MatDialog,
    private spinnerServi:NgxSpinnerService,
     private userService:DoctorService,
     public _usuarioService: UsuarioService,
     private toastr:ToastrService) {
     }
     rol:any={};
     menus:any={};
     menu1 :boolean=false;
     menu2 :boolean=false;
     menu3 :boolean=false;
     menu4 :boolean=false;
     menuMostrar(){
       this.rol.idMenuPadre=5
       this.userService.menuRoles(this.rol).subscribe(
         (data:any)=>{
          
         for (let i = 0; i < data.data.Table0.length; i++) {

           if (data.data.Table0[i].idMenu==7) {
             this.menu1=true
            } 
           
           if (data.data.Table0[i].idMenu==10) {
            this.menu2=true
          }
          
          if (data.data.Table0[i].idMenu==11) {
            this.menu3=true
          } 
         
          if (data.data.Table0[i].idMenu==12) {
            this.menu4=true
          } 
           
         }
        }
       )
     }

  ngOnInit(): void { 
    this.menuMostrar();
    setTimeout(() => {
      this.getlistUser1(); 
       this.getlistUser2(); 
    }, 500);
       
  }
  pacientesNuevos1:any;
  pacientesAtendidos1:any;
  pacientesSeguimiento1:any;
  pacientesNuevos2:any;
  pacientesAtendidos2:any;
  pacientesSeguimiento2:any;
  pacientesNuevos3:any;
  pacientesAtendidos3:any;
  pacientesSeguimiento3:any;
  pacientesRechazados:any;

user1:any={};

  getlistUser1(){
    this.user1.idEstadoSeguimientoPaciente = 1;
     this.spinnerServi.show();
    this.userService
    .getUsers(this.user1)
    .subscribe(
      (data:any) => { // Success
         this.users = data.data.Table0;
 for (let index = 0; index < this.users.length; index++) {
   const element = this.users[index];
   
   var hoy = new Date();
   var cumpleanos = new Date(this.users[index].fechaNacimiento);
   var edad = hoy.getFullYear() - cumpleanos.getFullYear();
   var m = hoy.getMonth() - cumpleanos.getMonth();

   if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
       edad--;
   }
    this.users[index].edad=(edad);
 }
         this.pacientesNuevos1 = this.users.filter(value=> (value.idEstadoSeguimientoPaciente == 1 || value.idEstadoSeguimientoPaciente ==null)&& value.totalpuntos>=12)
         this.pacientesNuevos2 = this.users.filter(value=> (value.idEstadoSeguimientoPaciente == 1 || value.idEstadoSeguimientoPaciente ==null)&& value.totalpuntos>5 && value.totalpuntos<12)
         this.pacientesNuevos3 = this.users.filter(value=> (value.idEstadoSeguimientoPaciente == 1 || value.idEstadoSeguimientoPaciente ==null)&& value.totalpuntos<=5)
         this.pacientesRechazados =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 4)
         if (this.users!=null) {
              this.spinnerServi.hide();
         }
      }
    );
  }
  userR:any={};
  getlistRechazados(){
    this.userR.idEstadoSeguimientoPaciente =4 ;
     this.spinnerServi.show();
    this.userService
    .getUsers(this.userR)
    .subscribe(
      (data:any) => { // Success
         this.users = data.data.Table0;
 for (let index = 0; index < this.users.length; index++) {
   const element = this.users[index];
   
   var hoy = new Date();
   var cumpleanos = new Date(this.users[index].fechaNacimiento);
   var edad = hoy.getFullYear() - cumpleanos.getFullYear();
   var m = hoy.getMonth() - cumpleanos.getMonth();

   if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
       edad--;
   }
    this.users[index].edad=(edad);
 }
         this.pacientesRechazados =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 4)
         if (this.users!=null) {
              this.spinnerServi.hide();
         }
      }
    );
  }
  user2:any={};
  getlistUser2(){
    this.user2.idEstadoSeguimientoPaciente = 2;
    this.spinnerServi.show();
    this.userService
    .getUsers(this.user2)
    .subscribe(
      (data:any) => { // Success
         this.users = data.data.Table0;
 for (let index = 0; index < this.users.length; index++) {
   const element = this.users[index];
   
   var hoy = new Date();
   var cumpleanos = new Date(this.users[index].fechaNacimiento);
   var edad = hoy.getFullYear() - cumpleanos.getFullYear();
   var m = hoy.getMonth() - cumpleanos.getMonth();

   if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
       edad--;
   }
    this.users[index].edad=(edad);
 }
        
         this.pacientesAtendidos1 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 2 && value.totalpuntos>=12)
         this.pacientesAtendidos2 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 2 &&value.totalpuntos>5 && value.totalpuntos<12)
         this.pacientesAtendidos3 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 2 && value.totalpuntos<=5)
      
         if (this.users!=null) {
             this.spinnerServi.hide();
         }
      }
    );
  }
  user3:any={};
  getlistUser3(){
    this.user3.idEstadoSeguimientoPaciente = 3;
    this.spinnerServi.show();
    this.userService
    .getUsers(this.user3)
    .subscribe(
      (data:any) => { // Success
         this.users = data.data.Table0;
 for (let index = 0; index < this.users.length; index++) {
   const element = this.users[index];
   
   var hoy = new Date();
   var cumpleanos = new Date(this.users[index].fechaNacimiento);
   var edad = hoy.getFullYear() - cumpleanos.getFullYear();
   var m = hoy.getMonth() - cumpleanos.getMonth();

   if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
       edad--;
   }
    this.users[index].edad=(edad);
 }
         this.pacientesSeguimiento1 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 3&& value.totalpuntos>=12)
         this.pacientesSeguimiento2 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 3&&value.totalpuntos>5 && value.totalpuntos<12)
         this.pacientesSeguimiento3 =  this.users.filter(value=> value.idEstadoSeguimientoPaciente == 3 && value.totalpuntos<=5)
         if (this.users!=null) {
             this.spinnerServi.hide();
         }
      }
    );
  }

  misPacientes1:any;
  misPacientes2:any;
  misPacientes3:any;

userMisPacientes:any;
  getMisPaciente(){
    this.spinnerServi.show();
    this.userService
    .getMisPacientes()
    .subscribe(
      (data:any) => { // Success
         this.userMisPacientes = data.data.Table0;
         for (let index = 0; index < this.userMisPacientes.length; index++) {
          const element = this.userMisPacientes[index];
          
          var hoy = new Date();
          var cumpleanos = new Date(this.userMisPacientes[index].fechaNacimiento);
          var edad = hoy.getFullYear() - cumpleanos.getFullYear();
          var m = hoy.getMonth() - cumpleanos.getMonth();
       
          if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
              edad--;
          }
           this.userMisPacientes[index].edad=(edad);
        }
         this.misPacientes1 = this.userMisPacientes.filter(value=> value.totalpuntos>=12)
         this.misPacientes2 = this.userMisPacientes.filter(value=>  value.totalpuntos>5 && value.totalpuntos<12)
         this.misPacientes3 = this.userMisPacientes.filter(value=>  value.totalpuntos<=5)
         if (this.users!=null) {
             this.spinnerServi.hide();
         }
      }
    );
  }


  getEncuesta(user){
    this.spinnerServi.show();
    this.userService
    .getEncuestraMostrar(user)
    .subscribe(
      (data:any) => { // Success
        this.encuesta = data.data.Table0[0].encuesta[0]; 
        this.encuesta.idPersona=user.idPersona;
        this.open(this.encuesta);
      }
    );}
   
    getEncuestaPato(user){
      this.spinnerServi.show();
      this.userService
      .getEncuestaPatologica(user)
      .subscribe(
        (data:any) => { // Success
          this.encuestaPato = data.data.Table0[0].encuesta[0];
           this.openPatologica(this.encuestaPato);
        }
        
      );}


      openValidar(user) {
        user.deshabilitar=2;
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "validar-component";
        dialogConfig.height = "300px";
        dialogConfig.width = "600px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(ValidarComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser1(); 
          
        });
      };

      validarPaciente(user){  
        user.deshabilitar=2;
        user.id=0;
           this.userService.cambiarEstadoPaciente(user).subscribe(
              (data:any) => {
               
               if (data.hasError==true) {
                 this.toastr.error(data.errors[0].descripcion);
               } else {
                 user.id=data.data.Table0[0].id;
                 user.idEstadoSeguimientoPaciente=data.data.Table0[0].idEstadoSeguimientoPaciente;
                 this.openValidar(user);
                //  this.toastr.success('Paciente Validado');
                
               }
              }
            );
        
    
      }
      deshabilitarPaciente(user){
        user.deshabilitar=4;
        user.id=0;
      this.userService.cambiarEstadoPaciente(user).subscribe(
        (data:any) => {
         if (data.hasError==true) {
           this.toastr.error(data.errors[0].descripcion);
         } else {
          user.id=data.data.Table0[0].id;
          user.idEstadoSeguimientoPaciente=data.data.Table0[0].idEstadoSeguimientoPaciente;
        this.openDeshabilitar(user);
          //  this.toastr.warning('Paciente Deshabilitado');
         }
        }
      );

      }

      openDeshabilitar(user) {
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-sintomas-component";
        dialogConfig.height = "300px";
        dialogConfig.width = "600px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(ValidarComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser1(); 
          
        });
      };

      open(encuesta) {
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-sintomas-component";
        dialogConfig.height = "550px";
        dialogConfig.width = "600px";
        dialogConfig.data = encuesta;
        const modalDialog = this.matDialog.open(ModalsintomasComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser2();
          this.getlistUser3();  
          this.getMisPaciente(); 
          
        });
      };
      openPatologica(encuesta) {

        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-patologias-component";
        dialogConfig.height = "550px";
        dialogConfig.width = "600px";
        dialogConfig.data = encuesta;
        const modalDialog = this.matDialog.open(ModalpatologiasComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser2(); 
          this.getlistUser3(); 
          this.getMisPaciente(); 
          
        });
      };
      openMapa(user) {
        this.spinnerServi.show();
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "map-component";
        dialogConfig.height = "500px";
        dialogConfig.width = "700px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(MapComponent, dialogConfig);
      };
    
      openCitas(user) {
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "citas-component";
        dialogConfig.height = "500px";
        dialogConfig.width = "1200px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(CitasComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser2();
          this.getlistUser3();
           this.getMisPaciente(); 
          
        });
      };


citaAhora1:any;
hoy:any;
d:any;
data2:any={};
data3:any={};
      citaAhora(user){
        /* Función que suma o resta días a una fecha, si el parámetro
   días es negativo restará los días*/
// function sumarDias(fecha, horas){
//   fecha.setHours(fecha.getHours() - horas);
//   return fecha;
// }
// this.d = new Date();
// sumarDias(this.d, 6);
        this.user1.estadoCita=2,
        //this.hoy=this.d;
        // this.user1.fechaCita= this.hoy,
        // this.user1.horaCita= this.hoy.getHours()+':'+this.hoy.getMinutes(),
        this.user1.fechaCita= '',
        this.user1.horaCita= '',
         this.user1.idPersona= user.idPersona;
        this.user1.idDoctor="",
        this.user1.observaciones="",
        this.user1.id=0,
         this.user1.dictamen= "",
         this.user1.idUsuario=this._usuarioService.usuario.idUsuario
         this.user1.idDoctor=this._usuarioService.usuario.idDoctor
            this.userService.saveCitaAuto(this.user1).subscribe(
              (data:any) => {
               if (data.hasError==true) {
                 if (data.errors[0].descripcion=='La persona ya tiene una cita pendiente') {
                  this.toastr.warning('El paciente ya tenía una cita programada, se actualizara la hora, fecha o doctor'); 
                  this.userService.mostrarCita(this.user1).subscribe(
                   (data1:any)=>{
                     
                     data1=data1.data.Table0;
                     for (let index = 0; index < data1.length; index++) {
                       const element = data1[index];
                       if (data1[index].idEstadoCita==1) {
                         this.data2=data1[index];
                       this.data2.idPersona=user.idPersona;
                       this.data2.fechaCita= '',
                       this.data2.horaCita= '',
                       this.data2.idDoctor=this._usuarioService.usuario.idDoctor
                       }
                     }
                    this.openAtendido(this.data2);
                   }
                 )
                 }else{
                   this.toastr.error(data.errors[0].descripcion); 
                 }
                 
               } else {
                //  this.toastr.success('Cita ingresada correctamente'); 
                 this.userService.mostrarCita(this.user1).subscribe(
                   (data1:any)=>{
                     data1=data.data.Table0;
                     for (let index = 0; index < data1.length; index++) {
                       const element = data1[index];
                       if (data1[index].idEstadoCita==1) {
                        this.data3=data1[index];
                      this.data3.idPersona=user.idPersona;
                      }
                     }
                    this.openAtendido(this.data3);
                   }
                 )
               }
              }
            );
       }

       openAtendido(user1) {
        user1.noAtendido=2;
        const dialogConfig = new MatDialogConfig;
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        dialogConfig.id = "atendido-component";
        dialogConfig.height = "500px";
        dialogConfig.width = "1000px";
        dialogConfig.data = user1;
        const modalDialog = this.matDialog.open(AtendidoComponent, dialogConfig);
      }

      
      getPerfil(user) {
        const dialogConfig = new MatDialogConfig;
        dialogConfig.disableClose = true;
        dialogConfig.id = "perfil-component";
        dialogConfig.height = "500px";
        dialogConfig.width = "900px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(PerfilComponent, dialogConfig);
        modalDialog.afterClosed().subscribe(result=>{
          this.getlistUser1();
          
        });
      }

      openModalVacuna(user){
        const dialogConfig = new MatDialogConfig;
        dialogConfig.disableClose = true;
        dialogConfig.id = "vacunas-component";
        dialogConfig.height = "500px";
        dialogConfig.width = "900px";
        dialogConfig.data = user;
        const modalDialog = this.matDialog.open(VacunasComponent, dialogConfig);
        // modalDialog.afterClosed().subscribe(result=>{
        //   this.getlistUser1();
          
       // });

      }
}
