<div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12 ">
        <div class="container ">
            <div class="row contenedor-menu">
                <div class="row">
                    <div class="col-xs-12 col-md-12 col-lg-12">
                      <div  class="col-lg-12 col-sm-12 col-xs-6">
                        <div  (click)="obtenerEncuetaPsicologica()" class="card card-menu">
                          <div class="card-body text-center">
                              <i class="fas fa-stethoscope icon-menu"></i>
                              <p class="card-text">Evalúate</p>
                          </div>
                        </div>
                      </div>
                        <div class="col-lg-12 col-sm-12 col-xs-6">
                            <div class="card card-menu">
                              <div (click)="navegacion('/perfil')" class="card-body text-center">
                                <i class="fas fa-user icon-menu"></i>
                                <p class="card-text">Mi perfil</p>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
               
                <div class="row">
                    <div class="col-xs-12 col-md-12 col-lg-12">
                        <div class="col-lg-12 col-sm-12 col-xs-6">
                            <div (click)="navegacion('/citas')" class="card card-menu">
                              <div class="card-body text-center">
                                  <i class="far fa-calendar icon-menu"></i>
                                  <p class="card-text">Citas</p>
                              </div>
                            </div>
                          </div>
                       
                          <div class="col-lg-12 col-sm-12 col-xs-6">
                           
                            <div (click)="navegacion('/noticias')" class="card card-menu">
                              <div class="card-body text-center">
                                  <i class="fas fa-exclamation-circle icon-menu"></i>
                                  <p class="card-text">Noticias y enlaces</p>
                              </div>
                            </div>
                            
                          </div>
                    </div>
                </div>
                



                  
            </div>
        </div>
        
    </div>
</div>