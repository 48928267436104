<div class="row">
    <div class="col-12">
        <div class="container">
            <mat-card class="text-center" *ngIf="cargando">
                <img class="rounded mx-auto d-block col-xs-12 img-cargando-menu"  src="../../../assets/image/loading/load1.gif" alt="">
              </mat-card>
            <mat-card *ngIf="!cargando">
                <h1 class="text-center">{{encuesta.preguntas[0].pregunta}}</h1>
                <hr>
                <table class="table table-striped table-hover table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Pregunta</th>
                            <th scope="col">Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let respuesta of encuesta.preguntas[0].respuestas">
                            <th scope="row">{{respuesta.respuesta}}</th>
                            <td>
                                <mat-checkbox (click)="enviarRespuestaEncuesta(respuesta)" [(ngModel)]="respuesta.seleccionada"></mat-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
                <div class="text-center">
                    <button style="background-color:#efefef !important; margin: 5px; border: grey solid 1px !important; "  type="button" class="btn btn-default" (click)="salirEncuesta()" >Salir</button>
                    <button style="background-color: rgb(0, 54, 113); " type="button" class="btn btn-primary" (click)="finalizarEncuesta()">Evaluar</button>
                </div>
            </mat-card>
        </div>
    </div>
</div>

