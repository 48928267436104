import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rol'
})
export class rolPipe implements PipeTransform {

  transform(value: any,arg:any): any {
    const resultPost=[]
      for(const post of value){
        if (post.rol==null) {
         post.rol='';
        }
        if(post.rol.toLowerCase().indexOf(arg.toLowerCase())>-1){
           resultPost.push(post);
        };
     
      };
      return resultPost;
    
 }

}
