import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informate',
  templateUrl: './informate.component.html',
  styleUrls: ['./informate.component.css']
})
export class InformateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
