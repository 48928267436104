import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


//Service
import {
UsuarioService,
LoginGuardGuard,
RegisterService,
DataListService
} from './service.index'
//Module
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  providers:[
    UsuarioService,
    LoginGuardGuard,
    RegisterService,
    DataListService
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class ServiceModule { }
