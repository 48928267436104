import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { MatDialogRef } from '@angular/material/dialog';
import{NgxSpinnerService}from 'ngx-spinner';
import { encuaesta } from 'src/app/models/paciente.model';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-modalpatologias',
  templateUrl: './modalpatologias.component.html',
  styleUrls: ['./modalpatologias.component.css']
})
export class ModalpatologiasComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: encuaesta,
  public dialogRef: MatDialogRef<ModalpatologiasComponent>,
  private spinnerServi:NgxSpinnerService,
  private _pacienteService: PacienteService,
  private toastr: ToastrService) { }
  contador: number = 0;
  ngOnInit(): void {
    this.spinnerServi.hide();
  }


  enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){
      this._pacienteService.postEncuesta({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.data.idRegistroEncuesta,
        idPregunta: this.data.preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta
      }).subscribe(result=>{
      });
    }else{
      this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe(result=>{
        
      });
    }
  }

  finalizarEncuesta(){
    this._pacienteService.finalizarEncuesta(this.data.idRegistroEncuesta).subscribe(result=>{
      this.toastr.success("Diagnóstico guardado");
      this.dialogRef.close();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }


}
