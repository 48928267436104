
<div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12 ">
        <div class="container ">
            <mat-card class="text-center" *ngIf="cargando">
                <img class="rounded mx-auto d-block col-xs-12 img-cargando-menu" src="../../../assets/image/loading/load1.gif" alt="">
            </mat-card>
            <div *ngIf="!cargando" class="row contenedor-menu">
                <div class="row contenedor-menu">
                    <div *ngFor="let noticia of noticias">
                        <a href="{{noticia.urlNoticia}}" target="_blank" rel="noopener noreferrer">
                            <div class="card card-menu">
                                <div style="padding: 3px; margin: 0px;" class="card-body text-center">
                                    <i class="fas fa-exclamation-circle icon-menu"></i>
                                    <p class="">{{noticia.titulo}}</p>
                                    <p style="font-weight: normal !important; text-align: justify !important;" class="card-text">{{noticia.descripcion}}</p>

                                </div>
                            </div>
                        </a>
                        
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<br><br><br>