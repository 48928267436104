import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegisterService, DataListService, UsuarioService } from 'src/app/services/service.index';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { PatologiabasemodalComponent } from 'src/app/components/patient/patologiabasemodal/patologiabasemodal.component';
import { MatDialog } from '@angular/material/dialog';
import { encuaesta } from 'src/app/models/paciente.model';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  //Declaration of varibles
  formPerfil: FormGroup
  public nacionalidades: any;
  public documentos: any;
  public estadoCiviles: any;
  public escolaridades: any;
  public departamentos: any;
  public municipios: any = [];
  public categoriaProfesion: any;
  public generos: any;
  public nivelesEducativos: any;
  public categoriasDirecciones: any;
  public tiposVivienda: any;
  public cargando: boolean = true;
  public perfilUsuario: any;
  public perfilUsuarioCopia: any;
  public datosActualizar: any;
  public contador: any = 0;
  public cargandoPerfil: boolean = false;
  public teclaAnterior = "";
  public inputPress = "";
  public localidad = {
    title: "Aldea",
    message: "Ingrese una aldea"
  };
  public copiaFormPerfil: any;
  get documento() { return this.formPerfil.get('documento'); }
  get nombres() { return this.formPerfil.get('nombres'); }
  get apellidos() { return this.formPerfil.get('apellidos'); }
  get profesion() { return this.formPerfil.get('profesion'); }
  get personaContacto() { return this.formPerfil.get('personaContacto'); }
  get numeroTelefonoContacto() { return this.formPerfil.get('numeroTelefonoContacto'); }
  get cantidadFamiliares() { return this.formPerfil.get('cantidadFamiliares'); }
  get direccionContacto() { return this.formPerfil.get('direccionContacto'); }
  get direccion() { return this.formPerfil.get('direccion'); }
  get fechaNacimiento() { return this.formPerfil.get('fechaNacimiento'); }
  get numeroTelefono() { return this.formPerfil.get('numeroTelefono'); }
  get correoElectronico() { return this.formPerfil.get('correoElectronico'); }
  get localida() { return this.formPerfil.get('localida'); }

  constructor(
    private toastr: ToastrService,
    private _registerService: RegisterService,
    private _dataLists: DataListService,
    private _pacienteService: PacienteService,
    private matDialog: MatDialog,
    private _userLogin: UsuarioService

  ) { }

  ngOnInit(): void {
    //Form Data
    this._registerService.getDatosPerfil().subscribe(result => {
      this.perfilUsuario = result.data.Table0[0];
      this.copiaFormPerfil = Object.assign({}, this.perfilUsuario);
      this.copiaFormPerfil.fechaNacimiento = this.copiaFormPerfil.fechaNacimiento.substring(0, 10);
      if (this.perfilUsuario.idCategoriaDireccion == "1") {
        this.localidad.title = "Aldea";
        this.localidad.message = "Ingrese una aldea";
      } else if (this.perfilUsuario.idCategoriaDireccion == "2") {
        this.localidad.title = "Caserio";
        this.localidad.message = "Ingrese una caserio";
      } else if (this.perfilUsuario.idCategoriaDireccion == "3") {
        this.localidad.title = "Barrio";
        this.localidad.message = "Ingrese una barrio";
      } else if (this.perfilUsuario.idCategoriaDireccion == "4") {
        this.localidad.title = "Colonia/residencial";
        this.localidad.message = "Ingrese una Colonia/residencial";
      }

      this.perfilUsuario.fechaNacimiento = this.perfilUsuario.fechaNacimiento.substring(0, 10);
      this._dataLists.getMunicipios(this.perfilUsuario.idDepartamento).subscribe(result => {
        this.municipios = result.data.Table0;
        this._dataLists.getNacionalidades().subscribe(data => {
          this.nacionalidades = data.data.Table0;
          this._dataLists.getDepartamentos().subscribe(data => {
            this.departamentos = data.data.Table0;
            this._dataLists.getTiposDocumentos().subscribe(data => {
              this.documentos = data.data.Table0;
              this._dataLists.getTipoVivienda().subscribe(data => {
                this.tiposVivienda = data.data.Table0;
                this._dataLists.getCategoriaDireccion().subscribe(data => {
                  this.categoriasDirecciones = data.data.Table0;
                  this._dataLists.getCategoriaProfesion().subscribe(data => {
                    this.categoriaProfesion = data.data.Table0;
                    this._dataLists.getGeneros().subscribe(data=>{
                    this.generos = data.data.Table0;
                    this._dataLists.getNivelEducativo().subscribe(data=>{
                    this.nivelesEducativos = data.data.Table0;
                    this._dataLists.getEstadosCiviles().subscribe(data=>{
                    this.estadoCiviles = data.data.Table0;
                    this.cargando = false;
                    });
                    });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

    //Form valid
    this.formPerfil = new FormGroup({
      nacionalidad: new FormControl(null, Validators.required),
      tipoDocumento: new FormControl(null, Validators.required),
      documento: new FormControl(null, Validators.required),
      nombres: new FormControl(null, Validators.required),
      apellidos: new FormControl(null, Validators.required),
      fechaNacimiento: new FormControl(null, Validators.required),
      profesion: new FormControl(null, Validators.required),
      idCategoriaProfesion: new FormControl(null, Validators.required),
      estadoCivil: new FormControl(null, Validators.required),
      escolaridad: new FormControl(null, Validators.required),
      idGenero: new FormControl(null, Validators.required),
      departamento: new FormControl(null, Validators.required),
      municipio: new FormControl(null, Validators.required),
      idCategoriaDireccion: new FormControl("1", Validators.required),
      direccion: new FormControl(null, Validators.required),
      numeroTelefono: new FormControl(null, [Validators.required]),
      telefonoFijo: new FormControl(null, [Validators.required]),
      localida: new FormControl(null, Validators.required),
      tipoVivienda: new FormControl(null, Validators.required),
      edificio: new FormControl(null, Validators.required),
      cantHabitaciones: new FormControl(null, Validators.required),
      correoElectronico: new FormControl(null, [Validators.required, Validators.email]),
      personaContacto: new FormControl(null, Validators.required),
      numeroTelefonoContacto: new FormControl(null, Validators.required),
      cantidadFamiliares: new FormControl(0, Validators.required),
      direccionContacto: new FormControl(null, Validators.required),
      contrasena: new FormControl(null, Validators.required),
      confirmarContrasena: new FormControl(null, Validators.required),
    }, { validators: this.validarContraseña('contrasena', 'confirmarContrasena') });
  }
  //functions
  validarContraseña(campo1: string, campo2: string) {
    return (group: FormGroup) => {
      let contrsena = group.controls[campo1].value,
        confirmarContrasena = group.controls[campo2].value;
      if (contrsena === confirmarContrasena) { return { sonIguales: false } }
      return { sonIguales: true }
    }
  }

  cambiarLocalidad() {

    if (this.formPerfil.value.idCategoriaDireccion == "1") {
      this.localidad.title = "Aldea";
      this.localidad.message = "Ingrese una aldea"
    } else if (this.formPerfil.value.idCategoriaDireccion == "2") {
      this.localidad.title = "Caserio";
      this.localidad.message = "Ingrese una caserio"
    } else if (this.formPerfil.value.idCategoriaDireccion == "3") {
      this.localidad.title = "Barrio";
      this.localidad.message = "Ingrese una barrio"
    } else if (this.formPerfil.value.idCategoriaDireccion == "4") {
      this.localidad.title = "Colonia/residencial";
      this.localidad.message = "Ingrese una Colonia/residencial"
    }
  }

  validarNumeros(event) {
    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
    }
  }

  validarLetras(event) {

    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 188 && event.keyCode !== 111 && event.keyCode !== 106 && event.keyCode !== 109 && event.keyCode !== 221 && event.keyCode !== 219 && event.keyCode !== 222 && event.keyCode !== 191 && event.keyCode !== 220 && event.keyCode !== 107 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 186) {
      return true;
    } else {
      return false;
    }
  }
  validarAlfanumerico(event) {
    if (!/[A-Za-z0-9 ,.#]/.test(event.key)) {
      return false;
    } else {
      return true;
    }
  }
  bloquearEnter(event) {
    if (event.keyCode == 13) return false
  }
  dobleEspacio(event, input) {
    if (input != this.inputPress) {
      this.teclaAnterior = "";
      this.inputPress = input;
    } else {
      this.inputPress = input;
    }
    this.teclaAnterior = this.teclaAnterior + " " + event.keyCode;
    var arregloTA = this.teclaAnterior.split(" ");

    if (event.keyCode == 32 && arregloTA[arregloTA.length - 2] == "32") {

      event.preventDefault();
      return false;
    }
  }

  getMunicipios() {
    this._dataLists.getMunicipios(Number(this.formPerfil.value.departamento)).subscribe(data => {
      this.municipios = data.data.Table0;
    });
  }

  getMunicipiosCargar(municipio: number) {
    this._dataLists.getMunicipios(municipio).subscribe(data => {
      this.municipios = data.data.Table0;
    });
  }

  updatePerfil() {
    if (this.formPerfil.value.nacionalidad == null) {
      this.toastr.info('Debe seleccionar su nacionalidad');
      return;
    } else if (this.formPerfil.value.tipoDocumento == null) {
      this.toastr.info('Debe seleccionar el tipo de documento');
      return;
    } else if (this.formPerfil.value.nacionalidad == "114" && this.formPerfil.value.tipoDocumento == "2") {
      this.toastr.info('Si es de nacionalidad Hondureña debe selecciona el tipo de documento la identidad');
      return
    } else if (this.formPerfil.value.nacionalidad != "114" && this.formPerfil.value.tipoDocumento == "1") {
      this.toastr.info('Si no es de nacionalidad Hondureña debe selecciona el tipo de documento el pasaporte');
      return
    } else if (this.formPerfil.value.documento == null || this.formPerfil.value.documento == "") {
      this.toastr.info('Debe ingresar su documentacion');
      return;
    } else if (this.formPerfil.value.nombres == null || this.formPerfil.value.nombres == "") {
      this.toastr.info('Debe ingresar un nombre');
      return;
    } else if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.nombres)) {
      this.toastr.info('No debe ingresar sus nombres con espacio, doble espacio o números');
      return;
    } else if (this.formPerfil.value.apellidos == null || this.formPerfil.value.apellidos == "") {

      this.toastr.info('Debe ingresar un apellido');
      return;
    } else if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.apellidos)) {

      this.toastr.info('No debe ingresar sus apellidos con espacio, doble espacio o números');
      return;
    } else if (this.formPerfil.value.fechaNacimiento == null || this.formPerfil.value.fechaNacimiento == "") {

      this.toastr.info('Debe seleccionar su fecha de nacimiento');
      return;
    } else if (this.formPerfil.value.idCategoriaProfesion == null || this.formPerfil.value.idCategoriaProfesion == "") {

      this.toastr.info('Debe selecciona su ocupación');
      return;
    }  else if (this.formPerfil.value.estadoCivil == null || this.formPerfil.value.estadoCivil == "") {

      this.toastr.info('Debe seleccionar su estado civil');
      return;
    } else if (this.formPerfil.value.escolaridad == null || this.formPerfil.value.escolaridad == "") {

      this.toastr.info('Debe seleccionar su escolaridad');
      return;
    } else if (this.formPerfil.value.idGenero == null || this.formPerfil.value.idGenero == "") {

      this.toastr.info('Debe seleccionar su genero');
      return;
    } else if ((this.formPerfil.value.telefonoFijo != "") && !/^\d{8}$/g.test(this.formPerfil.value.telefonoFijo)) {
      
      this.toastr.info('Su número de teléfono fijo debe contener números y 8 digitos');
      return;
    } else if (this.formPerfil.value.departamento == null || this.formPerfil.value.departamento == "") {
      
      this.toastr.info('Debe seleccionar el departamento');
      return;
    } else if (this.formPerfil.value.municipio == null || this.formPerfil.value.municipio == "") {
      
      this.toastr.info('Debe seleccionar el municipio');
      return;
    } else if (this.formPerfil.value.idCategoriaDireccion == null || this.formPerfil.value.idCategoriaDireccion == "") {
  
      this.toastr.info('Debe seleccionar la localidad');
      return;
    } else if (this.formPerfil.value.localida == null || this.formPerfil.value.localida == "") {
     
      this.toastr.info(this.localidad.message);
      return;
    } else if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.localida)) {
      
      this.toastr.info('No debe ingresar su ' + this.localidad.title + ' con espacio, doble espacio o números');
      return;
    } else if (this.formPerfil.value.tipoVivienda == null || this.formPerfil.value.tipoVivienda == "") {
     
      this.toastr.info('Debe seleccionar el tipo de vivienda');
      return;
    } else if (this.formPerfil.value.tipoVivienda == 3 && (this.formPerfil.value.edificio == null || this.formPerfil.value.edificio == "")) {
     
      this.toastr.info('Debe ingresar el nombre del edificio donde vive');
      return;
    } else if (this.formPerfil.value.tipoVivienda == 3 && !/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.edificio)) {
    
      this.toastr.info('No debe ingresar el nombre del edificio con espacio, doble espacio o números');
      return;
    } else if (this.formPerfil.value.direccion == null || this.formPerfil.value.direccion == "") {
      
      this.toastr.info('Debe ingresar su dirección');
      return;
    } else if (!/^([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#\']+[\s]{0,1})+([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ.,#\'])+([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#\']+[\s]{0,1})+([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ.,#\'])$/g.test(this.formPerfil.value.direccion)) {
      
      this.toastr.info('No debe ingresar su dirección con espacio, doble espacio');
      return;
    } else if (this.formPerfil.value.cantHabitaciones == null || this.formPerfil.value.cantHabitaciones == "") {
      
      this.toastr.info('Debe ingresar la cantidad de habitaciones de su vivienda');
      return;
    } else if (!/^\d{1,2}$/g.test(this.formPerfil.value.cantHabitaciones)) {
      
      this.toastr.info('La cantidad de habitaciones de su vivienda debe contener solo números');
      return;
    } else if (this.formPerfil.value.cantidadFamiliares == null || this.formPerfil.value.cantidadFamiliares == "") {
      
      this.toastr.info('Debe ingresar el número de persona que viven en su casa');
      return;
    } else if (!/^\d{1,2}$/g.test(this.formPerfil.value.cantidadFamiliares)) {
      this.toastr.info('El número de persona que viven en su casa de contener solo números');
      return;
    } else if (this.formPerfil.value.personaContacto == null || this.formPerfil.value.personaContacto == "") {
      
      this.toastr.info('Debe ingresar el nombre de la persona de contacto');
      return;
    } else if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.personaContacto)) {
      
      this.toastr.info('No debe ingresar el nombre de la persona de contacto con espacio, doble espacio o números');
      return;
    } else if (this.formPerfil.value.numeroTelefonoContacto == null || this.formPerfil.value.numeroTelefonoContacto == "") {
      
      this.toastr.info('Debe ingresar el número de teléfono del contacto');
      return;
    } else if (!/^\d{8}$/g.test(this.formPerfil.value.numeroTelefonoContacto)) {
   
      this.toastr.info('El número de teléfono del contacto debe contener solo números y debe tener 8 digitos');
      return;
    } else if (this.formPerfil.value.direccionContacto == null || this.formPerfil.value.direccionContacto == "") {
  
      this.toastr.info('Debe ingresar la direccion de su contacto');
      return;
    } else if (!/^([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#\']+[\s]{0,1})+([A-Za-z0-9ÁÉÍÓÚñáéíóúÑ,.#]{0}?[A-Za-z0-9ÁÉÍÓÚñáéíóúÑ.,#\'])$/g.test(this.formPerfil.value.direccionContacto)) {
      
      this.toastr.info('No debe ingresar la direccion de su contacto con espacio, doble espacio');
      return;

    } else {
     
      this.formarDatos();
    }
  }

  /**else if (this.formPerfil.value.profesion == null || this.formPerfil.value.profesion == "") {

      this.toastr.info('Debe ingresar su Profesión/oficio');
      return;
    } else if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formPerfil.value.profesion)) {

      this.toastr.info('No debe ingresar su Profesión/oficio con espacio, doble espacio o números');
      return;
    }
     */

  formarDatos() {
    this.datosActualizar = [
      
      {
        campo: "nombres",
        nuevoValor: this.formPerfil.value.nombres
      },
      {
        campo: "apellidos",
        nuevoValor: this.formPerfil.value.apellidos
      },
      {
        campo: "fechaNacimiento",
        nuevoValor: this.formPerfil.value.fechaNacimiento
      },
      {
        campo: "idEstadoCivil",
        nuevoValor: this.formPerfil.value.estadoCivil
      },
      {
        campo: "idNivelEducativo",
        nuevoValor: this.formPerfil.value.escolaridad
      },
      {
        campo: "idMunicipio",
        nuevoValor: this.formPerfil.value.municipio
      },
      {
        campo: "direccion",
        nuevoValor: this.formPerfil.value.direccion
      },

      {
        campo: "contactoEmergencia",
        nuevoValor: this.formPerfil.value.personaContacto
      },
      {
        campo: "telefonoContactoEmergencia",
        nuevoValor: this.formPerfil.value.numeroTelefonoContacto
      },
      {
        campo: "cantidadPersonas",
        nuevoValor: this.formPerfil.value.cantidadFamiliares
      },
      {
        campo: "direccionContacto",
        nuevoValor: this.formPerfil.value.direccionContacto
      },
      {
        campo: "idTipoVivienda",
        nuevoValor: this.formPerfil.value.tipoVivienda
      },
      {
        campo: "edificio",
        nuevoValor: this.formPerfil.value.edificio
      },
      {
        campo: "detalleCategoriaDireccion",
        nuevoValor: this.formPerfil.value.localida
      },
      {
        campo: "idCategoriaDireccion",
        nuevoValor: this.formPerfil.value.idCategoriaDireccion
      },
      {
        campo: "cantidadHabitaciones",
        nuevoValor: this.formPerfil.value.cantHabitaciones
      },
      {
        campo: "telefonoFijo",
        nuevoValor: this.formPerfil.value.telefonoFijo
      },
      {
        campo: "idCategoriaProfesion",
        nuevoValor: this.formPerfil.value.idCategoriaProfesion
      },
      {
        campo: "idSexo",
        nuevoValor: this.formPerfil.value.idGenero
      }
    ]
    this.actualizarPerfil();
  }


  actualizarPerfil() {
    this.cargandoPerfil = true;
    if (this.copiaFormPerfil[this.datosActualizar[this.contador].campo] != this.datosActualizar[this.contador].nuevoValor) {
      this._registerService.actualizarPerfil(this.datosActualizar[this.contador]).subscribe(result => {
        if (this.contador < this.datosActualizar.length - 1) {
          this.copiaFormPerfil[this.datosActualizar[this.contador].campo] = this.datosActualizar[this.contador].nuevoValor;
          this.contador += 1;
          this.actualizarPerfil();
        } else {
          this.toastr.success("Perfil actualizado exitosamente", "Perfil de Usuario");
          this.cargandoPerfil = false;
          this.contador = 0;
        }
      });
    } else {
      if (this.contador < this.datosActualizar.length - 1) {
        this.contador += 1;
        this.actualizarPerfil();
      } else {
        this.toastr.success("Perfil actualizado exitosamente", "Perfil de Usuario");
        this.cargandoPerfil = false;
        this.contador = 0;
      }
    }
  }


  openEncuestaRapida() {
    this._pacienteService.getEncuesta(3).subscribe(result => {
      this.openModalPatologica(result.data.Table0[0].encuesta[0]);
    });
  }

  openModalPatologica(encuesta: encuaesta) {

    //modalOpen
    const dialogRef = this.matDialog.open(PatologiabasemodalComponent, {
      disableClose: true,
      autoFocus: true,
      height: '600px',
      width: '500px',
      data: encuesta
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userLogin.usuario.activoEvaluar = true;
      }
    });
  }


}
