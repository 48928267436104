<nav class="navbar fixed-top top-navbar navbar-expand-md navbar-light nav-back">
    <br>
</nav>
<br><br><br><br>
<div class="container form-register">
    <div class="row justify-content-md-center align-items-center">
        <div class="col-12">

            <div class="card">

                <div class="card-body form-card">

                    <div *ngIf="cargandoFormulario" class="row contenedor-menu-register">

                        <i style="font-size: 90px;" class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <form *ngIf="!cargandoFormulario" class="form-horizotal form-material" autocomplete="off">
                        <div class="row ">
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140"
                                    alt="logo_unah">
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4">
                                <h1 class="text-center">Recuperar contraseña</h1>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                <div class="">
                                    <img class="" src="../../../assets/image/logo/logo_degt.png" width="210"
                                        alt="logo_degt">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="col-xs-12 col-md-12 col-lg-12 text-center">
                            <div class="alert alert-info" role="alert">
                                <p>{{mensaje}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <a class="btn btn-primary float-left" style="background-color: rgb(0, 54, 113);"
                                [routerLink]="['/login']"><i class="fas fa-chevron-left"></i> Iniciar sesión</a>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="cargandoFormulario">
                <br><br><br><br><br><br><br><br><br><br><br><br><br>
            </div>
        </div>
    </div>


</div>