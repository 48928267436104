import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cambiarcontrasena',
  templateUrl: './cambiarcontrasena.component.html',
  styleUrls: ['./cambiarcontrasena.component.css']
})
export class CambiarcontrasenaComponent implements OnInit {

  formCambioContrasena: FormGroup
  public cargando: boolean = false;
  public showpass: boolean = false;
  public showpassConfirmar: boolean = false;
  public showpassActual: boolean = false;
  public datoConfirmarContrasena = null;
  get contrasenaActual(){return this.formCambioContrasena.get('contrasenaActual');}
  get nuevaContrasena(){return this.formCambioContrasena.get('nuevaContrasena');}
  get confirmarnuevaContrasena(){return this.formCambioContrasena.get('confirmarnuevaContrasena');}
  @ViewChild('confirmarnuevaCont') confirmarContrasenaInput: ElementRef;

  constructor(
    private _RegisterService: RegisterService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formCambioContrasena =  new FormGroup({
      contrasenaActual: new FormControl(null, Validators.required),
      nuevaContrasena: new FormControl(null,Validators.required),
      confirmarnuevaContrasena: new FormControl(null, Validators.required)
    },{validators: this.validarContrasena('nuevaContrasena','confirmarnuevaContrasena')});
  }
  showpassword() {
    this.showpass = !this.showpass;
    if(this.showpass){
      this.formCambioContrasena.value.confirmarnuevaContrasena = this.formCambioContrasena.value.nuevaContrasena;
      this.datoConfirmarContrasena = this.formCambioContrasena.value.nuevaContrasena;
    }else{
      
     this.formCambioContrasena.value.confirmarnuevaContrasena = null;
     this.datoConfirmarContrasena = null;
    
    }
  }
  showpasswordActual() {
    this.showpassActual = !this.showpassActual;
  }
  showpasswordConfirmar() {
    this.showpassConfirmar = !this.showpassConfirmar;
  }
  copypassword(){
    if(this.showpass){
      this.formCambioContrasena.value.confirmarnuevaContrasena = this.formCambioContrasena.value.nuevaContrasena;
      this.datoConfirmarContrasena = this.formCambioContrasena.value.nuevaContrasena;
    }
  }
  validarContrasena(campo1: string, campo2:string){

    return( group: FormGroup ) =>{

      let contrasena = group.controls[campo1].value;
      let confirmarContrasena = group.controls[campo2].value;

      if(contrasena === confirmarContrasena){
        return null;
      } 

      return{
        sonIguales: true
      }
    }
  }

  combioContrasena(){

    if(this.formCambioContrasena.value.contrasenaActual ==  null || this.formCambioContrasena.value.contrasenaActual == ""){
      this.toastr.info("Debe ingresar su contraseña actual","Cambio contraseña");
      return;
    }else if(this.formCambioContrasena.value.nuevaContrasena ==  null || this.formCambioContrasena.value.nuevaContrasena == ""){
      this.toastr.info("Debe ingresar su nueva contraseña","Cambio contraseña");
      return;
    }else if((this.formCambioContrasena.value.confirmarnuevaContrasena ==  null || this.formCambioContrasena.value.confirmarnuevaContrasena == "") && !this.showpass){
      this.toastr.info("Debe confirmar su nueva contraseña","Cambio contraseña");
      return;
    }else if(!/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040-\u002E-\u005F])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/.test(this.formCambioContrasena.value.nuevaContrasena)){
      this.toastr.info("La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico.","Cambio contraseña");
      return;
    }else if((this.formCambioContrasena.value.confirmarnuevaContrasena !== this.formCambioContrasena.value.nuevaContrasena) && !this.showpass){
      return;
    }else{
      this.cargando = true;
      this._RegisterService.cambioContrasena({
        clave:  this._RegisterService.encryptPassword(this.formCambioContrasena.value.contrasenaActual),
        claveNueva: this._RegisterService.encryptPassword(this.formCambioContrasena.value.nuevaContrasena)
      }).subscribe(result=>{
        if(result.hasError){
          this.toastr.info(result.errors[0].descripcion);
          this.cargando=false;
        }else{
          this.toastr.success("Cambio de contraseña realizado exitosamente","Cambio contraseña");
          this.cargando = false;
          this.router.navigate(['/default']);
        }
        
      });
    }

    
  }

}
