import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reiniciocontrasena',
  templateUrl: './reiniciocontrasena.component.html',
  styleUrls: ['./reiniciocontrasena.component.css']
})
export class ReiniciocontrasenaComponent implements OnInit {

  formReinicio: FormGroup;
  get correoElectronico(){ return this.formReinicio.get('correoElectronico');}
  public cargando: boolean = false;
  public bloqueo: boolean = false;
  public mensaje: boolean = false;
  public respuesta = "";

  constructor(
    private _register:RegisterService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.formReinicio = new FormGroup({
      correoElectronico: new FormControl(null, [Validators.required,Validators.email])
    });
  }

  validarCorreo(){
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.formReinicio.value.correoElectronico)){
      alert("La dirección de email " + this.formReinicio.value.correoElectronico + " es correcta.");
     } else {
      alert("La dirección de email es incorrecta.");
     }
  }

  reiniciar(){
    this.cargando = false;
    this.bloqueo = false;
    this.mensaje = false;
    this.respuesta = "";

  }

  reinicioContrasena(){
    if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.formReinicio.value.correoElectronico)){
      this.toastr.info("Debe ingresar un correo eléctrico válido.","Reinicio de correo");
    } else{
      this.bloqueo = true;
      this.cargando = true;
      
      this._register.reiniciarContrasena(this.formReinicio.value.correoElectronico).subscribe(result=>{
        if(!result.hasError){
          this.respuesta = result.data.Table0[0].descripcion;
        }else{
          if(result.errors[0]){
          this.respuesta = result.errors[0].descripcion;
          }
          
        }
        this.mensaje = true;
        this.cargando = false;
      }, err =>{
          if(err.error.hasError && err.error.errors){
            this.respuesta = err.error.errors[0].descripcion;
          }
        this.mensaje = true;
        this.cargando = false;
      });
      
    }
    
  }

}
