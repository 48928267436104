<br><br>
<mat-dialog-content>
    <div class="contenedor1">
        <table class="table  table-sm">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Patologías</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let respuesta of data.preguntas[0].respuestas">
                    <th scope="row">{{respuesta.respuesta}}</th>
                    <td>
                        <mat-checkbox (click)="enviarRespuestaEncuesta(respuesta)" [(ngModel)]="respuesta.seleccionada"></mat-checkbox>
                    </td>
                </tr>

            </tbody>
        </table>

    </div>
</mat-dialog-content>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Salir</button>
    <button type="button" class="btn btn-primary" (click)="finalizarEncuesta()">Enviar</button>
</div>