import { Component, OnInit } from '@angular/core';
import { FormControl,FormGroup, Validators } from "@angular/forms";
import { DoctorService } from '../../services/doctor/doctor.service';
import { ToastrService } from 'ngx-toastr';
import{NgxSpinnerService}from 'ngx-spinner';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.css']
})
export class AddDoctorComponent implements OnInit {
  pageActual: number = 1;
  identidad='';
  filterPost = '';
  departamentos = '';
  apellidos='';
  municipios='';
  telefonoMovil='';
  correoe='';
 pipes='';
 rol='';
 profesion='';

  constructor(private doctorService:DoctorService,
    private toastr:ToastrService,
    private spinnerServi:NgxSpinnerService,) {
    this.apellidoForm=this.createFormGroup(); 
   }

  createFormGroup(){
    return new FormGroup({
       apellido :new FormControl('',Validators.required),
    });
  }

apellidoForm:FormGroup;
users:any;
dataSi :boolean;
buscarPersona(){
  this.spinnerServi.show();
 if(this.apellidoForm.valid)
 {
  this.doctorService.buscarPersonas(this.apellidoForm.value).subscribe(
    (data:any) => {
      if (data.data.Table0=='') {
        this.dataSi=false;
        this.toastr.error('No se Encontraron registros');
        this.spinnerServi.hide();
      } else {
        this.dataSi=true;
        this.users=data.data.Table0;
        this.spinnerServi.hide();
      }
    }
  );
 }else{
  this.dataSi=false;
  this.toastr.error('Ingrese el apellido');
  this.spinnerServi.hide();
 }

}


agregarDoctor(user){
  user.idRol1=2;
  this.doctorService.editarRol(user).subscribe(
    (data:any)=>{
      if (data.hasError==true) {
        this.toastr.error(data.errors[0].descripcion);
      } else {
        this.toastr.success('Realizado correctamente');
        this.buscarPersona();
      }
     
    } 
  );
}
agregarEnfermera(user){
  user.idRol1=4;
  this.doctorService.editarRol(user).subscribe(
    (data:any)=>{
      if (data.hasError==true) {
        this.toastr.error(data.errors[0].descripcion);
      } else {
        this.toastr.success('Realizado correctamente');
        this.buscarPersona();
      }
     
    } 
  );
}
agregarAdmin(user){
  user.idRol1=1;
  this.doctorService.editarRol(user).subscribe(
    (data:any)=>{
      if (data.hasError==true) {
        this.toastr.error(data.errors[0].descripcion);
      } else {
        this.toastr.success('Realizado correctamente');
        this.buscarPersona();
      }
     
    } 
  );
}
agregarEnfermeroDoctor(user){
  user.idRol1=5;
  this.doctorService.editarRol(user).subscribe(
    (data:any)=>{
      if (data.hasError==true) {
        this.toastr.error(data.errors[0].descripcion);
      } else {
        this.toastr.success('Realizado correctamente');
        this.buscarPersona();
      }
     
    } 
  );
}
backPaciente(user){
  user.idRol1=3;
  this.doctorService.editarRol(user).subscribe(
    (data:any)=>{
      if (data.hasError==true) {
        this.toastr.error(data.errors[0].descripcion);
      } else {
        this.toastr.success('Realizado correctamente');
        this.buscarPersona();
      }
     
    } 
  );
}

  ngOnInit(): void {
    this.spinnerServi.hide();
    this.dataSi=false;
  }

}
