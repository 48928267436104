
<mat-dialog-content>
    <h2  class="text-center">Diagnóstico rápido </h2>
    <hr>
    <h4>Debe enviar su diagnistico patologico para realizar un Diagnóstico rápido.</h4>
    <br>

    <h4 class="text-center">¿Desea enviar su Diagnóstico Patologico?</h4>

</mat-dialog-content>
<div  class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">No</button>
    <button type="button" class="btn btn-primary" (click)="openEncuestaPatologica()">Si</button>
</div>
