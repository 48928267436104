<div class="row">
    <div class="col-12">
        <div class="container">
          <mat-card class="text-center" *ngIf="cargando">
            <img class="rounded mx-auto d-block col-xs-12 img-cargando-menu"  src="../../../assets/image/loading/load1.gif" alt="">
          </mat-card>

          <mat-card *ngIf="!cargando">
            <h1 class="text-center">Mis Citas</h1>
       
            <mat-form-field appearance="outline">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Busque una cita">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                
              </mat-form-field>
    
              
                  <div class=" mat-elevation-z8">
                   
                    <table   mat-table [dataSource]="dataSource" matSort>
                     
                      <!-- nombre doctor Column -->
                      <ng-container matColumnDef="nombreDoctor">
                        <mat-header-cell *matHeaderCellDef>Médico</mat-header-cell>
                      
                        <mat-cell *matCellDef="let row"> 
                          <span class="mobile-label">Médico:</span>
                          {{row.nombreDoctor}}
                        </mat-cell>
                      </ng-container>
                  
                      <!-- fecha cita Column -->
                      <ng-container matColumnDef="fechaCita">
                        <mat-header-cell *matHeaderCellDef> Fecha cita </mat-header-cell>
                        <mat-cell *matCellDef="let row"> 
                          <span class="mobile-label">Fecha:</span>
                          {{row.fechaCita}} 
                        </mat-cell>
                      </ng-container>
                      
                  
                      <!-- Name Column -->
                      <ng-container matColumnDef="horaCita">
                        <mat-header-cell *matHeaderCellDef> Hora cita </mat-header-cell>
                        <mat-cell *matCellDef="let row"> 
                          <span class="mobile-label">Hora:</span>
                          {{row.horaCita}} 
                        </mat-cell>
                      </ng-container>
                    
                  
                      <!-- Dictamen Column -->
                      <!--
                      <ng-container matColumnDef="dictamen">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dictamen </th>
                        <td mat-cell *matCellDef="let row" > {{row.dictamen}} </td>
                      </ng-container>
                      -->
                      <!-- Observaciones Column -->
                      <ng-container matColumnDef="observaciones">
                        <mat-header-cell *matHeaderCellDef> Observaciones </mat-header-cell>
                        <mat-cell *matCellDef="let row"> 
                          <span class="mobile-label">Observaciones:</span>
                          {{row.observaciones}} 
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="nombreDictamen">
                        <mat-header-cell *matHeaderCellDef> Conclusión </mat-header-cell>
                        <mat-cell *matCellDef="let row"> 
                          <span class="mobile-label">Conclusión:</span>
                          {{row.concluciones}} 
                        </mat-cell>
                      </ng-container>
                      
                    
                      <!-- Estado cita Column -->
                      <!--                      <ng-container matColumnDef="estadoCita" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado cita </th>
                        <td mat-cell *matCellDef="let row" >{{row.estadoCita}} </td>
                      </ng-container>
                      -->

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </table>
                  
                    <mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>
                  </div>
          </mat-card>

         
              
        </div>
    </div>
</div>