import { Component, OnInit,Inject,Input } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import{NgxSpinnerService}from 'ngx-spinner';
import { UsuarioService } from 'src/app/services/service.index';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { async } from '@angular/core/testing';
import { __await } from 'tslib';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.css']
})
export class HistorialComponent implements OnInit {
  pipes='';
  order: any = 'respuesta';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<HistorialComponent>,
  private spinnerServi:NgxSpinnerService,
  private userService:DoctorService,
  public _usuarioService: UsuarioService) {
    if (this.data!=null) {
      this.spinnerServi.hide();
    }
   }
   fechaEncuesta:string;
   encuesta:any=[];
   todo:any;
  ngOnInit():void{
    this.data.sort();
  for (let index = 0; index < this.data.length; index++) {
      const element = this.data[index];
        this.spinnerServi.show();
        this.data[index].idPersona=this.data.idPersona;
        this.userService
        .getEncuestraHistorial(this.data[index])
        .subscribe(
            (data:any) => {
            this.encuesta.push(data.data.Table0[0].encuesta[0].preguntas[0].respuestas); 
            

            
          }
        );
      
    }

  }
  closeModal() {
    this.dialogRef.close();
  }

}
