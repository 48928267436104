import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { citas } from '../../models/usuario.model';
import { UsuarioService } from '../usuario/usuario.service';
import {UsuarioLogin } from 'src/app/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  public selectCita:citas={
    id :null,
    estadoCita:'',
    fechaCita:null,
    horaCita:null,
    idPersona:'',
    idDoctor :null,
    observaciones:'',
    idUsuario:null,
    dictamen:''
};


constructor(private http: HttpClient,private _UsuarioService: UsuarioService) {
}
  getUsers(user) {
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/registro/mostrar', {"idEstadoSeguimientoPaciente":user.idEstadoSeguimientoPaciente,"idUsuario":usuario.idUsuario,"idPersona": 0});
  } 
  getMisPacientes() {
    let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/pacientes/citas/mostrar', { "idDoctor":usuario.idDoctor,"idUsuario":usuario.idUsuario,"idPersona": 0});
  } 
  getEncuestraMostrar(user)
  { let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/encuesta/detalle/mostrar',{"idPersona":user.idPersona,"idUsuario": usuario.idUsuario,"idCategoria":2});
  }
  getDictamen() {
    // let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post(' https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/cita/dictamen/mostrar', {"id": 0});
  } 
  insertarDictamen(user) {
    return this.http.post(' https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/cita/dictaminar', {"id":user.id,"idDictamenCita":user.idDictamen});
  } 
  buscarPersonas(user)
  { let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post(' https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/general/persona/buscar',{"nombreCampo":"apellidos","valorCampo": user.apellido,"idUsuario":usuario.idUsuario});
  }

  agregarDoctor1(user)
  { let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/doctor/insertar',{"idPersona":user.id,"idUsuario":usuario.idUsuario,"idCategoriaPersonalMedico":user.idCategoriaPersonalMedico});
  }
 
  editarRol(user)
  {
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/usuario/rol/editar',{"id":0,"idPersona":user.id,"idRol":user.idRol1});
  }
  getEncuestraHistorial(user)
  { let usuario: UsuarioLogin = this._UsuarioService.usuario;
    return this.http.post(' https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/encuesta/mostrar',{"idEncuesta":user.id,"idPersona":user.idPersona,"idUsuario": usuario.idUsuario,"idCategoria":2});
  }

  getEncuestaPatologica(user)
  { let usuario: UsuarioLogin = this._UsuarioService.usuario

    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/encuesta/detalle/mostrar',{"idEncuesta":0,"idPersona":user.idPersona,"idUsuario": usuario.idUsuario,"idCategoria":3});
  }
  saveCita(newCita:citas)
  {
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/cita/insertar',newCita); 
  }
  saveCitaAuto(citaAut)
  {
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/cita/insertar',citaAut); 
  }
  updCita(newCita:citas)
  {
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/cita/insertar',newCita); 
  }
  mostrarCita(cita)
  {let usuario: UsuarioLogin = this._UsuarioService.usuario
    
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/cita/mostrar',{"id":0,"idUsuario":usuario.idUsuario,"idPersona":cita.idPersona}); 
  }
  getEstadoCita(){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/citas/estados/mostrar',{"id":0,"idUsuario":usuario.idUsuario}); 
  }
  cambiarEstadoCita(cita){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/cita/cambiarestado',{"id":cita.id,"idEstadoCita":cita.idEstadoCita,"idUsuario":usuario.idUsuario}); 
  }

  getDoctores(){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/doctor/mostrar',{"id":0,"idUsuario":usuario.idUsuario}); 
  }
  getEncuestaHistorial(params){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/paciente/encuesta/mostrar',params); 
  }

  cambiarEstadoPaciente(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/paciente/estado/insertar',{ "id":user.id,"idEstadoSeguimiento":user.deshabilitar,"observacion":user.observacion,"idPersona":user.idPersona,"idUsuario":usuario.idUsuario}); 
  }

  menuRoles(user){
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/seguridad/menu/mostrar',{ "idMenuPadre":user.idMenuPadre}); 
  }


  mostrarTiposVacuna(){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/marcasvacuna/mostrar',{id:0}); 
  }

  insertarVacunaPaciente(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
   let params={"id":user.id,"idPaciente":user.idPaciente,"idMarcaVacuna":user.idVacuna,"numeroDeDosis":user.idDosis,"fechaAplicacion":user.fechaAplicacion,"observacion":user.observaciones,"aplicadaPor":0,"lote":user.lote,"lugar":user.lugar}
     return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/pacientevacuna/insertar',params); 
  }

  mostrarVacunasPaciente(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/pacientevacuna/mostrar',{'id':0,'idPaciente':user.idPaciente}); 
  }
  
  eliminarVacunaPaciente(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/clinica/pacientevacuna/eliminar',{'id':user.id}); 
  }
    
  buscarCenso(user){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/general/persona/buscar',{'valorCampo':user, 'nombreCampo':'identidad'}); 
  }

  datosPersonalesInsertar(params){
    let usuario: UsuarioLogin = this._UsuarioService.usuario
    return this.http.post('https://dqahh48e0d.execute-api.us-east-1.amazonaws.com/dev/registro/datospersonales/insertar',params); 
  }
}
