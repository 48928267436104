import { Routes, RouterModule } from "@angular/router";

//Pages
import { DefaultComponent } from './default/default.component';
import { PagesComponent } from './pages.component';
import { PerfilComponent } from './perfil/perfil.component';
import { InformateComponent } from './informate/informate.component';
//Guard
import { LoginGuardGuard } from '../services/service.index';
import { CitasComponent } from './citas/citas.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { DoctorComponent } from './doctor/doctor.component';
import { DoctorguardGuard } from '../services/guards/doctorguard.guard';
import { CambiarcontrasenaComponent } from './cambiarcontrasena/cambiarcontrasena.component';
import { SintomasComponent } from './sintomas/sintomas.component';
import { AddDoctorComponent } from "../pages/add-doctor/add-doctor.component";
import { MicuentaComponent } from './micuenta/micuenta.component';
import { RegistroVacunasComponent } from './registro-vacunas/registro-vacunas.component';





const pagesRoutes: Routes = [
    {
        path:'',
        component: PagesComponent,
        canActivate: [LoginGuardGuard],
        children:[
            {path:'default', component:DefaultComponent,canActivate: [LoginGuardGuard]},
            {path:'perfil', component:PerfilComponent,canActivate:[LoginGuardGuard]},
            {path:'informate', component:InformateComponent, canActivate:[LoginGuardGuard]},
            {path:'citas',component:CitasComponent, canActivate: [LoginGuardGuard]},
            {path:'noticias',component:NotificacionesComponent, canActivate:[LoginGuardGuard]},
            {path:'doctor',component:DoctorComponent, canActivate:[LoginGuardGuard,DoctorguardGuard]},
            {path:'add-doctor',component:AddDoctorComponent, canActivate:[LoginGuardGuard,DoctorguardGuard]},
            {path:'cambioContrasena',component:CambiarcontrasenaComponent,canActivate:[LoginGuardGuard]},
            {path:'sintomas',component:SintomasComponent,canActivate:[LoginGuardGuard]},
            {path:'micuenta',component:MicuentaComponent,canActivate:[LoginGuardGuard]},
            {path:'registroVacunas',component:RegistroVacunasComponent,canActivate:[LoginGuardGuard,DoctorguardGuard]},
            {path: '', redirectTo:'/login', pathMatch: 'full'}
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes)