import { Component, OnInit,Inject } from '@angular/core';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { PacienteService } from '../../../services/paciente/paciente.service';
import {MAT_DIALOG_DATA} from "@angular/material/dialog"; 
import {MatDialogRef,MatDialog} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import{NgxSpinnerService}from 'ngx-spinner';
import { FormControl,FormGroup, Validators, NgForm } from "@angular/forms";
import { citas } from '../../../models/usuario.model';

@Component({
  selector: 'app-atendido',
  templateUrl: './atendido.component.html',
  styleUrls: ['./atendido.component.css']
})
export class AtendidoComponent implements OnInit {
citas:any;
  constructor(public dialogRef: MatDialogRef<AtendidoComponent>,
    public userService:DoctorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinnerServi:NgxSpinnerService,
    private toastr:ToastrService,private _UsuarioService: UsuarioService,
    private matDialog: MatDialog,
    private _pacienteService: PacienteService,
    public _usuarioService: UsuarioService,) {
      this.atendidoForm=this.createFormGroup();
     }

  ngOnInit(): void {
    this.getEncuesta(this.data);
    this.preUpdateCita(this.data);
    this.spinnerServi.show();
   
      this.getDictamen();
    
    
  }
encuesta:any;

  getEncuesta(user){
    this.spinnerServi.show();
    this.userService
    .getEncuestraMostrar(user)
    .subscribe(
      (data1:any) => { // Success
        this.encuesta = data1.data.Table0[0].encuesta[0]; 
        this.encuesta.idPersona=user.idPersona;
        this.spinnerServi.hide();
      }
    );}
dictamenes:any;
    getDictamen(){
      this.userService
      .getDictamen()
      .subscribe(
        (data2:any) => { // Success
          this.dictamenes=data2.data.Table0;
        }
      );}

  createFormGroup(){
    return new FormGroup({
      estadoCita:new FormControl(''),
      valoracionTelemedicina:new FormControl('',Validators.required),
      resultadosExamenesPrevios:new FormControl(''),
      concluciones:new FormControl('',Validators.required),
      fechaCita:new FormControl(this.data.fechaCita),
      horaCita:new FormControl(this.data.horaCita),
      idPersona:new FormControl(this.data.idPersona),
       idDoctor :new FormControl(this._usuarioService.usuario.idDoctor),
       observaciones:new FormControl(this.data.observaciones),
      idUsuario:new FormControl(''),
      id:new FormControl(this.data.id),
      idDictamen:new FormControl('',Validators.required)
    });
  }
  atendidoForm:FormGroup;
  
  preUpdateCita(citas:citas){
    this.userService.selectCita=Object.assign({},citas)

  }

  saveCita(){
    if(this.atendidoForm.valid)
    {
      this.atendidoForm.value.idUsuario=this._UsuarioService.usuario.idUsuario;
        this.userService.saveCitaAuto(this.atendidoForm.value).subscribe(
          (data:any) => {
           if (data.hasError==true) {
             this.toastr.error(data.errors[0].descripcion);
             this.closeModal();
           } else {
             this.userService.insertarDictamen(this.atendidoForm.value).subscribe(
               (data:any)=>{
                if (data.hasError==true) {
                   this.toastr.error(data.errors[0].descripcion);
                  this.closeModal();
                } else {
                  this.finalizarEncuesta();
                  this.toastr.success('Cita actualizada');
                  this.cambiarEstado(this.data);
                  this.closeModal();
                }
               }
             )
           }
          }
        );
    }
    else{
      this.toastr.info('Debe llenar los campos');
    }
  }



  cambiarEstado(cita){
    if (this.data.noAtendido==1) {
      cita.idEstadoCita=3
    } else {
      cita.idEstadoCita=2
    }
    
   this.userService.cambiarEstadoCita(cita).subscribe(
     (data:any) => {
       if (data.hasError==true) {
         this.toastr.error(data.errors[0].descripcion);
        this.closeModal();
       }else{
      //  this.toastr.success('Cambio de estado Exitoso');
        this.closeModal();
     }
   }

   );
   this.cambiarEstadoPaciente(cita);
 }
 closeModal() {
  this.dialogRef.close();
}



 cambiarEstadoPaciente(cita){
  cita.deshabilitar=3
  cita.id=0;
  this.userService.cambiarEstadoPaciente(cita).subscribe(
    (data:any) => {
     if (data.hasError==true) {
       this.toastr.error(data.errors[0].descripcion);
       this.closeModal();
     } else {
      //  this.toastr.success('Paciente Validado');
       this.closeModal();
     }
    }
  );
}


enviarRespuestaEncuesta(respuesta){
  if (!respuesta.seleccionada){  
    this._pacienteService.postEncuesta({
      idRespuesta:respuesta.idRespuesta,
      idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
      idPregunta: this.encuesta.preguntas[0].idPregunta,
      idOpcionRespuesta:respuesta.idOpcionRespuesta
    }).subscribe(result=>{  
    });
  }else{
    this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe(result=>{    
    });
  }
}

finalizarEncuesta(){
  this._pacienteService.finalizarEncuesta(this.data.idRegistroEncuesta).subscribe(result=>{
      this.toastr.success("Diagnóstico guardado");
    
  });
}
}

