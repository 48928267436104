<header class="toolbar" role="banner">


    <nav class="navbar fixed-top top-navbar navbar-expand-md navbar-light nav-back">

        <div class="navbar-header">
            <img width="55px" alt="logo unah" src="../../../assets/image/logo/logo_mesa_doctor_1847.png" />

        </div>
        <button type="button" class="navbar-toggler float-right my-2" data-toggle="collapse" data-target=".navbar-collapse">
            <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
        </button>
        <div class="navbar-collapse collapse ">
            <ul class="navbar-nav mr-auto ">
               
                <li *ngFor="let menu of menus" class="nav-item "><a data-toggle="collapse" data-target=".navbar-collapse" (click)="navegacion(menu.urlEstado)" class="nav-item-op">{{menu.menu}}</a></li>
            </ul>

            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown text-center">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark " href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/image/avatars/user.png" alt="user" class="profile-pic" width="40px" /></a>
                    <div class="dropdown-menu dropdown-menu-right text-center">
                        <div class="dropdown-user text-center small">
                            <a (click)="navegacion('/micuenta')" class="nav-link" style="color: black !important; margin: 5px; cursor: pointer;">Mi cuenta</a>
                            <hr>
                            <a (click)="navegacion('/cambioContrasena')" class="nav-link" style="color: black !important; margin: 5px; cursor: pointer;">Cambio contraseña</a>
                            <hr>
                            <a (click)="logout()" class="nav-link" style="color: black !important; margin: 5px; cursor: pointer;">Cerrar sesión</a>
                      
                        </div>
                    </div>
                </li>
            </ul>
        </div>



    </nav>

</header>

<br><br>