import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { encuaesta } from 'src/app/models/paciente.model';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PatologiabasemodalComponent } from '../patologiabasemodal/patologiabasemodal.component';
import { PacienteService } from 'src/app/services/paciente/paciente.service';

@Component({
  selector: 'app-patientmodal',
  templateUrl: './patientmodal.component.html',
  styleUrls: ['./patientmodal.component.css']
})
export class PatientmodalComponent implements OnInit {

  

  constructor(
    private dialogRef: MatDialogRef<PatientmodalComponent>,
    private matDialog: MatDialog,
    private _pacienteService: PacienteService
  ) { }

  ngOnInit(): void {
  }

  openEncuestaPatologica(){
    this._pacienteService.getEncuesta(3).subscribe(result=>{
      this.openModalPatologica(result.data[0]);
    });
    this.dialogRef.close();
  }

  
  
  openModalPatologica(encuesta: encuaesta){
    //Modal config
    const dialogConfig =  new MatDialogConfig()
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    //modalOpen
    const dialogRef = this.matDialog.open(PatologiabasemodalComponent,{
      disableClose: true,
      autoFocus:true,
      width: "40%",
      data: encuesta
    });

    dialogRef.afterClosed().subscribe(result=>{

    });
  }



  closeModal() {
    this.dialogRef.close();
  }
}
