import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/app/services/register/register.service';
import { UsuarioRegister } from 'src/app/models/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  formRegister : FormGroup; 
  public nacionalidades: any;
  public nacionalidad: number = 144;
  public documentos: any;
  public cargandoRegistro: boolean = false;
  public cargandoFormulario: boolean = true;
  public showpass: boolean = false;
  public showpassConfirmar: boolean = false;
  public datoConfirmarContrasena = null;
  public mensIdentificacion = "Ingrese la identificación sin guiones"
  get documento(){return this.formRegister.get('documento');}
  get nombres(){ return this.formRegister.get('nombres');}
  get apellidos(){return this.formRegister.get('apellidos');}
  get fechaNacimiento(){ return this.formRegister.get('fechaNacimiento');}
  get numeroTelefono(){ return this.formRegister.get('numeroTelefono');}
  get correoElectronico(){ return this.formRegister.get('correoElectronico');}
  
  constructor(
    private toastr: ToastrService,
    private _regiterService: RegisterService,
    private router: Router
    ) { 

    }

  ngOnInit(): void {
    //Datos del formulario
    this.obtenerDatosFormulario();
    //Form valid
    this.formRegister = new FormGroup({
      nacionalidad: new FormControl("114",Validators.required),
      tipoDocumento: new FormControl("1",Validators.required),
      documento: new FormControl(null,Validators.required),
      nombres: new FormControl(null, Validators.required),
      apellidos: new FormControl(null,Validators.required),
      fechaNacimiento: new FormControl(null,Validators.required),
      numeroTelefono: new FormControl(null, [Validators.required]),
      correoElectronico: new FormControl(null, [Validators.required,Validators.email]),
      contrasena: new FormControl(null, Validators.required),
      confirmarContrasena: new FormControl(null, Validators.required)
    },{validators: this.validarContrasena('contrasena','confirmarContrasena')});
  }
  /////Funciones 
  obtenerDatosFormulario(){
    this._regiterService.obtenerNacionalidades().subscribe(data =>{
      this.nacionalidades = data.data.Table0;
      this._regiterService.obtenerTipoDocumento().subscribe(data=>{
        this.documentos = data.data.Table0;
        this.cargandoFormulario = false;
      });
    });
  }
  showpassword() {
    this.showpass = !this.showpass;
    if(this.showpass){
      this.formRegister.value.confirmarContrasena = this.formRegister.value.contrasena;
      this.datoConfirmarContrasena = this.formRegister.value.contrasena;
    }else{
      this.formRegister.value.confirmarContrasena = null;
      this.datoConfirmarContrasena = null;
    }
    
  }
  showpasswordConfirmar() {
    this.showpassConfirmar = !this.showpassConfirmar;
    
  }
  copypassword(){
    if(this.showpass){
      this.formRegister.value.confirmarContrasena = this.formRegister.value.contrasena;
      this.datoConfirmarContrasena = this.formRegister.value.contrasena;
    }
  }

  validarNumeroYLetras(e){
    if(e.key.match(/[a-z0-9ñçáéíóú\s-]/i)===null) {
			e.preventDefault();
		}
  }

  mensajeIdentificacion(){
    if(this.formRegister.value.tipoDocumento == 1){
      this.mensIdentificacion = "Ingrese la identificación sin guiones";
    }else{
      this.mensIdentificacion = "Ingrese la identificación";
    }
  }
 
  validarContrasena(campo1: string, campo2:string){

    return( group: FormGroup ) =>{

      let contrasena = group.controls[campo1].value;
      let confirmarContrasena = group.controls[campo2].value;

      if(contrasena === confirmarContrasena){
        return null;
      } 

      return{
        sonIguales: true
      }
    }
  }
  validarNumeros(event){ 
    if((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !==190  && event.keyCode !==110 && event.keyCode !==8 && event.keyCode !==9  ){
      return false;
    }
    
  }

  validarLetras(event){
    if((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !==190  && event.keyCode !==110  && event.keyCode !==188 && event.keyCode !==111 && event.keyCode !== 106 && event.keyCode !== 109  && event.keyCode !== 221 && event.keyCode !== 219 && event.keyCode !== 222  && event.keyCode !== 191 && event.keyCode !== 220  && event.keyCode !== 107 && event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 186){
      return true;
    }else{
      return false;
    }
  }

  registerUser(){
    if(this.formRegister.value.nacionalidad == null || this.formRegister.value.nacionalidad == "0"){
      this.toastr.info('Debe seleccionar su nacionalidad');
      return;
    } else if(this.formRegister.value.tipoDocumento == null || this.formRegister.value.tipoDocumento == "0"){
      this.toastr.info('Debe seleccionar el tipo de identificación');
      return;
    }else if(this.formRegister.value.nacionalidad == "114"  && this.formRegister.value.tipoDocumento == "2"){
      this.toastr.info('Si es de nacionalidad Hondureña debe selecciona como tipo de documento la identidad');
      return
    }else if(this.formRegister.value.nacionalidad != "114"  && this.formRegister.value.tipoDocumento == "1"){
      this.toastr.info('Si no es de nacionalidad hondureña debe seleccionar como tipo de documento el pasaporte');
      return
    } else if(this.formRegister.value.documento == null || this.formRegister.value.documento == ""){
      this.toastr.info('Debe ingresar su número de identificación o pasaporte');
      return;
    } else if(this.formRegister.value.tipoDocumento == "1" && this.formRegister.value.documento.length < 13){
      this.toastr.info('Su número de identidad debe tener 13 digitos');
      return;
    } else if(this.formRegister.value.tipoDocumento == "1" && this.formRegister.value.documento.length > 13){
      this.toastr.info('Su número de identidad debe tener 13 digitos');
      return;
    } else if(this.formRegister.value.tipoDocumento == "1" && !Number.isInteger(Number(this.formRegister.value.documento))){
      this.toastr.info('Su número de identidad solo debe contener números');
      return;
    } else if(this.formRegister.value.nombres == null || this.formRegister.value.nombres == ""){
      this.toastr.info('Debe ingresar un nombre');
      return;
    } else if(!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formRegister.value.nombres)){
      this.toastr.info('No debe ingresar sus nombres con espacio, doble espacio o números');
      return;
    }else if(this.formRegister.value.apellidos == null || this.formRegister.value.apellidos == ""){
      this.toastr.info('Debe ingresar un apellido');
      return;
    } else if(!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]{0,1})+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])$/g.test(this.formRegister.value.apellidos)){
      this.toastr.info('No debe ingresar sus apellidos con espacio, doble espacio o números');
      return;
    }else if(this.formRegister.value.fechaNacimiento == null || this.formRegister.value.fechaNacimiento == ""){
      this.toastr.info('Debe seleccionar su fecha de nacimiento');
      return;
    }else if(this.formRegister.value.numeroTelefono == null || this.formRegister.value.numeroTelefono == ""){
      this.toastr.info('Debe ingresar su número de teléfono celular');
      return;
    
    }else if(this.formRegister.value.numeroTelefono.length < 8){
      this.toastr.info('Su número de teléfono debe de ser de 8 dígitos');
      return;
    }else if(!/^\d{8}$/g.test(this.formRegister.value.numeroTelefono)){
      this.toastr.info('Su número de teléfono solo debe contener números');
      return;
    }else if(!/^(7|8|9|3)[1-9][0-9]*$/g.test(this.formRegister.value.numeroTelefono)){
      this.toastr.info('Tu número de teléfono debe ser de un operador de telefonía móvil nacional');
      return;
    }else if (this.formRegister.value.correoElectronico == null || this.formRegister.value.correoElectronico == ""){
      this.toastr.info('Debe ingresar su correo');
      return;
    }else if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.formRegister.value.correoElectronico)){
      this.toastr.info("Debe ingresar un correo eléctrico válido.");
      return;
    }else if((this.formRegister.value.contrasena == null|| this.formRegister.value.contrasena == "") ){
      this.toastr.info('Debe ingresar la contraseña');
      return;
    }else if((this.formRegister.value.confirmarContrasena == null|| this.formRegister.value.confirmarContrasena == "") && !this.showpass ){
      this.toastr.info('Debe confirmar la contraseña',this.formRegister.value.confirmarContrasena);
      return;
    }else if((this.formRegister.value.contrasena !== this.formRegister.value.confirmarContrasena) && !this.showpass){;
      return;
    }else if(!/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040-\u002E-\u005F])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/.test(this.formRegister.value.contrasena)){
      this.toastr.info("La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico().");
      return;
    }else{
      let usuario = new UsuarioRegister(
        0,
        this.formRegister.value.nombres,
        this.formRegister.value.apellidos,
        this.formRegister.value.documento,
        Number(this.formRegister.value.tipoDocumento),
        this.formRegister.value.fechaNacimiento,
        Number(this.formRegister.value.nacionalidad),
        this.formRegister.value.numeroTelefono,
        this.formRegister.value.correoElectronico,
        this._regiterService.encryptPassword(this.formRegister.value.contrasena)
        )
        this.cargandoRegistro = true;
        this._regiterService.verificarNumeroTelefono(this.formRegister.value.numeroTelefono,'telefonoMovil').subscribe(result=>{
          
        if(result.hasError){
            this.toastr.info("El numero de telefono ya esta registrado con otro usuario","Número de telefono");
            this.cargandoRegistro = false;
        }else {
          this._regiterService.verificarNumeroTelefono(this.formRegister.value.documento,'identidad').subscribe(result=>{
            if(result.hasError){
              this.toastr.info("El numero del documento ya esta registrado con otro usuario","Documento");
              this.cargandoRegistro = false;
            }else{
              this._regiterService.verificarNumeroTelefono(this.formRegister.value.correoElectronico,'correoElectronico').subscribe(result=>{
                 if(result.hasError){
                  this.toastr.info("El correo ya esta registrado con otro usuario","Correo");
                  this.cargandoRegistro = false;
                 }else{
                    
                  this._regiterService.crearUsuario(usuario).subscribe(data =>{
                    this.cargandoRegistro = false;
                    this.toastr.success('Usuario creado exitosamente');
                    setTimeout(()=>{
                    this.router.navigate(['/login']);
                    },1000)
                  });
                  
                 }
              });
            }
          });
        }
    
          
    
        });



      
    
    }
    

    
    
   
  }

}
