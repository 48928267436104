import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encuaesta } from 'src/app/models/paciente.model';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService, UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-patologiabasemodal',
  templateUrl: './patologiabasemodal.component.html',
  styleUrls: ['./patologiabasemodal.component.css']
})
export class PatologiabasemodalComponent implements OnInit {



  constructor(
    public dialogRef: MatDialogRef<PatologiabasemodalComponent>,
    @Inject(MAT_DIALOG_DATA) public encuesta: encuaesta,
    private _pacienteService: PacienteService,
    private toastr: ToastrService,
    private _register: RegisterService,
    private _usuario:UsuarioService
  ) { 
    
  }

  ngOnInit(): void {
  }

  enviarRespuestaEncuesta(respuesta){
    if (!respuesta.seleccionada){
      this._pacienteService.postEncuesta({
        idRespuesta:respuesta.idRespuesta,
        idRegistroEncuesta:this.encuesta.idRegistroEncuesta,
        idPregunta: this.encuesta.preguntas[0].idPregunta,
        idOpcionRespuesta:respuesta.idOpcionRespuesta
      }).subscribe(result=>{
        respuesta.idRespuesta = result.data.Table0[0].idRespuesta || 0;
      });
    }else{
      this._pacienteService.eliminarRespuesta(respuesta.idRespuesta).subscribe(result=>{
        
      });
    }


    
  }

  finalizarEncuesta(){
    this._pacienteService.finalizarEncuesta(this.encuesta.idRegistroEncuesta).subscribe(result=>{
      this._register.actualizarPerfil({
        campo:"activoEvaluar",
        nuevoValor: true
      }).subscribe(result=>{
        this.toastr.success("Gracias por enviar tus condiciones patológicas ");
        this._usuario.usuario.activoEvaluar = true;
        localStorage.setItem('usuario',JSON.stringify(this._usuario.usuario));
        this.dialogRef.close(true);
      });
    });
  }


  closeModal() {
    this.dialogRef.close(false);
  }

}
