<div class="row">
    <div class="col-12">
        <div class="container">
            <mat-card>
                <div class="container form-register">
                    <div class="row justify-content-md-center align-items-center">
                        <div class="col-12">
                            
                            <div class="">
                                
                                <div class="card-body form-card">
                                    <form class="form-horizotal form-material" [formGroup]="formCambioContrasena" (ngSubmit)="combioContrasena()" autocomplete="off">
                                        <div class="row ">
                                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                                <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140" alt="logo_unah">
                                            </div>
                                            <div class="col-xs-4 col-md-4 col-lg-4">
                                                <h1 class="text-center">Cambio de contraseña</h1>
                                            </div>
                                            <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                                                <div class="">
                                                    <img class="" src="../../../assets/image/logo/logo_degt.png" width="210" alt="logo_degt">
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        
                                        <div class="row">
                                            <div class="col-xs-12 col-md-12 col-lg-12">
                                                <div class="form-group" [ngClass]="{'text-danger': contrasenaActual.dirty && contrasenaActual.invalid }">
                                                    <label for="contrasenaActual">Contraseña actual</label>
                                                    <div class="input-group input-group-sm mb-3">
                                                        <input maxlength="50" formControlName="contrasenaActual" [type]="showpassActual ? 'text' : 'password'" class="form-control form-control-sm" name="contrasenaActual" id="idcontrasenaActual" aria-describedby="helpId" placeholder="Ingrese su contraseña actual">
                                                        <div (click)="showpasswordActual()" class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i *ngIf="!showpassActual" class="fas fa-eye-slash"></i>
                                                                <i *ngIf="showpassActual" class="fas fa-eye"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                  </div>
                                            </div>
                                            <div class="col-xs-6 col-md-6 col-lg-6">
                                                <div class="form-group" [ngClass]="{'text-danger': nuevaContrasena.dirty && nuevaContrasena.invalid }">
                                                    <label for="nuevaContrasena">Nueva contraseña</label>
                                                    <div class="input-group input-group-sm mb-3">
                                                        <input (ngModelChange)="copypassword()"  maxlength="16" formControlName="nuevaContrasena" [type]="showpass ? 'text' : 'password'" class="form-control form-control-sm" name="nuevaContrasena" id="idnuevaContrasena" aria-describedby="helpId" placeholder="Ingrese su nueva contraseña">
                                                        <div (click)="showpassword()" class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i *ngIf="!showpass" class="fas fa-eye-slash"></i>
                                                                <i *ngIf="showpass" class="fas fa-eye"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                 
                                                    
                                                  </div>
                                            </div>
                                            <div *ngIf="!showpass" class="col-xs-6 col-md-6 col-lg-6">
                                                <div class="form-group" [ngClass]="{'text-danger': confirmarnuevaContrasena.dirty && confirmarnuevaContrasena.invalid }">
                                                    <label for="confirmarnuevaContrasena">Confirmar nueva contraseña</label>
                                                    <div class="input-group input-group-sm mb-3">
                                                        <input maxlength="16" [(ngModel)]="datoConfirmarContrasena" formControlName="confirmarnuevaContrasena" [type]="showpassConfirmar ? 'text' : 'password'" class="form-control form-control-sm" name="confirmarnuevaContrasena" id="idconfirmarnuevaContrasena" aria-describedby="helpId" placeholder="Confirme su nueva contraseña">
                                                        <div (click)="showpasswordConfirmar()" class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i *ngIf="!showpassConfirmar" class="fas fa-eye-slash"></i>
                                                                <i *ngIf="showpassConfirmar" class="fas fa-eye"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                  </div>
                                            </div>
                                            <div class="col-xs-12 col-md-12 col-md-12" *ngIf="formCambioContrasena.errors?.sonIguales && !showpass">
                                                <div class="form-group">
                                                    <p class="text-danger text-center">
                                                        Las contraseñas debe de ser iguales.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr>
                                        
                                            <div class="text-center">
                                                <button [disabled]="cargando" type="submit" style="background-color: rgb(0, 54, 113);" class="btn btn-primary"><i *ngIf="cargando" class="fas fa-sync fa-spin"></i> Cambio Contraseña</button>
                                            </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                
            </mat-card>
            
        </div>
    </div>
</div>

