import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Componenetes
import { HeaderComponent } from './header/header.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';

//Modules
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';  
import { ConsultarapidamodalComponent } from '../components/patient/consultarapidamodal/consultarapidamodal.component';
import {MatTooltipModule} from '@angular/material/tooltip'; 
import {MatDialogModule} from '@angular/material/dialog';
import { PatologiabasemodalComponent } from '../components/patient/patologiabasemodal/patologiabasemodal.component';
import { PatientmodalComponent } from '../components/patient/patientmodal/patientmodal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent
  ],
  imports:[
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatDialogModule
  ],
  exports:[
    HeaderComponent,
    BreadcrumbsComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule
  ],
  entryComponents:[
    ConsultarapidamodalComponent,
    PatologiabasemodalComponent,
    PatientmodalComponent
  ]
})
export class SharedModule { }
