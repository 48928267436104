import { Component, OnInit,Inject  } from '@angular/core';
import {MatDialogRef,MatDialogConfig,MatDialog} from '@angular/material/dialog';
import { FormControl,FormGroup, Validators, NgForm } from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { citas } from '../../../models/usuario.model';
import{NgxSpinnerService}from 'ngx-spinner';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { AtendidoComponent } from '../atendido/atendido.component';

@Component({
  selector: 'app-citas',
  templateUrl: './citas.component.html', 
  styleUrls: ['./citas.component.css']
})
export class CitasComponent implements OnInit {

  doctor:any={};
  pageActual: number = 1;
  citas:any;
estadosCita:any;
date: Date = new Date();
doctores:any;
  createFormGroup(){
    return new FormGroup({
      estadoCita:new FormControl(''),
      fechaCita:new FormControl('',Validators.required),
      horaCita:new FormControl('',Validators.required),
      idPersona:new FormControl(this.data.idPersona),
       idDoctor :new FormControl('',Validators.required),
       observaciones:new FormControl(''),
      idUsuario:new FormControl(''),
      id:new FormControl(this.data.id),
      dictamen:new FormControl('')
    });
  }

citaForm:FormGroup;

constructor(public dialogRef: MatDialogRef< CitasComponent >,
  public userService:DoctorService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private spinnerServi:NgxSpinnerService,
  private toastr:ToastrService,public _UsuarioService: UsuarioService,
  private matDialog: MatDialog) {
    this.citaForm=this.createFormGroup();
   }

   ngOnInit(): void {
    this.resetForm();
    this.userService.selectCita.id=0;
    this.userService.selectCita.idDoctor=this._UsuarioService.usuario.idDoctor;
     this.getCita();
     setTimeout(() => {
       this.getDoctores();
     }, 500);
   }

   resetForm(citaform?:NgForm):void{
    this.userService.selectCita={
      id:0,
      fechaCita:null,
      horaCita:null,
      observaciones:'',
      dictamen:'',
      idPersona:null,
      idDoctor:null,
      idUsuario:null,
      estadoCita:null,
    }; }


    saveCita(){
      if(this.citaForm.valid)
      {this.citaForm.value.idUsuario=this._UsuarioService.usuario.idUsuario;
        if (this.citaForm.value.id==0||this.citaForm.value.id==null) {
          this.citaForm.value.id=0;
          this.userService.saveCita(this.citaForm.value).subscribe(
            (data:any) => {
             if (data.hasError==true) {
               this.toastr.error(data.errors[0].descripcion);
               this.closeModal();
               this.resetForm();
             } else {
               this.toastr.success('Cita ingresada correctamente');
               this.getCita();
               this.resetForm();
             }
            }
          );
  
        } else {
          this.citaForm.value.idUsuario=this._UsuarioService.usuario.idUsuario;
          this.userService.updCita(this.citaForm.value).subscribe(
            (data:any) => {
             if (data.hasError==true) {
               this.toastr.error(data.errors[0].descripcion);
              this.closeModal();
               this.resetForm();
             } else {
               this.toastr.success('Cita actualizada correctamente');
              //  this.closeModal();
               this.getCita();
               this.resetForm();
             }
            }
          );
  
        }
  
      }else{
        this.toastr.info('Debe llenar todos los Campos');
      }
  
  
  
  
  
    }
  
    closeModal() {
      this.dialogRef.close();
      this.resetForm();
    }
  
  
  
    getCita(){
        this.spinnerServi.show();
     this.userService.mostrarCita(this.data).subscribe(
        (data:any) => {
          if (data.hasError==true) {
            this.toastr.error(data.errors[0].descripcion);
           this.closeModal();
            this.resetForm();
          }else{
          this.citas=data.data.Table0;
          if (this.citas!==null) {
              this.spinnerServi.hide();
           }
        }
      }
     );
    }
  
    preUpdateCita(citas:citas){
      this.userService.selectCita=Object.assign({},citas)
  
    }
  
    openAtendido(user) {
      user.noAtendido=2;
      const dialogConfig = new MatDialogConfig;
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      dialogConfig.id = "atendido-component";
      dialogConfig.height = "500px";
      dialogConfig.width = "1000px";
      dialogConfig.data = user;
      const modalDialog = this.matDialog.open(AtendidoComponent, dialogConfig);
      this.closeModal();
    }

    openNoAtendido(cita) {
      // user.noAtendido=1;
      // const dialogConfig = new MatDialogConfig;
      // // The user can't close the dialog by clicking outside its body
      // dialogConfig.disableClose = true;
      // dialogConfig.id = "atendido-component";
      // dialogConfig.height = "300px";
      // dialogConfig.width = "800px";
      // dialogConfig.data = user;
      // const modalDialog = this.matDialog.open(AtendidoComponent, dialogConfig);
      // this.closeModal();
        cita.idEstadoCita=3
     this.userService.cambiarEstadoCita(cita).subscribe(
       (data:any) => {
         if (data.hasError==true) {
           this.toastr.error(data.errors[0].descripcion);
           this.closeModal();
         }else{
         this.toastr.success('Cambio de estado exitoso');
         this.closeModal();
       }
       this.cambiarEstadoPaciente(cita);
     }
     );
    
    }
    cambiarEstadoPaciente(cita){
      cita.deshabilitar=3
      cita.id=0;
      this.userService.cambiarEstadoPaciente(cita).subscribe(
        (data:any) => {
         if (data.hasError==true) {
           this.toastr.error(data.errors[0].descripcion);
           this.closeModal();
         } else {
          //  this.toastr.success('Paciente Validado');
           this.closeModal();
         }
        }
      );
    }
    

   getDoctores(){
 this.userService.getDoctores().subscribe(
    (data:any) => {      
      this.doctores=data.data.Table0;
    
  }
 );
}

}