import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr'

//Modules
import { ServiceModule } from './services/service.module';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxSpinnerModule}from 'ngx-spinner';
import { OrderModule } from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination'; 

//Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { MapComponent } from './components/map/map.component';
import { PatientmodalComponent } from './components/patient/patientmodal/patientmodal.component';

//Angular Material
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ConsultarapidamodalComponent } from './components/patient/consultarapidamodal/consultarapidamodal.component';
import { PatologiabasemodalComponent } from './components/patient/patologiabasemodal/patologiabasemodal.component';
import { CitasComponent } from './components/doctor/citas/citas.component';
import { ModalComponent } from './components/doctor/modal/modal.component';
import { ModalpatologiasComponent } from './components/doctor/modalpatologias/modalpatologias.component';
import { ModalsintomasComponent } from './components/doctor/modalsintomas/modalsintomas.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { ReiniciocontrasenaComponent } from './account/reiniciocontrasena/reiniciocontrasena.component';
import { ConfirmarcontrsenaComponent } from './account/confirmarcontrsena/confirmarcontrsena.component';
import { HistorialComponent } from './components/doctor/historial/historial.component';
import { PsicologicomodalComponent } from './components/patient/psicologicomodal/psicologicomodal.component';
import { CommonModule } from '@angular/common';
import { ValidarComponent } from './components/doctor/validar/validar.component';
import { AtendidoComponent } from './components/doctor/atendido/atendido.component';
import { PerfilComponent } from './components/doctor/perfil/perfil.component';
import { VacunasComponent } from './components/doctor/vacunas/vacunas.component';
import { PatologiasComponent } from './components/patologias/patologias.component';





@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    MapComponent,
    PatientmodalComponent,
    ConsultarapidamodalComponent,
    PatologiabasemodalComponent,
    CitasComponent,
    ModalComponent,
    ModalpatologiasComponent,
    ModalsintomasComponent,
    ReiniciocontrasenaComponent,
    ConfirmarcontrsenaComponent,
    HistorialComponent,
    PsicologicomodalComponent,
    ValidarComponent,
    AtendidoComponent,
    PerfilComponent,
    VacunasComponent,
    PatologiasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ServiceModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatCheckboxModule,
    MatDialogModule,
    NgxSpinnerModule,
    CommonModule,
    OrderModule,
    NgxPaginationModule
    
  ],
  exports: [
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

