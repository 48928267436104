<div id="main-wrapper">
    <app-header></app-header>

    <br><br>
    <div class="page-wrapper">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="container-fluid ">
            
            <router-outlet></router-outlet>

        </div>
        <footer class="page-footer font-small blue pt-4">
            <div class="footer-copyright text-center py-3">© 2020 Copyright:
                <a href="https://www.unah.edu.hn/" target="_blank"> UNAH</a>
            </div>
        </footer>
    </div>
</div>