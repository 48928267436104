import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente/paciente.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  public noticias:any;
  public cargando: boolean = true;
  constructor(
    private _paciente: PacienteService
  ) { }

  ngOnInit(): void {
    this._paciente.obtenerNoticias().subscribe(result=>{
      if(result.data.Table0 && !result.hasError){
        this.noticias = result.data.Table0; 
      }
      this.cargando = false;
    });
  }

}
