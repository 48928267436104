import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public menus: any;
  constructor(public router: Router,
    public _usuarioService: UsuarioService
    ) { }

  ngOnInit(): void {
    this._usuarioService.obtenerMenu().subscribe(result=>{
      if(result && !result.hasError){
        this.menus = result.data.Table0;
      }
    });
  }

  logout(){
    this._usuarioService.logout();
  }

  navegacion(pagina: string){
     this.router.navigate([pagina]);
  }
}
