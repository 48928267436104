import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators,FormControlName } from '@angular/forms';
import { DataListService } from '../../services/dataLists/data-list.service';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { DoctorService } from '../../services/doctor/doctor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VacunasComponent } from '../../components/doctor/vacunas/vacunas.component';
import { DateAdapter } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../../services/register/register.service';
import { PacienteService } from '../../services/paciente/paciente.service';
import { encuaesta } from '../../models/paciente.model';
import { PatologiabasemodalComponent } from '../../components/patient/patologiabasemodal/patologiabasemodal.component';
import { PatologiasComponent } from '../../components/patologias/patologias.component';
// import {​​​​​ DatePipe }​​​​​ from'@angular/common';


@Component({
  selector: 'app-registro-vacunas',
  templateUrl: './registro-vacunas.component.html',
  styleUrls: ['./registro-vacunas.component.css']
})
export class RegistroVacunasComponent implements OnInit {
registroForm:FormGroup
pagosForm:FormGroup
generos:any=[]
departamentos:any=[]
municipios:any=[]
color:any
filtro:FormGroup
pageActual:number=1
identidad='';
filterPost = '';
  departamentosF = '';
  apellidos='';
  municipiosF='';
  telefonoMovil='';

  historialVacunas:any=[]
  fechaTo:any
  formVacuna:FormGroup
  hideFecha:any
  vacunas:any=[]
  dosis:any=[]
  banderDosis:any
  constructor(private matDialog:MatDialog, private _pacienteService: PacienteService,private srvRegistro:RegisterService, private toastr:ToastrService, private _adapter:DateAdapter<any>, private _dataLists:DataListService, private modal:MatDialog, private userService:DoctorService,private spinnerServi:NgxSpinnerService ) { }
closeModal(){
this.formVacuna.reset();
this.hideFecha=false;
}
preUpdateVacuna(vacun,tmp){

  this.formVacuna.patchValue({
    id:vacun.id,
    fechaAplicacion: vacun.fechaAplicacion,
    idDosis:vacun.numeroDeDosis,
    observaciones: vacun.observacion,
    idVacuna:vacun.idMarcaVacuna,
    lote:vacun.lote,
    lugar:vacun.lugar,
  })
  const dialogRef = this.modal.open(tmp, {
    width: '900px',
    maxHeight: '700px'})

}
eliminarVacuna(item){
  var f= confirm('Desea eliminar esta vacuna?')
  if (f==true) {
    this.userService.eliminarVacunaPaciente(item).subscribe((data:any)=>{
this.toastr.info(data.data.Table0[0].descripcion);
this.mostrarVacunasPaciente(this.idPersona);
this.modal.closeAll();
    });
  } 
}


cambioVacuna(){
  // this.hideFecha=false;
  // this.formVacuna.patchValue({
  //   fechaAplicacion:''
  //  })

}
enviarVacuna(){
  if (this.formVacuna.valid) {
    this.spinnerServi.show();
    this.formVacuna.value.idPaciente= this.idPersona;
    this.userService.insertarVacunaPaciente(this.formVacuna.value).subscribe((data:any)=>{
      if (data.hasError==true) {
       this.toastr.warning(data.errors[0].descripcion);
       this.spinnerServi.hide();
      }else{
      if (this.formVacuna.value.id==0||this.formVacuna.value.id==null) {
        this.toastr.success('Vacuna ingresada correctamente');
       this.spinnerServi.hide();

      }
      else{
       this.toastr.info('Vacuna actualizada correctamente');
       this.spinnerServi.hide();

      }
     }
     this.mostrarVacunasPaciente(this.idPersona);
     this.formVacuna.reset();
     this.hideFecha=false;
     
    });
    
  }
  else{
    this.toastr.warning('LLene los campos obligatorios');
  }
 }
cambioDosis(eve){
  if (eve==1) {
    this.banderDosis=true;
  } else {
    this.banderDosis=false;
    
  }


}
cambio(io){
  var vui=this.formVacuna.get('idVacuna');
  for (let i = 0; i < this.vacunas.length; i++) {
    if (this.vacunas[i].id==vui.value) {
      var fechaInicio= new Date(io);
      fechaInicio.setMinutes(fechaInicio.getMinutes() + fechaInicio.getTimezoneOffset())
      var fechaFin= fechaInicio
      var diff = this.vacunas[i].diasEntreDosis;
      fechaFin.setDate(fechaInicio.getDate()+diff);
      var dia= fechaFin.getDate();
      var mes= fechaFin.getMonth()+1;
      var anio= fechaFin.getFullYear();
   this.fechaTo= dia +'/'+mes+'/'+ anio
   if (this.banderDosis) {
     this.hideFecha=true
     
   } else {
    this.hideFecha=false
     
   }
  
    }
    
  }

}

mostrarVacunas(){
  this.userService.mostrarTiposVacuna().subscribe((data:any)=>{
    this.vacunas=data.data.Table0;
  });
}

initDosis(){
   
  this.dosis=[
    {
      id:1,
      nombreDosis:'Primera dosis'
    },{
      id:2,
      nombreDosis:'Segunda dosis'
    }
  ]
}
initDosis2(){
  this.dosis=[
    {
      id:2,
      nombreDosis:'Segunda dosis'
    }
  ]
}

initFormPersonal(){
  
      this.formVacuna = new FormGroup({
        id:new FormControl(0),
        fechaAplicacion: new FormControl('',[Validators.required]),
        idDosis:new FormControl('',Validators.required),
        observaciones: new FormControl('',),
        lote: new FormControl('',Validators.required),
        lugar: new FormControl('',Validators.required),
        idVacuna: new FormControl('',[Validators.required]),
        fechaDos: new FormControl('')
      });
     }
  ngOnInit(): void {
    this._adapter.setLocale('es-HN');
    this.initRegistroForm();
    this.mostrarGeneros();
    this.mostrarDeptos();
    this.color="primary"
    this.filtro= new FormGroup({
      filterRegistros: new FormControl('')
    })
    this.initFormPersonal();
    this.mostrarVacunas();
    this.initDosis();
    this.registroForm.controls['edad'].disable();

  }

  initRegistroForm(){
    this.registroForm= new FormGroup({
      numeroIdentificacion:new FormControl('',Validators.required),
      nombres:new FormControl('',Validators.required),
      apellidos:new FormControl('',Validators.required),
      fechaNacimiento:new FormControl('',Validators.required),
      idGenero:new FormControl('',Validators.required),
      telefonoMovil:new FormControl('',Validators.required),
      correo:new FormControl('',),
      idDepartamento:new FormControl('',Validators.required),
      idMunicipio:new FormControl('',Validators.required),
      direccion:new FormControl(''),
      idRol: new FormControl(3),
      edad:new FormControl(''),
      id:new FormControl(0),
      idDocumentoIdentificacion:  new FormControl(1),
      idNacionalidad: new FormControl(114),
      clave: new FormControl(''),
      idEstadoCivil:  new FormControl(0),
      idNivelEducativo: new FormControl(''),
      ocupacion: new FormControl(''),
      profesion: new FormControl(''),
      cuenta: new FormControl('')

    })
  }
  mostrarGeneros(){
    this._dataLists.getGeneros().subscribe(data=>{
      this.generos = data.data.Table0;
  })
}
mostrarDeptos(){
this._dataLists.getDepartamentos().subscribe(data => {
  this.departamentos = data.data.Table0;
});
}
getMunicipios(i){
  this._dataLists.getMunicipios(Number(i)).subscribe(data=>{
    this.municipios= data.data.Table0;
  });
}

guardarRegistro(){
  this.idPersona=null;
  if (this.registroForm.value.numeroIdentificacion.length !== 13) {
    this.toastr.warning("Numero de Identidad debe tener 13 numeros sin guiones");
    return
} 

if (this.registroForm.valid) {
  this.spinnerServi.show();
  this.registroForm.value.idRol=3
  this.registroForm.value.idDocumentoIdentificacion=1
  this.registroForm.patchValue({
    id:0
  }) 
 
  var fecha= (this.registroForm.value.fechaNacimiento)
  var dia= fecha.getDate()
  var mes= fecha.getMonth()+1
  var anio= fecha.getFullYear()
  var fechaNa=anio+'-'+ mes +'-'+ dia;
  this.registroForm.value.fechaNacimiento=fechaNa;
  this.userService.datosPersonalesInsertar(this.registroForm.value).subscribe((data:any)=>{
    if (data.hasError) {
      this.toastr.warning(data.errors[0].descripcion);
      this.spinnerServi.hide();
      return;
    } else {
      this.spinnerServi.hide();
      this.idPersona=data.data.Table0[0].id;
      this.flagAccion=1;
      if (this.VacOrPat==1) {
        this._pacienteService.getEncuestaVac(this.idPersona).subscribe(result => {
          
          const dialogRef = this.matDialog.open(PatologiasComponent, {
            disableClose: true,
            autoFocus: true,
            height: '600px',
            width: '500px',
            data: [result.data.Table0[0].encuesta[0], this.idPersona]
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        });
      } else {
        const dialogRef = this.modal.open(this.mdlVacuna, {
          width: '900px',
          maxHeight: '700px'
      });
      }
      
      
     
    }

  })
  
}else{
  this.toastr.error('llene los campos obligatorios');
}
}

validarNumeros(event) {
  if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 190 && event.keyCode !== 110 && event.keyCode !== 8 && event.keyCode !== 9) {
      return false;
  } else {
      return true;
  }

}
tablaValidar0:any=[]
tablaValidar1:any=[]
idPersona:any=null
flagAccion:number=0
verificado:boolean=true
buscarCenso(item){
  this.historialVacunas=[];
  this.initDosis();
  this.mostrarVacunas();
  this.idPersona=null
  if (this.registroForm.value.numeroIdentificacion.length !== 13) {
    this.toastr.warning("Numero de Identidad debe tener 13 numeros sin guiones");
    return
  }

  this.spinnerServi.show();
  this.userService.buscarCenso(this.registroForm.value.numeroIdentificacion).subscribe((data:any)=>{
    this.tablaValidar0=data.data.Table0
    this.tablaValidar1=data.data.Table1
    if (this.tablaValidar0.length>0) {
      this.getMunicipios(data.data.Table0[0].idDepartamento)
      this.flagAccion=1;
      this.idPersona=data.data.Table0[0].idPersona;
      this.verificado=false;
      // this.toastr.info('Esta persona ya esta ingresada');
      this.spinnerServi.hide();
      var fech =new Date(data.data.Table0[0].fechaNacimiento);
      // var  fech=(data.data.Table0[0].fechaNacimiento);
      // var f= fech.slice(0,-14)
      // f + ' 0:00:00';
      this.registroForm.patchValue({
      nombres:data.data.Table0[0].nombres,
      apellidos:data.data.Table0[0].apellidos,
      idGenero:data.data.Table0[0].idGenero,
      fechaNacimiento:fech,
      edad:data.data.Table0[0].edad,
      telefonoMovil:data.data.Table0[0].telefonoMovil,
      idDepartamento:data.data.Table0[0].idDepartamento,
      idMunicipio:data.data.Table0[0].idMunicipio,
      direccion:data.data.Table0[0].direccion,
      correo:data.data.Table0[0].correoElectronico
    })
    this.registroForm.patchValue({
      idMunicipio:data.data.Table0[0].idMunicipio
    })
    this.mostrarVacunasPaciente(data.data.Table0[0].idPersona);
      return;
    }
    if (this.tablaValidar1.length==0) {
      this.flagAccion=2;
      this.toastr.warning('Numero de identidad no encontrada');
      this.verificado=true;
      this.spinnerServi.hide();
      this.registroForm.reset();
      return;
    }
     if(this.tablaValidar1.length>0) {
       this.flagAccion=2
       this.verificado=true
       var fech =new Date(data.data.Table1[0].fechaNacimiento);
        this.registroForm.patchValue({
        nombres:data.data.Table1[0].nombres,
        apellidos:data.data.Table1[0].apellidos,
        idGenero:parseInt( data.data.Table1[0].idGenero),
        fechaNacimiento:fech,
        edad:data.data.Table1[0].edad,
        telefonoMovil:'',
        idDepartamento:'',
        idMunicipio:'',
        direccion:'',
        correo:''
  })
      this.spinnerServi.hide();
    }
    this.spinnerServi.hide();


  });
}
mostrarVacunasPaciente(paciente){
  this.spinnerServi.show();
  var pacientes={idPaciente:paciente}
  this.userService.mostrarVacunasPaciente(pacientes).subscribe((data:any)=>{
  this.historialVacunas=data.data.Table0;
  this.spinnerServi.hide();
  this.historialVacunas.forEach(e => {
    
    if (e.numeroDeDosis==1) {
      this.banderDosis=false;
      this.formVacuna.patchValue({
        idDosis:2
      })
    }
    if (e.numeroDeDosis==2) {
      this.formVacuna.patchValue({
        idDosis:1
      })
    }

    this.formVacuna.patchValue({
      idVacuna:e.idMarcaVacuna
    })

    this.formVacuna.patchValue({
      idVacuna:e.idMarcaVacuna
    })
    this.spinnerServi.hide();
      

  });
 
  });
}

ver(e){
  const edad1= this.calcularEdad(e);
  this.registroForm.patchValue({
    edad:edad1
  }) 
}
calcularEdad(fecha) {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
  }

  return edad;
}


openModal(tmp){
  const dialogRef = this.modal.open(tmp, {
    width: '900px',
    maxHeight: '700px'
});
}
listaPacientes:any=[]
user:any=[]

VacOrPat:any
mdlVacuna:any
fechaHoy:any
  openModalVacuna(tmp){
   
    if (this.historialVacunas.length==2) {
      this.toastr.warning("El paciente ya tiene aplicada las 2 dosis");
      return;
    }
    this.fechaHoy= new Date()
    var dia= this.fechaHoy.getDate();
    dia<10?dia='0'+dia:dia;
    var mes= this.fechaHoy.getMonth()+1;
    mes<10?mes='0'+mes:mes;
    var anio= this.fechaHoy.getFullYear();
    var  fechaH= anio +'-'+mes+'-'+ dia
    this.formVacuna.patchValue({fechaAplicacion:fechaH})
    this.cambio(fechaH)
    this.historialVacunas.length==1?this.hideFecha=false:this.hideFecha=true;
this.VacOrPat=2
this.mdlVacuna=tmp;
this.historialVacunas.forEach(e => {
    
  if (e.numeroDeDosis==1) {
    this.banderDosis=false;
    this.formVacuna.patchValue({
      idDosis:2
    })
  }
  if (e.numeroDeDosis==2) {
    this.formVacuna.patchValue({
      idDosis:1
    })
  }

  this.formVacuna.patchValue({
    idVacuna:e.idMarcaVacuna
  })

  this.formVacuna.patchValue({
    idVacuna:e.idMarcaVacuna
  })
  this.spinnerServi.hide();
    

});
    if (this.flagAccion==2&& this.idPersona==null) {
      this.guardarRegistro();
      
    } 
    if (this.flagAccion==0 && this.idPersona==null) {
      this.guardarRegistro();
    }
    if (this.flagAccion==1 && this.idPersona!=null) {
      const dialogRef = this.modal.open(tmp, {
        width: '900px',
        maxHeight: '700px'
    });
     
    }
  }


 encuestaGlobal:any
  openModalPatologica() {
this.VacOrPat=1
    if (this.flagAccion==2&& this.idPersona==null) {
      this.guardarRegistro();
      
    } 
    if (this.flagAccion==0 && this.idPersona==null) {
      this.guardarRegistro();
    }
    if (this.flagAccion==1 && this.idPersona!=null) {
      this.spinnerServi.show();
      this._pacienteService.getEncuestaVac(this.idPersona).subscribe(result => {
        this.spinnerServi.hide();
        const dialogRef = this.matDialog.open(PatologiasComponent, {
          disableClose: true,
          autoFocus: true,
          height: '600px',
          width: '500px',
          data: [result.data.Table0[0].encuesta[0],this.idPersona]
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      })
     
    }

    
  }

}
