import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { UsuarioService } from './usuario/usuario.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  constructor(
    private router: Router,
    private _login: UsuarioService,
    private _toastr: ToastrService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = localStorage.getItem("token");
 
    if (token ==null){token="Bearer"}
    
    req =req.clone({
      setHeaders:{"x-api-key": "WO2NB3uLu57GBDUa29BYW28A7e0Cih8v1EzaRs18",
      'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
    return next.handle(req).pipe(tap((resp: HttpResponse<any>) => {
      if(resp.body){
        if(resp.body.token && resp.body.token != ""){
          localStorage.setItem("token",resp.body.token);
        }
      }
    },
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }
      this._toastr.success("La sesión caducó por inactividad","Sesión");
      setTimeout(()=>{
        this._login.logout();
      },1000);
      
    }
    }));
  }
 
 
}