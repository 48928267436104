<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <h1 class="text-center">Cita</h1>
            <form [formGroup]="atendidoForm" (ngSubmit)="saveCita()" autocomplete="off">
                <input type="hidden" name="id" id="id" [(ngModel)]="this.userService.selectCita.id" formControlName="id" class="form-control form-control-sm">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="telemedica">Valoración telemedicina <span style="color: red;">*</span></label>
                            <textarea type="text" name="valoracionTelemedicina" id="valoracionTelemedicina" formControlName="valoracionTelemedicina" class="form-control form-control-sm">
                        </textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="examenes">Resultado de exámenes previos</label>
                            <textarea type="text" name="resultadosExamenesPrevios" id="resultadosExamenesPrevios" formControlName="resultadosExamenesPrevios" class="form-control form-control-sm">
                        </textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="conclusion">Conclusión <span style="color: red;">*</span></label>
                            <textarea type="text" name="concluciones" id="concluciones" formControlName="concluciones" class="form-control form-control-sm">
                        </textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="doctor">Dictamen <span style="color: red;">*</span></label>
                            <select formControlName="idDictamen" class="form-control form-control-sm" name="idDictamen" id="idDictamen">
                        <option *ngFor="let dictamen of dictamenes " [value]=dictamen.id >{{dictamen.nombreDictamen}}</option>
                      </select>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="text-center">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cerrar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>

            </form>
        </div>
        <hr>
        <div class="col-lg-6 col-md-12 col-sm-12 contenedor1">
            <table class="table table-sm ">
                <thead class="thead-light ">
                    <tr>
                        <th scope="col ">Síntomas</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let respuesta of encuesta.preguntas[0].respuestas ">
                        <th scope="row ">{{respuesta.respuesta}}</th>
                        <td>
                            <mat-checkbox (click)="enviarRespuestaEncuesta(respuesta)" [(ngModel)]="respuesta.seleccionada "></mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="text-center">
                <button type="button" class="btn btn-primary" (click)="finalizarEncuesta()">Evaluar</button>
            </div> -->
        </div>

    </div>
    <hr>
</div>