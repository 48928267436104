<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/image/background/alma_mater.jpg);background-color: #cccccc; ">
    <div class="login-box card" style="background-color: rgb(0, 54, 111); ">
        <div class="card-body ">
            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material " id="loginform" autocomplete="off"
                (ngSubmit)="iniciarSesion(f)">
                <a  class="text-center db">
                    <div class="text-center ">
                        <img width="150px" src="../.././../assets/image/logo/logo_doctor_1847.png" alt="Home" />
                    </div>

                </a>
                <br />
                <div class="form-group">
                    <div class="col-xs-12">
                        <input ngModel name="usuario" (ngModelChange)="limpiarMensaje()" class="form-control btn-login"
                            type="text" required placeholder="Correo electrónico o teléfono ">
                    </div>
                </div>
                
                <div class="input-group mb-3">
                    <input ngModel name="contrasena" (ngModelChange)="limpiarMensaje()"
                    class="form-control btn-login" [type]="showpass ? 'text' : 'password'" required placeholder="Contraseña">
                    <div (click)="showpassword()" class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                            <i *ngIf="!showpass" class="fas fa-eye-slash"></i>
                            <i *ngIf="showpass" class="fas fa-eye"></i>
                        </span>
                    </div>
                </div>

                <div class="form-group text-center ">
                    <div class="col-xs-12">
                        <div class="col-sm-12 col-xs-12 col-lg-12 text-center" style="color: #819FF7;">
                            <a [routerLink]="['/reiniciar']" class="text-primary m-l-5"><b
                                    style="color: #819FF7;">¿Olvidó su contraseña? </b></a>

                        </div>
                    </div>
                    <br>
                    <div *ngIf="!cargandoInicio" class="col-xs-12">
                        <button style="background-color: white; color: black;"
                            class="btn btn-info btn-lg btn-block btn-rounded" type="submit">Ingresar</button>
                    </div>
                    <div *ngIf="cargandoInicio" class="col-xs-12">
                        <button disabled style="background-color: white; color: black;"
                            class="btn btn-info btn-lg btn-block btn-rounded"><i
                                class="fas fa-sync fa-spin"></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 text-center" *ngIf="mensaje != '' ">
                        <div class="alert alert-danger text-center" role="alert">
                            <p>
                                {{mensaje}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-sm-12 col-xs-12 col-lg-12 text-center" style="color: white;">
                        ¿No tienes una cuenta?
                    </div>
                    <br>
                    <div class="col-sm-12 col-xs-12 col-lg-12 text-center">
                        <a [routerLink]="['/register']" class="text-primary m-l-5"><b
                                style="color: #819FF7;">Regístrate, es gratis</b></a>
                    </div>
                    <br>
                    <div class="col-sm-12 col-xs-12 col-lg-12 text-center" style="color: white;">
                        LA UNAH, Responde
                    </div>
                </div>
                <div class="fondo-img">
                    <img width="150px" src="../../../assets/image/background/sol_docto_fondo.png" alt="">
                </div>
                <br><br><br><br>

                <footer class="page-footer text-center footer-login"
                    style="color: black; background-color: rgba(252, 194, 0, .7);">
                    <h2>#QuedateEnCasa</h2>
                </footer>

            </form>

        </div>
    </div>
</section>