<div class="row">
    <div class="col-12">
        <div class="container">
            <mat-card class="text-center" *ngIf="cargando">
                <img class="rounded mx-auto d-block col-md-12 img-cargando-menu" src="../../../assets/image/loading/load1.gif" alt="">
            </mat-card>
            <mat-card *ngIf="!cargando">
                <form class="form-horizotal form-material" [formGroup]="formPerfil" (ngSubmit)="updatePerfil()" autocomplete="off">
                    <div class="row">
                        <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                            <img class="" src="../../../assets/image/logo/logo_unah_titulo.png" width="140" alt="logo_unah">
                        </div>
                        <div class="col-xs-4 col-md-4 col-lg-4">
                            <h1 class="text-center">Perfil de usuario</h1>
                        </div>
                        <div class="col-xs-4 col-md-4 col-lg-4 text-center">
                            <div class="">
                                <img class="" src="../../../assets/image/logo/logo_degt.png" width="210" alt="logo_medicina">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h2><i class="fas fa-user"></i> Datos personales</h2>
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group" [ngClass]="{'text-danger': nombres.dirty && nombres.invalid }">
                                <label for="nombre">Nombres</label>
                                <input style="text-transform: uppercase !important;" (keydown)="validarLetras($event)" [(ngModel)]="perfilUsuario.nombres" maxlength="50" formControlName="nombres"
                                    type="text" class="form-control form-control-sm" name="nombres" id="nombres"
                                    aria-describedby="helpId" placeholder="Ingrese su nombre">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group" [ngClass]="{'text-danger': apellidos.dirty && apellidos.invalid }">
                                <label for="apellidos">Apellidos</label>
                                <input style="text-transform: uppercase !important;" (keydown)="validarLetras($event)" [(ngModel)]="perfilUsuario.apellidos" maxlength="50" formControlName="apellidos"
                                    type="text" class="form-control form-control-sm" name="Apellidos" id="apellidos"
                                    aria-describedby="helpId" placeholder="Ingrese su apellido">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-6">
                            <div class="form-group">
                                <label for="nacionalidad">Nacionalidad</label>
                                <select disabled [(ngModel)]="perfilUsuario.idNacionalidad" formControlName="nacionalidad"
                                    class="form-control form-control-sm" name="nacionalidad" id="idNacionalidad">
                                    <option *ngFor="let nacionalidad of nacionalidades" [value]="nacionalidad.id">
                                        {{nacionalidad.pais}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-6">
                            <div class="form-group " [ngClass]="{'text-danger': documento.dirty && documento.invalid }">
                                <label for="tipoDocumento">Tipo de identificación</label>
                                <div class="row">
                                    <div class="col-md-6">
                                        <select disabled [(ngModel)]="perfilUsuario.idDocumentoIdentificacion"
                                            formControlName="tipoDocumento" title="Ingrese"
                                            class="form-control form-control-sm " name="tipoDocumento"
                                            id="idTipoDocumento">
                                            <option *ngFor="let documento of documentos" [value]="documento.id">
                                                {{documento.nombreDocumento}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <input disabled [(ngModel)]="perfilUsuario.identidad" maxlength="30"
                                            formControlName="documento" type="text" name="documento" id="documento"
                                            class="form-control form-control-sm " placeholder="Ingrese su documento">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="categoriaProfesion">Ocupación</label>
                                <select [(ngModel)]="perfilUsuario.idCategoriaProfesion" formControlName="idCategoriaProfesion"
                                    class="form-control form-control-sm" name="categoriaProfesion" id="idCategoriaProfesion">
                                    <option *ngFor="let categoProfesion of categoriaProfesion" [value]="categoProfesion.id">
                                        {{categoProfesion.categoriaProfesion}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!--
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group" [ngClass]="{'text-danger': profesion.dirty && profesion.invalid }">
                                <label for="profesion">Profesión/oficio</label>
                                <input style="text-transform: uppercase !important;" (keydown)="validarLetras($event);" [(ngModel)]="perfilUsuario.profesion" maxlength="25" formControlName="profesion"
                                    type="text" class="form-control form-control-sm" name="profesion" id="idProfesion"
                                    aria-describedby="helpId" placeholder="Ingrese su Profesión /oficio">
                            </div>
                        </div>-->
                        
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="estadoCivil">Estado civil</label>
                                <select [(ngModel)]="perfilUsuario.idEstadoCivil" formControlName="estadoCivil"
                                    class="form-control form-control-sm" name="estadoCivil" id="idEstadoCivil">
                                    <option *ngFor="let estCivil of estadoCiviles" [value]="estCivil.id">
                                        {{estCivil.estadoCivil}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group"
                                [ngClass]="{'text-danger': fechaNacimiento.dirty && fechaNacimiento.invalid }">
                                <label for="fechaNacimiento">Fecha de nacimiento</label>
                                <input [(ngModel)]="perfilUsuario.fechaNacimiento" value="2002-01-01" min="1900-01-01"
                                    max="2002-12-31" formControlName="fechaNacimiento" type="date"
                                    class="form-control form-control-sm" name="fechaNacimiento" id="fechaNacimiento"
                                    aria-describedby="helpId" placeholder="Ingrese su fecha de nacimiento">

                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label for="escolaridad">Escolaridad</label>
                                <select [(ngModel)]="perfilUsuario.idNivelEducativo" formControlName="escolaridad"
                                    class="form-control form-control-sm" name="escolaridad" id="idEscolaridad">
                                    <option *ngFor="let nivelEducacion of nivelesEducativos" [value]="nivelEducacion.id">
                                        {{nivelEducacion.nivelEducativo}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label for="genero">Genero</label>
                                <select [(ngModel)]="perfilUsuario.idGenero" formControlName="idGenero"
                                    class="form-control form-control-sm" name="genero" id="idGenero">
                                    <option *ngFor="let gen of generos"  [value]="gen.id">
                                        {{gen.genero}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label for="cantidadHabitaciones">Teléfono fijo</label>
                                <input maxlength="8" (keydown)="validarNumeros($event)" [(ngModel)]="perfilUsuario.telefonoFijo" formControlName="telefonoFijo"  type="text" name="telefonoFijo" id="idtelefonoFijo" class="form-control form-control-sm" placeholder="Ingrese su número de teléfono fijo">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group"
                                [ngClass]="{'text-danger': correoElectronico.dirty && correoElectronico.invalid }">
                                <label for="correoElectronico">Correo electrónico</label>
                                <input disabled  [(ngModel)]="perfilUsuario.correoElectronico" maxlength="80"
                                    formControlName="correoElectronico" type="text" class="form-control form-control-sm"
                                    name="correoElectronico" id="correoElectronico" aria-describedby="helpId"
                                    placeholder="Ingrese su correo electrónico">

                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group"
                                [ngClass]="{'text-danger': numeroTelefono.dirty && numeroTelefono.invalid }">
                                <label for="numeroTelefono">Número de teléfono</label>
                                <input disabled (keydown)="validarNumeros($event)" [(ngModel)]="perfilUsuario.telefonoMovil" maxlength="8"
                                    formControlName="numeroTelefono" type="text" class="form-control form-control-sm"
                                    name="numeroTelefono" id="numeroTelefono" aria-describedby="helpId"
                                    placeholder="Ingrese su número de teléfono">

                            </div>
                        </div>
                    </div>

                    <h2><i class="fas fa-map-marked-alt"></i> Datos de residencia</h2>
                    <div class="row">
                        <div class="col-xs-12 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label for="departamento">Departamentos</label>
                                <select [(ngModel)]="perfilUsuario.idDepartamento" (change)="getMunicipios()"
                                    formControlName="departamento" class="form-control form-control-sm"
                                    name="departamento" id="idDepartamento">
                                    <option *ngFor="let departamento of departamentos" [value]="departamento.id">
                                        {{departamento.departamento}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-lg-4 col-md-4">
                            <div class="form-group">
                                <label for="municipio">Municipios</label>
                                <select [(ngModel)]="perfilUsuario.idMunicipio" formControlName="municipio"
                                    class="form-control form-control-sm" name="municipio" id="idMunicipio">
                                    <option *ngFor="let municipio of municipios" [value]="municipio.id">
                                        {{municipio.municipio}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-lg-4 col-md-4">
                            <div class="form-group" [ngClass]="{'text-danger': nombres.dirty && nombres.invalid }">
                                <label for="idCategoriaDireccion">Localidad</label>
                                <select [(ngModel)]="perfilUsuario.idCategoriaDireccion" formControlName="idCategoriaDireccion" (change)="cambiarLocalidad()"
                                    class="form-control form-control-sm" name="idCategoriaDireccion" id="idCategoriaDireccion">
                                    <option *ngFor="let categoriaDireccion of categoriasDirecciones" [value]="categoriaDireccion.id">
                                        {{categoriaDireccion.nombreCategoriaDireccion}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label for="tipoVivienda">Tipo de vivienda</label>
                                <select [(ngModel)]="perfilUsuario.idTipoVivienda" formControlName="tipoVivienda"  name="tipoVivienda" id="idTipoVivienda" class="form-control form-control-sm">
                                    <option *ngFor="let tipoVivienda of tiposVivienda" [value]="tipoVivienda.id">{{tipoVivienda.nombreTipoVivienda}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label for="edificio">Edificio</label>
                                <input style="text-transform: uppercase !important;" [(ngModel)]="perfilUsuario.edificio" (keydown)="validarLetras($event);" formControlName="edificio" type="text" name="edificio" id="idEdificio" class="form-control form-control-sm" placeholder="Ingrese el nombre del edificio">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 col-lg-4"  [ngClass]="{'text-danger': localida.dirty && localida.invalid }">
                            <div class="form-group">
                                <label for="localida">{{localidad.title}}</label>
                                <input style="text-transform: uppercase !important;" (keydown)="validarLetras($event);"  [(ngModel)]="perfilUsuario.detalleCategoriaDireccion" formControlName="localida" type="text" name="localida" id="idlocalida" class="form-control form-control-sm" placeholder="{{localidad.message}}">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-xs-12 col-lg-12 col-md-12">
                            <div class="form-group" [ngClass]="{'text-danger': direccion.dirty && direccion.invalid }">
                                <label for="direccion">Dirección</label>
                            <textarea (keydown)="validarAlfanumerico($event)" style="text-transform: uppercase !important;" (keydown)="bloquearEnter($event)"  [(ngModel)]="perfilUsuario.direccion" formControlName="direccion"
                                    placeholder="Ingrese su dirección exacta con referencia si es posible"
                                    class="form-control form-control-sm" name="direccion" id="direccion" cols="30"
                                    rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group"
                                [ngClass]="{'text-danger': cantidadFamiliares.dirty && cantidadFamiliares.invalid }">
                                <label for="cantidadFamiliares">Cantidad de personas que viven en la casa</label>
                                <input (keydown)="validarNumeros($event)" [(ngModel)]="perfilUsuario.cantidadPersonas" maxlength="2"
                                    formControlName="cantidadFamiliares" type="text"
                                    class="form-control form-control-sm" name="cantidadFamiliares"
                                    id="idCantidadFamiliares" aria-describedby="helpId"
                                    placeholder="Ingrese la cantidad">

                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="cantidadHabitaciones">No. habitaciones</label>
                                <input (keydown)="validarNumeros($event)" [(ngModel)]="perfilUsuario.cantidadHabitaciones" formControlName="cantHabitaciones" maxlength="1" type="text" name="cantidadHabitaciones" id="idCantidadHabitaciones" class="form-control form-control-sm" placeholder="Ingrese la cantidad de habitaciones de su vivienda">
                            </div>
                        </div>
                        
                    </div>
                    <h2><i class="fas fa-user-friends"></i> Datos de contacto</h2>
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group"
                                [ngClass]="{'text-danger': personaContacto.dirty && personaContacto.invalid }">
                                <label for="personaContacto">Nombre del contacto</label>
                                <input style="text-transform: uppercase !important;" (keydown)="validarLetras($event)" [(ngModel)]="perfilUsuario.contactoEmergencia" maxlength="50"
                                    formControlName="personaContacto" type="text" class="form-control form-control-sm"
                                    name="personaContacto" id="idPersonaContacto" aria-describedby="helpId"
                                    placeholder="Ingrese el nombre del contacto">

                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6">
                            <div class="form-group"
                                [ngClass]="{'text-danger': numeroTelefonoContacto.dirty && numeroTelefonoContacto.invalid }">
                                <label for="numeroTelefonoContacto">Teléfono del contacto </label>
                                <input (keydown)="validarNumeros($event)" [(ngModel)]="perfilUsuario.telefonoContactoEmergencia" maxlength="8"
                                    formControlName="numeroTelefonoContacto" type="text"
                                    class="form-control form-control-sm" name="numeroTelefonoContacto"
                                    id="idNumeroTelefonoContacto" aria-describedby="helpId"
                                    placeholder="Ingrese el número de teléfono del contacto">

                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12 col-lg-12">
                            <div class="form-group" [ngClass]="{'text-danger': direccionContacto.dirty && direccionContacto.invalid }">
                                <label for="direccionContacto">Dirección del contacto</label>
                                <textarea (keydown)="validarAlfanumerico($event)" style="text-transform: uppercase !important;" (keydown)="bloquearEnter($event)" [(ngModel)]="perfilUsuario.direccionContacto" formControlName="direccionContacto"
                                    placeholder="Ingrese su dirección exacta con referencia si es posible del contacto"
                                    class="form-control form-control-sm" name="direccionContacto"
                                    id="idDireccionContacto" cols="30" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    

                    <hr>
                    <div class="text-center">
                        <!-- <button type="button" matTooltip="Condiciones patológicas" aria-label="Button that displays a tooltip when focused or hovered over" class="btn-material-primary float-left" (click)="openEncuestaRapida()" data-toggle="tooltip" data-placement="right" mat-mini-fab
                            aria-label="Example icon-button with a heart icon">
                            <i class="fas fa-stethoscope icon-menu"></i>
                            </button> -->
                        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cerrar</button>
                        <button [disabled]="cargandoPerfil" type="submit" class="btn btn-success"><i *ngIf="cargandoPerfil" class="fas fa-sync fa-spin"></i> Actualizar</button>

                    </div>
                </form>





            </mat-card>



        </div>
    </div>
</div>