import { Component, OnInit ,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormControl, FormControlName, FormGroup, Validators, NgForm } from '@angular/forms';
import { DoctorService } from '../../../services/doctor/doctor.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-vacunas',
  templateUrl: './vacunas.component.html',
  styleUrls: ['./vacunas.component.css']
})
export class VacunasComponent implements OnInit {
public formVacuna:FormGroup
vacunas:any=[];
historialVacunas:any=0;
dosis:any;
  constructor(@Inject(MAT_DIALOG_DATA) public dataRecibida: any,public dialogRef: MatDialogRef< VacunasComponent >,
  private srvDoctor:DoctorService, private toast:ToastrService) { }

  ngOnInit(): void {
    this.mostrarVacunasPaciente(this.dataRecibida.idPersona);
    this.mostrarVacunas();
    this.initFormPersonal();
    this.initDosis();
  }
  flag:number
  initDosis(){
   
      this.dosis=[
        {
          id:1,
          nombreDosis:'Primera dosis'
        },{
          id:2,
          nombreDosis:'Segunda dosis'
        }
      ]
  
   
  }
  date:any;
  initFormPersonal(){
//     this.date=new Date;
//      var day=this.date.getDate();
//      if (day<10) {
//       day='0'+day;
//    }
// var mes=(this.date.getMonth()+1);
// if (mes<10) {
//    mes='0'+mes;
// }
// var anio= this.date.getUTCFullYear()

    this.formVacuna = new FormGroup({
      id:new FormControl(0),
      fechaAplicacion: new FormControl('',[Validators.required]),
      idDosis:new FormControl('',Validators.required),
      observaciones: new FormControl('',),
      lote: new FormControl('',Validators.required),
      lugar: new FormControl('',Validators.required),
      idVacuna: new FormControl('',[Validators.required]),
      fechaDos: new FormControl('')
    });
   }

  mostrarVacunas(){
 this.srvDoctor.mostrarTiposVacuna().subscribe((data:any)=>{
   this.vacunas=data.data.Table0;
 });
  }
  fechaTo:any;
  hideFecha:boolean=false
  cambio(io){
 var vui=this.formVacuna.get('idVacuna');
for (let i = 0; i < this.vacunas.length; i++) {
  if (this.vacunas[i].id==vui.value) {
    var fechaInicio= new Date(io);
    fechaInicio.setMinutes(fechaInicio.getMinutes() + fechaInicio.getTimezoneOffset())
    var fechaFin= fechaInicio
    var diff = this.vacunas[i].diasEntreDosis;
    fechaFin.setDate(fechaInicio.getDate()+diff);
    var dia= fechaFin.getDate();
    var mes= fechaFin.getMonth()+1;
    var anio= fechaFin.getFullYear();
 this.fechaTo= dia +'/'+mes+'/'+ anio
 if (this.banderDosis) {
   this.hideFecha=true
   
 } else {
  this.hideFecha=false
   
 }

  }
  
}
  }
  cambioVacuna(){
    this.hideFecha=false;
    this.formVacuna.patchValue({
      fechaAplicacion:''
     })

  }
  banderDosis:any
  cambioDosis(eve){
    if (eve==1) {
      this.banderDosis=true;
    } else {
      this.banderDosis=false;
      
    }


  }

  enviarVacuna(){
   if (this.formVacuna.valid) {
     this.formVacuna.value.idPaciente= this.dataRecibida.idPersona;
     this.srvDoctor.insertarVacunaPaciente(this.formVacuna.value).subscribe((data:any)=>{
       if (data.hasError==true) {
        this.toast.warning(data.errors[0].descripcion);
       }else{
       if (this.formVacuna.value.id==0) {
         this.toast.success('Vacuna ingresada correctamente');
       }
       else{
        this.toast.info('Vacuna actualizada correctamente');
       }
      }
      this.mostrarVacunasPaciente(this.dataRecibida.idPersona);
      this.resetForm();
      this.hideFecha=false;
      
     });
     
   }
   else{
     this.toast.warning('LLene los campos obligatorios');
   }
  }
  mostrarVacunasPaciente(paciente){
    var pacientes={idPaciente:paciente}
    this.srvDoctor.mostrarVacunasPaciente(pacientes).subscribe((data:any)=>{
    this.historialVacunas=data.data.Table0;
    });
  }

  
  resetForm(formVacuna?:NgForm ):void{
    this.formVacuna.patchValue({
      id:0,
      fechaAplicacion:'' ,
      idDosis:'',
      observaciones: '',
      idVacuna: '',
      fechaDos:'',
      lote:'',
      lugar:''
    });
   }


  closeModal() {
    this.dialogRef.close();
    this.resetForm();
  }
  eliminarVacuna(item){
    var f= confirm('Desea eliminar esta vacuna?')
    if (f==true) {
      this.srvDoctor.eliminarVacunaPaciente(item).subscribe((data:any)=>{
  this.toast.info(data.data.Table0[0].descripcion);
this.mostrarVacunasPaciente(this.dataRecibida.idPersona);
      });
    } 
  }

  preUpdateVacuna(vacun){
    this.formVacuna.patchValue({
      id:vacun.id,
      fechaAplicacion: vacun.fechaAplicacion,
      idDosis:vacun.numeroDeDosis,
      observaciones: vacun.observacion,
      idVacuna:vacun.idMarcaVacuna,
      lote:vacun.lote,
      lugar:vacun.lugar,
    })

  }

}
