import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef,MatDialogModule} from '@angular/material/dialog';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { FormControl,FormGroup, Validators, NgForm } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.css']
})
export class ValidarComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef< ValidarComponent >,
    private _UsuarioService: UsuarioService,
    public userService:DoctorService,
    private toastr:ToastrService,
    private dial:MatDialogModule,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.validarForm=this.createFormGroup();
    }
    createFormGroup(){
      return new FormGroup({
        id:new FormControl(this.data.id),
        idEstadoSeguimientoPaciente:new FormControl(this.data.idEstadoSeguimientoPaciente),
         observaciones:new FormControl('',Validators.required),
        idUsuario:new FormControl(''),
        deshabilitar:new FormControl(''),
        idPersona:new FormControl(this.data.idPersona),
      });
    }
  

    validarForm:FormGroup;

  ngOnInit(): void {
    // this.doctor.getlistUser();
  }

  closeModal() {
    this.dialogRef.close();
    this.resetForm();
  }

  resetForm(validarform?:NgForm):void{
    this.userService.selectCita={
      id:0,
      fechaCita:null,
      horaCita:null,
      observaciones:'',
      dictamen:'',
      idPersona:null,
      idDoctor:null,
      idUsuario:null,
      estadoCita:null,
    }; }
user1:any={};
    backEstado(){
      this.user1.deshabilitar=1;
      this.user1.idPersona=this.data.idPersona;
      this.user1.id=this.data.id;
      this.userService.cambiarEstadoPaciente(this.user1).subscribe(
        (data:any) => {
         if (data.hasError==true) {
           this.toastr.error(data.errors[0].descripcion);
           this.closeModal();
           this.resetForm();
         } else {
          //  this.toastr.success('Paciente Validado');
           this.resetForm();
           this.closeModal();
         }
        }
      );

    }



    validarPaciente(){
      if(this.validarForm.valid)
      {
        if (this.validarForm.value.deshabilitar!=4) {
         
          this.userService.cambiarEstadoPaciente(this.validarForm.value).subscribe(
            (data:any) => {
             if (data.hasError==true) {
               this.toastr.error(data.errors[0].descripcion);
               this.closeModal();
               this.resetForm();
             } else {
               this.toastr.success('Paciente validado');
               this.resetForm();
               this.closeModal();
             }
            }
          );
          
        } else {
          this.userService.cambiarEstadoPaciente(this.validarForm.value).subscribe(
            (data:any) => {
             if (data.hasError==true) {
               this.toastr.error(data.errors[0].descripcion);
               this.closeModal();
               this.resetForm();
             } else {
               this.toastr.warning('Paciente deshabilitado');
               this.resetForm();
               this.closeModal();
             }
            }
          );
          
        }
         
      }else{
        this.toastr.info('Debe llenar la observación');
      }
  
    }
}
