<mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 col-md-12 col-lg-12">
         <h2 class="text-center" >¿Cómo te sientes hoy?</h2>
            <div class="row contenedor-menu-psico">
                <i (click)="opcionRespuesta('like',0)" [ngClass]="{'icon-menu-psico-color':respuestas[0].respuesta == 'like' }" class="far fa-thumbs-up icon-menu-psico text-center"></i>
                <i (click)="opcionRespuesta('dislike',0)" [ngClass]="{'icon-menu-psico-color':respuestas[0].respuesta == 'dislike' }" class="far fa-thumbs-down fa-flip-horizontal icon-menu-psico"></i>
                
            </div>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-12">
            <h2 class="text-center" >¿Cuál es tu estado de ánimo?</h2>
            <div class="row contenedor-menu-psico">
               
                <div class="text-center">
                    <i (click)="opcionRespuesta('bien',1)" [ngClass]="{'icon-menu-psico-color':respuestas[1].respuesta == 'bien' }" class="far fa-smile icon-menu-psico"></i>
                    <i (click)="opcionRespuesta('normal',1)" [ngClass]="{'icon-menu-psico-color':respuestas[1].respuesta == 'normal' }" class="far fa-meh icon-menu-psico"></i>
                    <i (click)="opcionRespuesta('mal',1)" [ngClass]="{'icon-menu-psico-color':respuestas[1].respuesta == 'mal' }" class="far fa-frown icon-menu-psico"></i>
                </div>
                
            </div>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-12">
            <h2 (click)="closeModal()" class="text-center" style="color: #FEC500 !important; cursor: pointer !important;" >Haré la evaluación después</h2>
        </div>
    
    </div>
</mat-dialog-content>

