<div class="form-container">

    <div class="modal-header">
        <h2 class="modal-title" *ngIf="data.deshabilitar!=4" id="exampleModalLabel">Validar Paciente</h2>
        <h2 class="modal-title" *ngIf="data.deshabilitar==4" id="exampleModalLabel">Deshabilitar Paciente</h2>
        <!-- <button type="button" class="close">
            <span (click)="closeModal()">&times;</span>
          </button> -->
    </div>
    <form [formGroup]="validarForm" (ngSubmit)="validarPaciente()" autocomplete="off">
        <div class="modal-body">
            <input type="hidden" name="deshabilitar" id="deshabilitar" [(ngModel)]="data.deshabilitar" formControlName="deshabilitar" class="form-control form-control-sm">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label for="nacionalidad">Observaciones <span style="color: red;">*</span></label>
                        <textarea type="text" [(ngModel)]="this.userService.selectCita.observaciones" name="observaciones" id="observaciones" formControlName="observaciones" class="form-control form-control-sm">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-secondary" (click)="backEstado()" (click)="closeModal()" (click)="resetForm()" data-dismiss="modal">Cerrar</button>
                <button type="submit" *ngIf="data.deshabilitar!=4" class="btn btn-primary">Validar</button>
                <button type="submit" *ngIf="data.deshabilitar==4" class="btn btn-danger">Deshabilitar</button>
            </div>
        </div>
    </form>
</div>